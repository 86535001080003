/* eslint-disable camelcase */
import { createErrorsHandlers, prepareSorter } from '../../utils'
import { BackendError } from '../RequestError'
import { SorterOrder } from '../SorterOrder'
import { fetchApi } from '../fetchApi'
import {
  RecordStatus,
  RemoteSectionAdvancement,
  SectionAdvancement,
  parseRemoteSectionAdvancement
} from '../sectionAdvancement'
import {
  RemoteSubjectInRepository,
  SubjectInRepository,
  SubjectProfileAnswer,
  parseRemoteSimpleAnswer,
  parseRemoteSubject
} from '../subjectRepository'
import { Product, RemoteProduct, parseRemoteProduct } from './products'

export interface AddPanelistsOptions {
  subjectIds: string[]
  projectId: string
  visitId?: string
}

interface AddPanelistsResponseHandlers {
  onSuccess?: (testIds: SubjectsToTestsMap) => void
  onRequestError?: (code: number) => void
  onAlreadyExists?: () => void
}

type SubjectsToTestsMap = Record<string, string>

export const addPanelists = (
  { projectId, subjectIds, visitId }: AddPanelistsOptions,
  responseHandlers?: AddPanelistsResponseHandlers
) => {
  const { req, cancel } = fetchApi.post<SubjectsToTestsMap>(`side_by_side/projects/${projectId}/tests`, {
    datacapt_ids: subjectIds,
    visit_id: visitId
  })

  req.then(({ error, status, body }) => {
    if (error) {
      createErrorsHandlers<AddPanelistsResponseHandlers>(
        {
          [BackendError.SIDE_BY_SIDE_SUBJECT_ALREADY_IN_PROJECT]: 'onAlreadyExists'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body)
    }
  })

  return cancel
}

interface FetchSubjectsNotInProjectOptions {
  projectId: string
  searchPhrase: string
}

interface FetchSubjectsNotInProjectResponse {
  results: RemoteSubjectInRepository[]
}

interface FetchSubjectsNotInRecruitmentResponseHandlers {
  onSuccess?: (subjects: SubjectInRepository[]) => void
  onRequestError?: (code: number) => void
}

export const fetchSubjectsNotInProject = (
  { projectId, searchPhrase: search }: FetchSubjectsNotInProjectOptions,
  responseHandlers: FetchSubjectsNotInRecruitmentResponseHandlers
) => {
  const url = `side_by_side/projects/${projectId}/subjects`
  const { req, cancel } = fetchApi.get<FetchSubjectsNotInProjectResponse>(url, { search })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchSubjectsNotInRecruitmentResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess(body.results?.map(subject => parseRemoteSubject(subject)))
    }
  })

  return cancel
}

export interface SideBySideTest {
  id: string
  testNumber: number
  datacaptId: string
  firstName: string
  lastName: string
  centerName: string
  creationDate?: Date
  createdAuthor?: string
  lastUpdated?: Date
  lastUpdateAuthor?: string
  imageId?: string
  informationAnswers?: SubjectProfileAnswer[]
  products?: Product[]
  isFormStarted?: boolean
  status: RecordStatus
  sectionsAdvancement?: { [sectionId: string]: SectionAdvancement }
  progress: number
}

export interface RemoteSideBySideTest {
  id: number
  test_number: number
  datacapt_id: string
  first_name: string
  last_name: string
  center_name: string
  created_at: string
  created_by_author: string
  modified_at: string
  modified_by_author: string
  photo_thumbnail: string
  information_answers?: {
    question: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    answer: any
  }[]
  products?: RemoteProduct[]
  is_form_started?: boolean
  status: RecordStatus
  sections?: RemoteSectionAdvancement[]
}

interface FetchSideBySideTestsResponse {
  count: number
  next: number
  results: RemoteSideBySideTest[]
}

const parseRemoteSideBySideTest: (test: RemoteSideBySideTest) => SideBySideTest = (test: RemoteSideBySideTest) => ({
  id: String(test.id),
  testNumber: test.test_number,
  datacaptId: test.datacapt_id,
  firstName: test.first_name,
  lastName: test.last_name,
  creationDate: test.created_at && new Date(test.created_at),
  creationAuthor: test.created_by_author,
  lastUpdated: test.modified_at && new Date(test.modified_at),
  lastUpdateAuthor: test.modified_by_author,
  centerName: test.center_name,
  imageId: test.photo_thumbnail,
  informationAnswers: test.information_answers?.map(a => ({
    question: a.question,
    answer: parseRemoteSimpleAnswer(a.answer)
  })),
  products: test.products?.map(parseRemoteProduct),
  isFormStarted: test.is_form_started,
  status: test.status,
  sectionsAdvancement: (test.sections || []).reduce(
    (acc, remoteSection) => ({
      ...acc,
      [remoteSection.id]: parseRemoteSectionAdvancement(remoteSection)
    }),
    {}
  ),
  progress: 0 // we don't need progress in SBS but we need property to be consistent
})

export interface SideBySideTestSorter {
  field: keyof SideBySideTest
  order: SorterOrder
}

const sorterFields = {
  testNumber: ['test_number'],
  datacaptId: ['subject__first_name', 'subject__last_name', 'subject__datacapt_id'],
  lastUpdated: ['modified_at'],
  centerName: ['center__name'],
  creationDate: ['created_at']
}

interface FetchSideBySideTestsOptions {
  projectId: string
  options?: {
    limit?: number
    offset?: number
    sorter?: SideBySideTestSorter
    search?: string
    filters?: {
      centers: string[]
    }
  }
}

interface FetchSideBySideTestsResponseHandlers {
  onSuccess?: ({ tests, countAll }: { tests: SideBySideTest[]; countAll: number }) => void
  onRequestError?: (code: number) => void
}

export const fetchSideBySideTests = (
  { projectId, options }: FetchSideBySideTestsOptions,
  responseHandlers?: FetchSideBySideTestsResponseHandlers
) => {
  const sorter = prepareSorter<typeof sorterFields, SideBySideTestSorter>(sorterFields, options.sorter)
  const query = {
    limit: options.limit,
    offset: options.offset,
    ordering: sorter,
    search: options.search,
    center_ids: options.filters?.centers
  }

  const { req, cancel } = fetchApi.get<FetchSideBySideTestsResponse>(`side_by_side/projects/${projectId}/tests`, query)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchSideBySideTestsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        tests: body.results.map(parseRemoteSideBySideTest),
        countAll: body.count
      })
    }
  })

  return cancel
}

interface FetchSideBySideTestResponseHandlers {
  onSuccess?: (test: SideBySideTest) => void
  onRequestError?: (code: number) => void
}

interface FetchSideBySideTestOptions {
  projectId: string
  testId: string
}

export const fetchSideBySideTest = (
  { testId, projectId }: FetchSideBySideTestOptions,
  responseHandlers?: FetchSideBySideTestResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RemoteSideBySideTest>(`side_by_side/projects/${projectId}/tests/${testId}`)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchSideBySideTestResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteSideBySideTest(body))
    }
  })

  return cancel
}

interface EndSideBySideTestResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

interface EndSideBySideTestOptions {
  projectId: string
  testId: string
}

export const endSideBySideTest = (
  { testId, projectId }: EndSideBySideTestOptions,
  responseHandlers?: EndSideBySideTestResponseHandlers
) => {
  const url = `side_by_side/projects/${projectId}/tests/${testId}/end`
  const { req, cancel } = fetchApi.patch(url, {})

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<EndSideBySideTestResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface UpdateSideBySideTestResponseHandlers {
  onSuccess?: (test: SideBySideTest) => void
  onRequestError?: (code: number) => void
}

interface UpdateSideBySideTestOptions {
  projectId: string
  testId: string
  products?: number[]
  isFormStarted?: boolean
}

export const updateSideBySideTest = (
  { testId, projectId, products, isFormStarted }: UpdateSideBySideTestOptions,
  responseHandlers?: UpdateSideBySideTestResponseHandlers
) => {
  const query = { products, is_form_started: isFormStarted }
  const url = `side_by_side/projects/${projectId}/tests/${testId}`
  const { req, cancel } = fetchApi.patch<RemoteSideBySideTest>(url, query)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<UpdateSideBySideTestResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteSideBySideTest(body))
    }
  })

  return cancel
}
