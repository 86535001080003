/* eslint-disable camelcase */
import dayjs, { Dayjs } from 'dayjs'

import { createErrorsHandlers } from '../../utils'
import { BackendError } from '../RequestError'
import { fetchApi } from '../fetchApi'
import { RemoteVisit } from './visits'

export enum CalendarEventColor {
  Pink1 = 'pink-1',
  Pink2 = 'pink-2',
  Purple1 = 'purple-1',
  Purple2 = 'purple-2',
  Blue2 = 'blue-2',
  Blue12 = 'blue-12',
  Green4 = 'green-4',
  Red5 = 'red-5',
  Red3 = 'red-3',
  Orange1 = 'orange-1',
  Yellow3 = 'yellow-3',
  Yellow1 = 'yellow-1',
  Green7 = 'green-7',
  Green2 = 'green-2'
}

enum EventType {
  Event = 'EVENT'
}

export enum EventStudyType {
  edc = 'EDC',
  recruitment = 'RECRUITMENT',
  project = 'PROJECT'
}

export type RemoteEventStudy = { id: number; uuid: string; name: string; reference_number: string }

export type RemoteEventPerson = {
  id: number
  name: string
  full_name: string
  first_name: string
  last_name: string
  datacapt_id: string
  email: string
  photo_thumbnail: string
}

type RemoteEventDate = { date?: string; datetime?: string } | string

export interface RemoteEvent {
  id?: number
  organizer: number | RemoteEventPerson
  type: EventType
  center: number | { id: number; name: string; abbreviation: string }
  color: CalendarEventColor
  start: RemoteEventDate
  end: RemoteEventDate
  timezone: string
  recruitment_study?: string | RemoteEventStudy
  project?: string | RemoteEventStudy
  study?: string | RemoteEventStudy
  schedule?: number
  is_recurring: boolean
  is_full_day?: boolean
  is_available?: boolean
  recurring_period?: string
  title: string
  public_title: string
  attendees?: (number | RemoteEventPerson)[]
  participants?: (number | RemoteEventPerson)[]
  capacity?: number
  visits?: RemoteVisit[]
}

export interface EventPerson {
  id: string
  fullName?: string
  datacaptId?: string
  email?: string
  photoThumbnail?: string
  visitId?: number
}

export interface CalendarEvent {
  organizer: EventPerson
  id?: number
  schedule: number
  title: string
  publicTitle: string
  startDate: Dayjs
  endDate: Dayjs
  isFullDay: boolean
  timezone: string
  startTime: string
  endTime: string
  capacity: number
  subjects: EventPerson[]
  attendees: EventPerson[]
  isAvailable: boolean
  study: {
    uuid: string
    name?: string
    reference?: string
    type: EventStudyType
  }

  center: {
    id: string
    name?: string
    abbreviation?: string
  }
  color: CalendarEventColor
  startFilterString?: string // used for optimization
  endFilterString?: string // used for optimization
  startInUserTimezone?: Dayjs
  endInUserTimezone?: Dayjs
}

const getTimeAndDateInTimezone = (dateTime: string, timezone: string) => {
  const date = dayjs(dateTime)
  const timezoneDate = dayjs.tz(date, timezone)
  return timezoneDate
}

const parseRemoteEventStudy = (remoteEvent: RemoteEvent) => {
  if (typeof remoteEvent.study === 'object' && remoteEvent.study !== null) {
    return {
      uuid: remoteEvent.study.uuid,
      name: remoteEvent.study.name,
      reference: remoteEvent.study.reference_number,
      type: EventStudyType.edc
    }
  }
  if (typeof remoteEvent.recruitment_study === 'object' && remoteEvent.recruitment_study !== null) {
    return {
      uuid: remoteEvent.recruitment_study.uuid,
      name: remoteEvent.recruitment_study.name,
      reference: remoteEvent.recruitment_study.reference_number,
      type: EventStudyType.recruitment
    }
  }
  if (typeof remoteEvent.project === 'object' && remoteEvent.project !== null) {
    return {
      uuid: remoteEvent.project.uuid,
      name: remoteEvent.project.name,
      reference: remoteEvent.project.reference_number,
      type: EventStudyType.project
    }
  }
  return null
}

const parseEventPersonForSave = (person: EventPerson): number =>
  typeof person === 'string' ? Number(person) : Number(person.id)

export const parseRemoteEventPerson = (
  remotePerson: number | RemoteEventPerson,
  visits?: RemoteVisit[]
): EventPerson => {
  if (!remotePerson || typeof remotePerson !== 'object') return {} as EventPerson

  const getFullName = () => {
    if (remotePerson.name) return remotePerson.name
    if (remotePerson.first_name) return `${remotePerson.first_name} ${remotePerson.last_name}`

    return remotePerson.full_name
  }

  return {
    id: String(remotePerson.id),
    fullName: getFullName(),
    datacaptId: remotePerson.datacapt_id,
    email: remotePerson.email,
    photoThumbnail: remotePerson.photo_thumbnail,
    visitId: visits?.find(v => typeof v.subject === 'number' && v.subject === remotePerson.id).id
  }
}

const getEventDate = (remoteDate: RemoteEventDate, timezone: string, isFullDay: boolean, isEnd = false) => {
  if (typeof remoteDate === 'string') return getTimeAndDateInTimezone(remoteDate, timezone)
  if (isFullDay) return isEnd ? dayjs(remoteDate.date).subtract(1, 'day') : dayjs(remoteDate.date)
  return getTimeAndDateInTimezone(remoteDate?.datetime, timezone)
}

export const parseRemoteCalendarEvent = (remoteEvent: RemoteEvent): CalendarEvent => {
  const startDate = getEventDate(remoteEvent.start, remoteEvent.timezone, remoteEvent.is_full_day)
  const endDate = getEventDate(remoteEvent.end, remoteEvent.timezone, remoteEvent.is_full_day, true)

  return {
    organizer: parseRemoteEventPerson(remoteEvent.organizer),
    id: remoteEvent.id,
    schedule: remoteEvent.schedule,
    title: remoteEvent.title,
    publicTitle: remoteEvent.public_title,
    startDate,
    endDate,
    timezone: remoteEvent.timezone,
    startTime: startDate.format('HH:mm'),
    endTime: endDate.format('HH:mm'),
    isFullDay: remoteEvent.is_full_day,
    capacity: remoteEvent.capacity,
    subjects: remoteEvent.participants
      ? remoteEvent.participants.map(p => parseRemoteEventPerson(p, remoteEvent.visits))
      : [],
    attendees: remoteEvent.attendees ? remoteEvent.attendees.map(p => parseRemoteEventPerson(p)) : [],
    isAvailable: remoteEvent.is_available,
    study: parseRemoteEventStudy(remoteEvent),
    center: typeof remoteEvent.center !== 'number' && {
      id: String(remoteEvent.center?.id),
      name: remoteEvent.center?.name,
      abbreviation: remoteEvent.center?.abbreviation
    },
    color: remoteEvent.color
  }
}

export const getUtcString = (date: Dayjs) => (date ? date.clone().utc().format('YYYY-MM-DD HH:mm') : undefined)

const getTimeAndDateInUTC = (date: Dayjs, time: string, timezone: string) => {
  const timezoneDate = dayjs.tz(`${date.format('YYYY-MM-DD')} ${time}`, timezone)
  return getUtcString(timezoneDate)
}

const parseCalendarEventForSave = (event: CalendarEvent): RemoteEvent => ({
  organizer: parseEventPersonForSave(event.organizer),
  type: EventType.Event,
  title: event.title,
  public_title: event.publicTitle || undefined,
  timezone: event.timezone,
  start: {
    date: event.isFullDay ? event.startDate.format('YYYY-MM-DD') : undefined,
    datetime: !event.isFullDay ? getTimeAndDateInUTC(event.startDate, event.startTime, event.timezone) : undefined
  },
  end: {
    date: event.isFullDay ? event.endDate.add(1, 'day').format('YYYY-MM-DD') : undefined,
    datetime: !event.isFullDay ? getTimeAndDateInUTC(event.endDate, event.endTime, event.timezone) : undefined
  },
  study: event.study?.type === EventStudyType.edc ? event.study.uuid : undefined,
  recruitment_study: event.study?.type === EventStudyType.recruitment ? event.study.uuid : undefined,
  project: event.study?.type === EventStudyType.project ? event.study.uuid : undefined,
  center: Number(event.center.id) || undefined,
  capacity: event.capacity,
  color: event.color,
  attendees: event.attendees.map(parseEventPersonForSave).filter(Boolean),
  participants: event.subjects.map(parseEventPersonForSave).filter(Boolean),
  is_recurring: false
})

interface CreateCalendarEventResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const createCalendarEvent = (event: CalendarEvent, responseHandlers?: CreateCalendarEventResponseHandlers) => {
  const { req, cancel } = fetchApi.post('events', parseCalendarEventForSave(event))

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<CreateCalendarEventResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

export const updateCalendarEvent = (event: CalendarEvent, responseHandlers?: CreateCalendarEventResponseHandlers) => {
  const { req, cancel } = fetchApi.put(`events/${event.id}`, parseCalendarEventForSave(event))

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<CreateCalendarEventResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface FetchCalendarEventOptions {
  startDate?: Dayjs
  endDate?: Dayjs
  search?: string
  centerIds?: string[]
  scheduleId?: number
}

interface FetchCalendarEventResponse {
  count: number
  next: number
  previous: number
  results: RemoteEvent[]
}

interface FetchCalendarResponseHandlers {
  onSuccess?: ({ events, allEventsCount }: { events: CalendarEvent[]; allEventsCount: number }) => void
  onRequestError?: (code: number) => void
}

export const fetchCalendarEvents = (
  { startDate, endDate, search, centerIds, scheduleId }: FetchCalendarEventOptions,
  responseHandlers?: FetchCalendarResponseHandlers
) => {
  const query = {
    ordering: ['start_datetime', 'end_datetime'],
    limit: 1000,
    search,
    end_after: getUtcString(startDate),
    start_before: getUtcString(endDate),
    center_abbreviation: centerIds?.length ? centerIds : undefined,
    schedule: scheduleId,
    expand: 'organizer,center,recruitment_study,project,study,attendees,participants,visits'
  }

  const { req, cancel } = fetchApi.get<FetchCalendarEventResponse>('events', query)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchCalendarResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        events: body?.results.map(parseRemoteCalendarEvent).filter(e => e.id) || [],
        allEventsCount: body?.count || 0
      })
    }
  })

  return cancel
}

interface DeleteCalendarEventResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const deleteCalendarEvent = ({ id }: { id: number }, responseHandlers?: DeleteCalendarEventResponseHandlers) => {
  const { req, cancel } = fetchApi.delete(`events/${id}`)

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<DeleteCalendarEventResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface SubjectToEventOptions {
  eventId: number
  subjectId: string
}

interface AddSubjectToEventResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
  onSubjectAlreadyBooked?: () => void
  onWrongSlotInSequentialBooking?: () => void
  onMissingSlotForSequentialBooking?: () => void
}

export const addSubjectToEvent = (
  { eventId, subjectId }: SubjectToEventOptions,
  responseHandlers: AddSubjectToEventResponseHandlers
) => {
  const { req, cancel } = fetchApi.post(`events/${eventId}/book`, { datacapt_id: subjectId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<AddSubjectToEventResponseHandlers>(
        {
          [BackendError.CALENDAR_SLOT_SUBJECT_ALREADY_BOOKED]: 'onSubjectAlreadyBooked',
          [BackendError.CALENDAR_SEQUENTIAL_BOOKING_WRONG_SLOT]: 'onWrongSlotInSequentialBooking',
          [BackendError.CALENDAR_MISSING_SLOT_FOR_SEQUENTIAL_BOOKING]: 'onMissingSlotForSequentialBooking'
        },
        error,
        responseHandlers,
        status
      )
    } else {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface FetchUsersForEventsOptions {
  options?: {
    limit?: number
    offset?: number
    search?: string
  }
}

interface FetchUsersForEventsResponseHandlers {
  onSuccess?: (users: EventPerson[]) => void
  onRequestError?: (code: number) => void
}

interface FetchUsersForEventsResponse {
  results: RemoteEventPerson[]
}

export const fetchUsersForEvents = (
  { options }: FetchUsersForEventsOptions,
  responseHandlers?: FetchUsersForEventsResponseHandlers
) => {
  const query = {
    limit: options.limit,
    offset: options.offset,
    search: options.search
  }

  const { req, cancel } = fetchApi.get<FetchUsersForEventsResponse>('users/simple-list', query)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchUsersForEventsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.results.map(p => parseRemoteEventPerson(p)))
    }
  })

  return cancel
}
