import './CalendarEventPopup.less'

import { Button, Popover } from 'antd'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { navigate } from 'gatsby-plugin-react-intl'
import React, { useContext, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import {
  AclAction,
  AclFeature,
  CalendarEvent,
  EventPerson,
  EventStudyType,
  deleteCalendarEvent,
  updateCalendarEvent
} from '../../../../requests'
import { routes } from '../../../../routes'
import { localeFromPath } from '../../../../utils'
import { UserContext } from '../../../auth'
import {
  DatacAvatar,
  DatacDropdownMenu,
  DatacIcon,
  DatacMessage,
  DatacOption,
  DatacPopconfirm,
  DatacTitle,
  DatacTooltip
} from '../../../common'
import { useCalendarStore } from '../../CalendarStore'
import { EventPeopleSelect, PeopleSelectType, parseEventPersonOption } from '../CalendarEventEdit/EventPeopleSelect'
import { CalendarReschedule } from '../CalendarReschedule'

interface AddSubjectFormProps {
  subjects: EventPerson[]
  setSubjects: (subjects: EventPerson[]) => void
  capacity: number
  isOpened: boolean
  onClose: () => void
  onGoBack: () => void
}

const AddSubjectForm: React.VFC<AddSubjectFormProps> = ({
  subjects,
  setSubjects,
  capacity,
  isOpened,
  onClose,
  onGoBack
}) => {
  const [currentOptions, setCurrentOptions] = useState<DatacOption[]>(subjects.map(parseEventPersonOption))
  const [currentSubjects, setCurrentSubjects] = useState<EventPerson[]>(subjects)
  const intlParticipants = useScopedIntl('calendar.event.participants')
  const intl = useScopedIntl('')

  const onSubmit = () => {
    setSubjects(currentSubjects.filter(s => currentOptions.map(o => o.value).includes(s.id)))
    onGoBack()
  }

  const addSubjects = (subjects: EventPerson[]) => {
    setCurrentSubjects([...currentSubjects, ...subjects.filter(s => !currentSubjects.map(c => c.id).includes(s.id))])
  }

  return (
    <>
      <div className="calendar-event-popup__header">
        <DatacIcon name="chevronLeft" onClick={onGoBack} raw />

        <DatacTitle size="medium">{intlParticipants('add')}</DatacTitle>
        <DatacIcon name="x" onClick={onClose} raw />
      </div>

      <div className="calendar-event-popup__add-subjects">
        <EventPeopleSelect
          currentSelection={currentOptions}
          setCurrentSelection={setCurrentOptions}
          isVisible
          isAlwaysOpened={isOpened}
          type={PeopleSelectType.Participants}
          dropDownClassName="calendar-event-popup__add-subjects__dropdown"
          setPeople={addSubjects}
          capacity={capacity}
        />
      </div>
      <Button size="large" type="primary" onClick={onSubmit} className="calendar-event-popup__add-subjects__button">
        {intl('common.save')}
      </Button>
    </>
  )
}

enum ParticipantMenuAction {
  Delete = 'delete',
  Reschedule = 'reschedule'
}

interface CalendarEventPopupProps {
  event: CalendarEvent
  onClose?: (reload?: boolean) => void
  children?: React.ReactNode
  placement?: 'bottom'
}

export const CalendarEventPopup: React.FC<CalendarEventPopupProps> = ({ event, onClose, children, placement }) => {
  const { forcedEventDetailsId, clearForcedEventDetailsId, currentView, setEventToEdit, userTimezone } =
    useCalendarStore()
  const intl = useScopedIntl('')
  const intlParticipants = useScopedIntl('calendar.event.participants')
  const intlDelete = useScopedIntl('calendar.event.delete')
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)
  const [isShowingSubjects, setIsShowingSubjects] = useState(false)
  const [isAddingSubjects, setIsAddingSubjects] = useState(false)
  const [shouldReloadEvents, setShouldReloadEvents] = useState(false)
  const [currentEvent, setCurrentEvent] = useState(event)
  const [participantToReschedule, setParticipantToReschedule] = useState<EventPerson>(null)
  const { user } = useContext(UserContext)

  const onDelete = () => {
    deleteCalendarEvent(
      { id: currentEvent.id },
      {
        onSuccess: () => {
          onClose(true)
          onVisibleChange(false)
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const onVisibleChange = (visible: boolean) => {
    if (!visible) {
      clearForcedEventDetailsId()
      onClose(shouldReloadEvents)
    }
    setIsAddingSubjects(false)
    setShouldReloadEvents(false)
    setIsPopoverVisible(visible)
    setCurrentEvent(event)
  }

  const availableSubjectsCount = currentEvent.capacity - currentEvent.subjects?.length

  const start = dayjs.tz(event.startDate, userTimezone)
  const end = dayjs.tz(event.endDate, userTimezone)
  const localizedDate = start.locale(localeFromPath())
  const localizedEndDate = end.locale(localeFromPath())
  const hasManyDays = !!end.diff(start, 'day')

  const onEditClick = () => {
    setEventToEdit(currentEvent)
    onVisibleChange(false)
    onClose()
  }

  const goToStudy = () => {
    if (currentEvent.study.type === EventStudyType.edc) navigate(routes.studyDashboard(currentEvent.study.uuid))
    if (currentEvent.study.type === EventStudyType.recruitment)
      navigate(routes.recruitmentStudy(currentEvent.study.uuid))
    if (currentEvent.study.type === EventStudyType.project) navigate(routes.sideBySideProject(currentEvent.study.uuid))
  }

  const duration = () => {
    const duration = dayjs.duration(dayjs(end, 'HH:mm').diff(dayjs(start, 'HH:mm')))
    return dayjs.utc(duration.as('milliseconds')).format('H:mm')
  }

  const attendeesNames = currentEvent.attendees
    .slice(0, 2)
    .map(a => a.fullName)
    .join(', ')

  const additionalAttendeesCount = Math.max(0, currentEvent.attendees?.length - 2)

  const onUpdateCalendarEvent = (event: CalendarEvent) => {
    updateCalendarEvent(event, {
      onSuccess: () => {
        setCurrentEvent(event)
        setShouldReloadEvents(true)
      },
      onRequestError: code => DatacMessage.genericError(intl, code)
    })
  }

  const onChangeSubjects = (subjects: EventPerson[]) => {
    onUpdateCalendarEvent({ ...currentEvent, subjects })
  }

  const isOpened = isPopoverVisible || forcedEventDetailsId === currentEvent.id

  const onCloseSubjectForm = (closePopup: boolean) => {
    setIsAddingSubjects(false)
    closePopup && onVisibleChange(false)
  }

  const getPopoverPlacement = () => {
    if (placement) return placement
    if (currentView === 'day') return undefined
    if (currentView === 'agenda') return 'bottomLeft'
    return 'right'
  }

  const onParticipantMenuAction = (action: string, participant: EventPerson) => {
    switch (action) {
      case ParticipantMenuAction.Delete:
        onChangeSubjects(currentEvent.subjects.filter(subject => participant.id !== subject.id))
        break
      case ParticipantMenuAction.Reschedule:
        setParticipantToReschedule(participant)
        break
    }
  }

  const onCloseReschedule = (reload: boolean) => {
    setParticipantToReschedule(null)
    if (reload) onClose(true)
  }

  const hasAccessToStudy = () => {
    if (currentEvent.study?.type === EventStudyType.edc && !user.canDo(AclFeature.StudyList)(AclAction.AccessAll))
      return false
    if (currentEvent.study?.type === EventStudyType.recruitment && !user.canAccess(AclFeature.Recruitment)) return false
    if (currentEvent.study?.type === EventStudyType.project && !user.canAccess(AclFeature.SideBySide)) return false

    return true
  }

  return (
    <>
      <Popover
        open={isOpened}
        onOpenChange={onVisibleChange}
        rootClassName="calendar-event-popup__root"
        placement={getPopoverPlacement()}
        trigger="click"
        arrow
        content={
          <div className="calendar-event-popup">
            {isAddingSubjects ? (
              <AddSubjectForm
                capacity={currentEvent.capacity}
                subjects={currentEvent.subjects}
                setSubjects={onChangeSubjects}
                isOpened={isOpened && isAddingSubjects}
                onGoBack={() => onCloseSubjectForm(false)}
                onClose={() => onCloseSubjectForm(true)}
              />
            ) : (
              <>
                <div className="calendar-event-popup__header">
                  <div
                    className={classNames(
                      'calendar-event-edit__modal__color-picker__color',
                      `calendar-content__background--${currentEvent.color}`
                    )}
                  />
                  <DatacTitle size="medium">{currentEvent.title}</DatacTitle>
                  <div className="calendar-event-popup__header__actions">
                    {user.canDo(AclFeature.Calendar)(AclAction.Edit) && hasAccessToStudy() && (
                      <DatacIcon
                        name="edit"
                        raw
                        className="calendar-event-popup__header__actions__edit"
                        onClick={onEditClick}
                      />
                    )}
                    {user.canDo(AclFeature.Calendar)(AclAction.Delete) && (
                      <DatacPopconfirm
                        onConfirm={onDelete}
                        okText={intlDelete('yes')}
                        cancelText={intlDelete('cancel')}
                        title={intlDelete('sure')}
                      >
                        <DatacIcon name="trash" raw className="calendar-event-popup__header__actions__delete" />
                      </DatacPopconfirm>
                    )}
                    <DatacIcon name="x" onClick={() => onVisibleChange(false)} raw />
                  </div>
                </div>
                {currentEvent.publicTitle && <DatacTitle size="small">{currentEvent.publicTitle}</DatacTitle>}
                {!!currentEvent.attendees?.length && (
                  <div className="calendar-event-popup__row">
                    <DatacIcon name="user" />
                    <div className="calendar-event-popup__row__attendees">
                      <div className="calendar-event-popup__row__attendees__names">
                        {attendeesNames} {!!additionalAttendeesCount && `+${additionalAttendeesCount}`}
                      </div>
                      <div className="calendar-event-popup__row__attendees__avatars">
                        {currentEvent.attendees?.map(a => (
                          <DatacTooltip title={a.fullName} key={a.id}>
                            <div>
                              <DatacAvatar fullName={a.fullName} size="xsmall" />
                            </div>
                          </DatacTooltip>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                <div className="calendar-event-popup__row">
                  <DatacIcon name="calendar" />
                  <div>
                    {localizedDate.format('dddd, D MMM YYYY')}
                    {currentEvent.isFullDay ? (
                      hasManyDays && ` - ${localizedEndDate.format('dddd, D MMM YYYY')}`
                    ) : (
                      <span>
                        &#x2022; {start.format('HH:mm')} - {end.format('HH:mm')} ({duration()} h)
                      </span>
                    )}
                  </div>
                </div>
                {currentEvent.center?.name && (
                  <div className="calendar-event-popup__row">
                    <DatacIcon name="mapPin" />
                    <div>
                      {currentEvent.center.name}
                      <span> ({currentEvent.center.abbreviation})</span>
                    </div>
                  </div>
                )}
                {currentEvent.study?.uuid && (
                  <div className="calendar-event-popup__row">
                    <DatacIcon name="clipboard" />
                    {hasAccessToStudy() ? (
                      <button type="button" className="raw calendar-event-popup__row__link" onClick={goToStudy}>
                        {currentEvent.study.reference} {currentEvent.study.reference && '-'} {currentEvent.study.name}
                      </button>
                    ) : (
                      <div>
                        {currentEvent.study.reference} {currentEvent.study.reference && '-'} {currentEvent.study.name}
                      </div>
                    )}
                  </div>
                )}
                {!!currentEvent.capacity && (
                  <div className="calendar-event-popup__subjects">
                    <button
                      type="button"
                      className="raw calendar-event-popup__subjects__toggle"
                      onClick={() => setIsShowingSubjects(!isShowingSubjects)}
                    >
                      <div className="calendar-event-popup__subjects__toggle__label">
                        {intlParticipants('placeholder')}
                      </div>
                      <div className="calendar-event-popup__subjects__toggle__capacity">{`${currentEvent.subjects.length}/${currentEvent.capacity}`}</div>
                      <div className="calendar-event-popup__subjects__toggle__space" />
                      <DatacIcon
                        className="calendar-event-popup__subject__toggle__arrow"
                        raw
                        name={isShowingSubjects ? 'chevronUp' : 'chevronDown'}
                      />
                    </button>
                    <div
                      className={classNames('calendar-event-popup__subjects__wrapper', { hidden: !isShowingSubjects })}
                    >
                      <div className="calendar-event-popup__subjects__list">
                        {!!availableSubjectsCount && user.canAccess(AclFeature.SubjectRepository) && (
                          <button
                            type="button"
                            className="raw calendar-event-popup__subjects__list__add"
                            onClick={() => setIsAddingSubjects(true)}
                          >
                            <DatacIcon name="plus" />
                            <div className="calendar-event-popup__subjects__list__add__label">
                              {intlParticipants('add')}
                            </div>
                          </button>
                        )}
                        {currentEvent.subjects.map(s => (
                          <div key={s.id} className="calendar-event-popup__subjects__list__subject">
                            <DatacAvatar photoThumbnail={s.photoThumbnail} fullName={s.fullName} size="xsmall" />
                            <div className="calendar-event-popup__subjects__list__subject__name">
                              <div className="calendar-event-popup__subjects__list__subject__name__label">
                                {s.fullName} <span>{s.datacaptId}</span>
                              </div>
                              <div className="calendar-event-popup__subjects__list__subject__name__email">
                                {s.email}
                              </div>
                            </div>
                            <DatacDropdownMenu
                              options={[
                                {
                                  type: ParticipantMenuAction.Reschedule,
                                  label: 'calendar.event.reschedule.button',
                                  icon: 'calendar'
                                },
                                {
                                  type: ParticipantMenuAction.Delete,
                                  label: 'common.delete',
                                  icon: 'trash'
                                }
                              ]}
                              onClick={(action: string) => onParticipantMenuAction(action, s)}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        }
      >
        {children}
      </Popover>
      <CalendarReschedule
        visitId={participantToReschedule?.visitId}
        event={currentEvent}
        participant={participantToReschedule}
        onClose={onCloseReschedule}
      />
    </>
  )
}
