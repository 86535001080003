/* eslint-disable camelcase */
import dayjs, { Dayjs } from 'dayjs'

import { createErrorsHandlers, prepareSorter } from '../../utils'
import { SorterOrder } from '../SorterOrder'
import { fetchApi } from '../fetchApi'
import { PaymentStatus } from '../payments'
import {
  BookedVisitStatus,
  ParticipantStatus,
  RemoteParticipantStatus,
  RemoteSchedule,
  Schedule,
  parseRemoteSchedule,
  participantStatusMapping
} from '../recruitment'
import {
  CalendarEvent,
  EventPerson,
  RemoteEvent,
  RemoteEventPerson,
  getUtcString,
  parseRemoteCalendarEvent,
  parseRemoteEventPerson
} from './events'

export interface RemoteVisit {
  id: number
  event?: RemoteEvent
  schedule?: RemoteSchedule
  rescheduled_from_event: number
  subject: RemoteEventPerson
  status: BookedVisitStatus
  cause: string
  project_tests?: {
    project_name: string
    project_uuid: string
    id: string
  }[]
  payment_amount: number
  payment_amount_default: number
  payment_currency: string
  payment_currency_default: string
  payment_status: PaymentStatus
  payment_date: string
  payment_created_at: string
  application_status: RemoteParticipantStatus
}

export interface CalendarVisit {
  id: string
  event?: CalendarEvent
  schedule?: Schedule
  subject?: EventPerson
  status: BookedVisitStatus
  cause: string
  tests?: {
    projectName: string
    projectId: string
    testId: string
  }[]
  paymentAmount: number
  paymentAmountDefault: number
  paymentCurrency: string
  paymentCurrencyDefault: string
  paymentStatus: PaymentStatus
  paymentDate: Dayjs
  paymentCreatedAt: Dayjs
  applicationStatus: ParticipantStatus
}

export const parseRemoteVisit = (remote: RemoteVisit): CalendarVisit => ({
  id: String(remote.id),
  event: remote.event ? parseRemoteCalendarEvent(remote.event) : ({} as CalendarEvent),
  schedule: remote.schedule ? parseRemoteSchedule(remote.schedule) : ({} as Schedule),
  status: remote.status,
  cause: remote.cause,
  subject: remote.subject ? parseRemoteEventPerson(remote.subject) : ({} as EventPerson),
  tests: remote.project_tests
    ? remote.project_tests.map(test => ({
        projectName: test.project_name,
        projectId: test.project_uuid,
        testId: test.id
      }))
    : [],
  paymentAmount: remote.payment_amount,
  paymentAmountDefault: remote.payment_amount_default,
  paymentCurrency: remote.payment_currency,
  paymentCurrencyDefault: remote.payment_currency_default,
  paymentStatus: remote.payment_status,
  paymentDate: remote.payment_date && dayjs(remote.payment_date),
  paymentCreatedAt: remote.payment_created_at && dayjs(remote.payment_created_at),
  applicationStatus: participantStatusMapping[remote.application_status]
})

interface RescheduleVisitResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

interface RescheduleVisitOptions {
  visitId: number
  date: Dayjs
  startTime: string
  endTime: string
  freeSlot: boolean
  userTimezone: string
}

export const rescheduleVisit = (
  { visitId, date, startTime, endTime, freeSlot, userTimezone }: RescheduleVisitOptions,
  responseHandlers?: RescheduleVisitResponseHandlers
) => {
  const query = {
    start: { datetime: dayjs.tz(`${date.format('YYYY-MM-DD')}T${startTime}`, userTimezone).toISOString() },
    end: { datetime: dayjs.tz(`${date.format('YYYY-MM-DD')}T${endTime}`, userTimezone).toISOString() },
    reduce_capacity: !freeSlot
  }

  const { req, cancel } = fetchApi.post(`visits/${visitId}/reschedule`, query)

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<RescheduleVisitResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface RemoveVisitResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const removeVisit = ({ visitId }: { visitId: number }, responseHandlers: RemoveVisitResponseHandlers) => {
  const { req, cancel } = fetchApi.delete(`visits/${visitId}`)

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<RemoveVisitResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface VisitsSearchOptions {
  search?: string
  endDate?: Dayjs
  startDate?: Dayjs
  status?: string[]
  visit?: string[]
  location?: string[]
  studyId?: string[]
  recruitmentStudyId?: string[]
  projectId?: string[]
}

const parseBookedVisitsSearchOptions = (options: VisitsSearchOptions) => {
  const study = options?.studyId?.length ? `study:${options.studyId.join(',study:')}` : undefined
  const recruitment_study = options?.recruitmentStudyId?.length
    ? `recruitment_study:${options.recruitmentStudyId.join(',recruitment_study:')}`
    : undefined
  const project = options?.projectId?.length ? `project:${options.projectId.join(',project:')}` : undefined
  const parent = [study, recruitment_study, project].filter(Boolean).join(',')

  return {
    search: options?.search?.toLowerCase(),
    status: options?.status?.length ? options.status.join(',') : undefined,
    visit: options?.visit?.length ? options.visit.join(',') : undefined,
    center_abbreviation: options?.location?.length ? options.location.join(',') : undefined,
    parent: parent || undefined,
    end_after: options.startDate ? getUtcString(options.startDate) : undefined,
    start_before: options.endDate ? getUtcString(options.endDate) : undefined
  }
}

type FetchVisitsOptions = VisitsSearchOptions & {
  sorter: VisitsSorter
  limit: number
  offset: number
}

interface FetchBookedVisitsResponseHandlers {
  onSuccess?: ({ bookedVisits, countAll }: { bookedVisits: CalendarVisit[]; countAll: number }) => void
  onRequestError?: (code: number) => void
}

interface FetchVisitsResponse {
  results: RemoteVisit[]
  dates: string[]
  count: number
  count_scheduled: number
  count_completed: number
  count_not_done: number
  count_cancelled: number
}

export interface VisitsSorter {
  field: keyof CalendarVisit | 'date'
  order: SorterOrder
}

const sorterFields = {
  date: ['event__start', 'visit_name', 'participant_name'],
  visitName: ['event__public_title'],
  participantName: ['subject__full_name'],
  centerName: ['event__center__name'],
  paymentAmount: ['payment_amount'],
  status: ['status']
}

export const fetchVisits = (
  { options }: { options: FetchVisitsOptions },
  responseHandlers?: FetchBookedVisitsResponseHandlers
) => {
  const sorter = prepareSorter<typeof sorterFields, VisitsSorter>(sorterFields, options.sorter)
  const query = {
    ordering: sorter,
    limit: options?.limit,
    offset: options?.offset,
    ...parseBookedVisitsSearchOptions(options),
    expand: 'event.schedule,subject,event.recruitment_study,event.project,event.study,event.center,project_tests'
  }
  const { req, cancel } = fetchApi.get<FetchVisitsResponse>('visits', query)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchBookedVisitsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        bookedVisits: body.results.map(visit => parseRemoteVisit(visit)),
        countAll: body.count
      })
    }
  })

  return cancel
}

interface LinkTestToVisitOptions {
  projectId: string
  visitId: string
}

interface LinkTestToVisitResponseHandlers {
  onSuccess?: (testIds: string[]) => void
  onRequestError?: (code: number) => void
}

export const linkTestToVisit = (
  { projectId, visitId }: LinkTestToVisitOptions,
  responseHandlers?: LinkTestToVisitResponseHandlers
) => {
  const { req, cancel } = fetchApi.put<{ project_tests: string[] }>(`visits/${visitId}/link-project-test`, {
    project: projectId
  })

  req.then(({ error, status, body }) => {
    if (error) {
      createErrorsHandlers<LinkTestToVisitResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.project_tests)
    }
  })

  return cancel
}
