import './ParticipantsTableConfirmation.less'

import { Button, Form } from 'antd'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import {
  CalendarVisit,
  DisqualifiedCause,
  Participant,
  ParticipantStatus,
  PaymentType,
  fetchAllSelectedParticipants,
  getPossibleParticipantTransitions
} from '../../../../../requests'
import { bulkUpdateParticipantsStatus } from '../../../../../requests/recruitment/participantsVisit'
import { DatacAvatar, DatacBox, DatacMessage, DatacModal, DatacRecordStatusTag } from '../../../../common'
import { useRecruitmentStudyDetailsStore } from '../../RecruitmentStudyDetailsStore'
import { ApplicationStatus } from './ApplicationStatus'
import { ConfirmationPayments } from './ConfirmationPayments'
import { ConfirmationSuccess } from './ConfirmationSuccess'

interface ParticipantsTableConfirmationProps {
  records: Participant[]
  isOpened: boolean
  onClose: () => void
  filters: Record<string, string[]>
  search: string
  isEverythingSelected: boolean
}

export const ParticipantsTableConfirmation: React.FC<ParticipantsTableConfirmationProps> = ({
  records,
  isOpened,
  onClose,
  filters,
  search,
  isEverythingSelected
}) => {
  const intl = useScopedIntl('')
  const intlConfirmation = useScopedIntl('recruitment.study.schedules.table.confirmation')
  const intlModal = useScopedIntl('recruitment.study.candidates.status.modal')
  const [cancelledVisits, setCancelledVisits] = useState<CalendarVisit[]>()
  const [currentRecords, setCurrentRecords] = useState<Participant[]>()
  const [currentStep, setCurrentStep] = useState(1)
  const { study } = useRecruitmentStudyDetailsStore()
  const [targetStatus, setTargetStatus] = useState<ParticipantStatus>()
  const [paymentAmount, setPaymentAmount] = useState<number>()
  const [hasPaymentStep, setHasPaymentStep] = useState(false)
  const [possibleTransitions, setPossibleTransitions] = useState<ParticipantStatus[]>([])
  const [isAddingComment, setIsAddingComment] = useState(false)
  const [paymentComment, setPaymentComment] = useState<string>()
  const [formInstance] = Form.useForm()
  const [disqualifiedCause, setDisqualifiedCause] = useState<DisqualifiedCause>()

  const resetPayment = () => {
    setPaymentComment(null)
    setPaymentAmount(null)
    setIsAddingComment(false)
  }

  useEffect(() => {
    setHasPaymentStep(
      study.paymentType === PaymentType.Study &&
        [ParticipantStatus.Completed, ParticipantStatus.Excluded, ParticipantStatus.Disqualified].includes(targetStatus)
    )
    resetPayment()
  }, [targetStatus])

  useEffect(() => {
    if (!currentRecords?.length) return

    setPossibleTransitions(getPossibleParticipantTransitions(currentRecords.map(r => r.status)))
  }, [currentRecords])

  useEffect(() => {
    if (!isOpened) return
    setCurrentStep(1)
    resetPayment()
    setTargetStatus(null)
    setCurrentRecords([])
    setDisqualifiedCause(DisqualifiedCause.NotEligible)

    if (records && !isEverythingSelected) {
      setCurrentRecords(records)
      return
    }

    fetchAllSelectedParticipants(
      {
        studyId: study.id,
        options: {
          search,
          status: filters?.status
        }
      },
      {
        onSuccess: setCurrentRecords,
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }, [isOpened])

  const isNextDisabled = currentStep === 1 && !targetStatus

  const goToNextStep = () => {
    if (currentStep === 3) {
      onClose()
      return
    }

    if (currentStep === 2 || !hasPaymentStep) {
      formInstance.validateFields()
      if (isAddingComment && !paymentComment) return

      bulkUpdateParticipantsStatus(
        {
          studyId: study.id,
          records: currentRecords.map(v => Number(v.id)),
          search,
          status: filters?.status,
          targetStatus,
          paymentAmount,
          paymentComment: isAddingComment ? paymentComment : undefined,
          disqualifiedCause
        },
        {
          onSuccess: cancelledVisits => {
            setCancelledVisits(cancelledVisits)
            setCurrentStep(3)
          },
          onNoCurrency: () =>
            DatacMessage.error(intlConfirmation('no_currency.title'), intlConfirmation('no_currency.description')),
          onWrongStatus: () =>
            DatacMessage.error(intlModal('wrong_status.title'), intlModal('wrong_status.description')),
          onRequestError: code => DatacMessage.genericError(intl, code)
        }
      )
      return
    }

    setCurrentStep(currentStep + 1)
  }

  useEffect(() => {
    if (isAddingComment) return

    setPaymentComment(null)
    formInstance.setFieldsValue({ comment: null })
  }, [isAddingComment])

  const theoreticalAmount = study.paymentType === PaymentType.Study ? study.payment : 0

  const getNextButtonLabel = () => {
    if (currentStep === 3) return intl('common.close')
    if (currentStep === 2) return intl('common.finish')
    return intl('common.next')
  }

  return (
    <Form form={formInstance}>
      <DatacModal
        className="participants-table-confirmation"
        isOpened={isOpened && !!currentRecords?.length}
        onClose={onClose}
        noFooter
        title={
          currentRecords?.length === 1
            ? intlConfirmation('title.single')
            : intlConfirmation('title', { count: currentRecords?.length })
        }
      >
        <DatacBox className="participants-table-confirmation__left">
          <div className="participants-table-confirmation__left__participants">
            {currentRecords?.map(record => (
              <div key={record.id} className="participants-table-confirmation__left__participants__participant">
                <DatacAvatar
                  photoThumbnail={record.photoThumbnail}
                  firstName={record.firstName}
                  lastName={record.lastName}
                />
                <div className="participants-table-confirmation__left__participants__participant__info">
                  <div className="participants-table-confirmation__left__participants__participant__info__name">
                    {record.firstName} {record.lastName}`
                  </div>
                  <DatacRecordStatusTag status={record.status} coloredBackground />
                </div>
              </div>
            ))}
          </div>
          <div className="participants-table-confirmation__left__study">
            {intlConfirmation('study', { name: study.name })}{' '}
          </div>
        </DatacBox>
        <div className="participants-table-confirmation__right">
          <div className="participants-table-confirmation__right__steps">
            <div className="participants-table-confirmation__right__steps__lines">
              <div className="participants-table-confirmation__right__steps__lines__line active" />
              {hasPaymentStep && (
                <div
                  className={classNames('participants-table-confirmation__right__steps__lines__line', {
                    active: currentStep > 1
                  })}
                />
              )}
            </div>
            {currentStep === 3
              ? intlConfirmation('success')
              : intlConfirmation('step', { current: currentStep, total: hasPaymentStep ? 2 : 1 })}
          </div>
          <div className="participants-table-confirmation__right__content">
            {currentStep === 1 && (
              <ApplicationStatus
                possibleTransitions={possibleTransitions}
                selectedStatus={targetStatus}
                onStatusChange={setTargetStatus}
                selectedCause={disqualifiedCause}
                onCauseChange={setDisqualifiedCause}
              />
            )}
            {currentStep === 2 && (
              <ConfirmationPayments
                applicationStatus={targetStatus}
                theoreticalAmount={theoreticalAmount}
                currency={study.currency}
                setPaymentAmount={setPaymentAmount}
                paymentAmount={paymentAmount}
                paymentComment={paymentComment}
                setPaymentComment={setPaymentComment}
                isAddingComment={isAddingComment}
                setIsAddingComment={setIsAddingComment}
                participants={currentRecords}
              />
            )}
            {currentStep === 3 && (
              <ConfirmationSuccess cancelledVisits={cancelledVisits} multiple={currentRecords?.length > 1} />
            )}
          </div>
          <div className="participants-table-confirmation__right__footer">
            {currentStep > 1 && currentStep < 3 ? (
              <Button type="default" size="large" onClick={() => setCurrentStep(currentStep - 1)}>
                {intl('common.back')}
              </Button>
            ) : (
              <div />
            )}
            <Button type="primary" size="large" disabled={isNextDisabled} onClick={goToNextStep}>
              {getNextButtonLabel()}
            </Button>
          </div>
        </div>
      </DatacModal>
    </Form>
  )
}
