/* eslint-disable camelcase */
import dayjs, { Dayjs } from 'dayjs'

import { createErrorsHandlers, localeFromPath, prepareSorter } from '../../utils'
import { SorterOrder } from '../SorterOrder'
import { SelectionKeys } from '../exports'
import { fetchApi } from '../fetchApi'
import { Condition, ConditionalLogicOperator, prepareConditionForSave } from '../forms'
import { PaymentStatus } from '../payments'
import {
  RemoteSubjectInRepository,
  SimpleAnswer,
  SubjectInRepository,
  SubjectStatus,
  parseRemoteSimpleAnswer,
  parseRemoteSubject
} from '../subjectRepository'
import { InternationalPhoneNumber, MessageType, parseRemotePhoneNumber } from '../subjects'
import { TableColumnVariableSource } from '../variables'

export enum DisqualifiedCause {
  NotEligible = 'NOT_ELIGIBLE',
  NotAvailable = 'NOT_AVAILABLE'
}

export enum RemoteParticipantStatus {
  New = 'NEW',
  Contacted = 'CONTACTED',
  NotInterested = 'NOT_INTERESTED',
  Interested = 'INTERESTED',
  Unqualified = 'UNQUALIFIED',
  QualifiedForScreening = 'QUALIFIED_FOR_SCREENING',
  Rejected = 'REJECTED',
  Enrolled = 'ENROLLED',
  Completed = 'COMPLETED'
}

export enum ParticipantStatus {
  Prospect = 'PROSPECT',
  Contacted = 'CONTACTED',
  NotInterested = 'NOT_INTERESTED',
  Interested = 'INTERESTED',
  Disqualified = 'DISQUALIFIED',
  Qualified = 'QUALIFIED',
  Enrolled = 'ENROLLED',
  Completed = 'COMPLETED',
  Excluded = 'EXCLUDED'
}

export enum PoolAction {
  Email = 'EMAIL',
  Sms = 'SMS',
  NoInvite = 'NO_INVITATION'
}

export const participantStatusMapping = {
  [RemoteParticipantStatus.New]: ParticipantStatus.Prospect,
  [RemoteParticipantStatus.Contacted]: ParticipantStatus.Contacted,
  [RemoteParticipantStatus.NotInterested]: ParticipantStatus.NotInterested,
  [RemoteParticipantStatus.Interested]: ParticipantStatus.Interested,
  [RemoteParticipantStatus.Unqualified]: ParticipantStatus.Disqualified,
  [RemoteParticipantStatus.QualifiedForScreening]: ParticipantStatus.Qualified,
  [RemoteParticipantStatus.Rejected]: ParticipantStatus.Excluded,
  [RemoteParticipantStatus.Enrolled]: ParticipantStatus.Enrolled,
  [RemoteParticipantStatus.Completed]: ParticipantStatus.Completed
}

export const participantStatusForSaveMapping = {
  [ParticipantStatus.Prospect]: RemoteParticipantStatus.New,
  [ParticipantStatus.Contacted]: RemoteParticipantStatus.Contacted,
  [ParticipantStatus.NotInterested]: RemoteParticipantStatus.NotInterested,
  [ParticipantStatus.Interested]: RemoteParticipantStatus.Interested,
  [ParticipantStatus.Disqualified]: RemoteParticipantStatus.Unqualified,
  [ParticipantStatus.Qualified]: RemoteParticipantStatus.QualifiedForScreening,
  [ParticipantStatus.Excluded]: RemoteParticipantStatus.Rejected,
  [ParticipantStatus.Enrolled]: RemoteParticipantStatus.Enrolled,
  [ParticipantStatus.Completed]: RemoteParticipantStatus.Completed
}

const participantStatusTransitionsMap = {
  [ParticipantStatus.Prospect]: [
    ParticipantStatus.Contacted,
    ParticipantStatus.NotInterested,
    ParticipantStatus.Interested,
    ParticipantStatus.Disqualified,
    ParticipantStatus.Qualified
  ],
  [ParticipantStatus.Contacted]: [
    ParticipantStatus.NotInterested,
    ParticipantStatus.Interested,
    ParticipantStatus.Disqualified,
    ParticipantStatus.Qualified
  ],
  [ParticipantStatus.NotInterested]: [
    ParticipantStatus.Contacted,
    ParticipantStatus.Interested,
    ParticipantStatus.Disqualified,
    ParticipantStatus.Qualified
  ],
  [ParticipantStatus.Interested]: [
    ParticipantStatus.Contacted,
    ParticipantStatus.NotInterested,
    ParticipantStatus.Disqualified,
    ParticipantStatus.Qualified
  ],
  [ParticipantStatus.Disqualified]: [
    ParticipantStatus.Qualified,
    ParticipantStatus.Enrolled,
    ParticipantStatus.Completed,
    ParticipantStatus.Excluded
  ],
  [ParticipantStatus.Qualified]: [
    ParticipantStatus.Disqualified,
    ParticipantStatus.Enrolled,
    ParticipantStatus.Completed,
    ParticipantStatus.Excluded
  ],
  [ParticipantStatus.Excluded]: [
    ParticipantStatus.Disqualified,
    ParticipantStatus.Qualified,
    ParticipantStatus.Enrolled,
    ParticipantStatus.Completed
  ],
  [ParticipantStatus.Enrolled]: [
    ParticipantStatus.Disqualified,
    ParticipantStatus.Qualified,
    ParticipantStatus.Completed,
    ParticipantStatus.Excluded
  ],
  [ParticipantStatus.Completed]: [
    ParticipantStatus.Disqualified,
    ParticipantStatus.Qualified,
    ParticipantStatus.Enrolled,
    ParticipantStatus.Excluded
  ]
}

export const getPossibleParticipantTransitions = (selectedStatuses: ParticipantStatus[]) => {
  const unsortedPossibleTransitions = selectedStatuses
    .map(s => participantStatusTransitionsMap[s])
    .reduce((acc, curr) => acc.filter(s => curr.includes(s)))

  return (Object.values(ParticipantStatus) as ParticipantStatus[]).filter(s => unsortedPossibleTransitions.includes(s))
}

export const clearRemoteParticipantStatus = <T>(status: T | RemoteParticipantStatus) => {
  if (Object.values(RemoteParticipantStatus).includes(status as RemoteParticipantStatus))
    return participantStatusMapping[status as RemoteParticipantStatus]
  return status as T
}

export interface Participant {
  id: string
  datacaptId: string
  firstName: string
  lastName: string
  email: string
  internationalPhoneNumber?: InternationalPhoneNumber
  appliedDate: Date
  progress: number
  status: ParticipantStatus
  language: string
  centerId: string
  centerAbbreviation?: string
  note: string
  variableAnswers?: Record<TableColumnVariableSource, Record<string, SimpleAnswer>>
  photoThumbnail: string
  paymentAmount: number
  paymentCurrency: string
  paymentStatus: PaymentStatus
  paymentDate: Dayjs
  paymentCreatedAt: Dayjs
  paymentTheoreticalAmount: number
}

export interface RemoteParticipant {
  id: number
  datacapt_id: string
  first_name: string
  last_name: string
  email: string
  phone: string
  language: string
  applied_date: string
  status: RemoteParticipantStatus
  progress: number
  center_id: number
  note: string
  photo_thumbnail: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  variable_answers?: Record<TableColumnVariableSource, Record<string, any>>
  payment_amount: number
  payment_currency: string
  payment_status: PaymentStatus
  payment_date: string
  payment_created_at: string
}

interface FetchParticipantResponse {
  count: number
  next: number
  results: RemoteParticipant[]
}

export const parseRemoteParticipant: (participant: RemoteParticipant) => Participant = (
  participant: RemoteParticipant
) => ({
  id: String(participant.id),
  datacaptId: participant.datacapt_id,
  firstName: participant.first_name,
  lastName: participant.last_name,
  email: participant.email,
  internationalPhoneNumber: parseRemotePhoneNumber(participant.phone),
  language: participant.language,
  appliedDate: participant.applied_date ? new Date(participant.applied_date) : undefined,
  progress: participant.progress,
  status: participantStatusMapping[participant.status],
  centerId: String(participant.center_id),
  note: participant.note,
  photoThumbnail: participant.photo_thumbnail,
  variableAnswers:
    participant.variable_answers &&
    (Object.fromEntries(
      (Object.keys(participant.variable_answers) as TableColumnVariableSource[]).map(source => {
        return [
          source,
          source
            ? {
                ...Object.entries(participant.variable_answers[source]).reduce(
                  (acc, [key, value]) => ({ ...acc, [key]: parseRemoteSimpleAnswer(value) }),
                  {}
                )
              }
            : {}
        ]
      })
    ) as Record<TableColumnVariableSource, Record<string, SimpleAnswer>>),
  paymentAmount: participant.payment_amount,
  paymentCurrency: participant.payment_currency,
  paymentStatus: participant.payment_status,
  paymentDate: participant.payment_date && dayjs(participant.payment_date),
  paymentCreatedAt: participant.payment_created_at && dayjs(participant.payment_created_at),
  paymentTheoreticalAmount: 0 // todo: check where it is on BE
})

export interface ParticipantsSorter {
  field: keyof Participant
  order: SorterOrder
}

const sorterFields = {
  datacaptId: ['subject__datacapt_id'],
  firstName: ['subject__first_name', 'subject__last_name', 'subject__datacapt_id'],
  email: ['subject__first_name', 'subject__last_name', 'subject__email'],
  stage: ['status'],
  appliedDate: ['applied_date'],
  survey: ['progress'],
  centerAbbreviation: ['center__abbreviation']
}

interface FetchParticipantsOptions {
  studyId: string
  options?: {
    limit?: number
    offset?: number
    sorter?: ParticipantsSorter
    search?: string
    filters?: {
      status: string[]
    }
  }
  poolId?: number
}

interface FetchParticipantsResponseHandlers {
  onSuccess?: ({ participants, countAll }: { participants: Participant[]; countAll: number }) => void
  onRequestError?: (code: number) => void
}

export const fetchParticipants = (
  { studyId, options, poolId }: FetchParticipantsOptions,
  responseHandlers?: FetchParticipantsResponseHandlers
) => {
  const sorter = prepareSorter<typeof sorterFields, ParticipantsSorter>(sorterFields, options.sorter)
  const query = {
    limit: options.limit,
    offset: options.offset,
    ordering: sorter,
    search: options.search,
    status: options.filters?.status?.map(s => participantStatusForSaveMapping[s as ParticipantStatus]),
    recruitment_pool_id: poolId
  }

  const { req, cancel } = fetchApi.get<FetchParticipantResponse>(`recruitment/studies/${studyId}/records/list`, query)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchParticipantsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        participants: body.results.map(parseRemoteParticipant),
        countAll: body.count
      })
    }
  })

  return cancel
}

interface FetchAllSelectedParticipantsOptions {
  studyId: string
  options?: {
    search?: string
    status: string[]
  }
}
interface FetchAllSelectedParticipantsResponseHandlers {
  onSuccess?: (participants: Participant[]) => void
  onRequestError?: (code: number) => void
}

export const fetchAllSelectedParticipants = (
  { studyId, options }: FetchAllSelectedParticipantsOptions,
  responseHandlers?: FetchAllSelectedParticipantsResponseHandlers
) => {
  const query = {
    search: options.search,
    status: options.status
  }

  const { req, cancel } = fetchApi.get<RemoteParticipant[]>(`recruitment/studies/${studyId}/records/list/full`, query)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchAllSelectedParticipantsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.map(parseRemoteParticipant))
    }
  })

  return cancel
}

interface FetchParticipantOptions {
  participantId: string
  studyId: string
}

interface FetchParticipantResponseHandlers {
  onSuccess?: ({ participant }: { participant: Participant }) => void
  onRequestError?: (code: number) => void
}

export const fetchParticipant = (
  { participantId, studyId }: FetchParticipantOptions,
  responseHandlers?: FetchParticipantResponseHandlers
) => {
  // todo: add support to fetch without studyId
  const { req, cancel } = fetchApi.get<RemoteParticipant>(`recruitment/studies/${studyId}/records/${participantId}`)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchParticipantResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        participant: parseRemoteParticipant(body)
      })
    }
  })

  return cancel
}

interface UpdateParticipantStatusOptions {
  participantId: string
  status: ParticipantStatus
  studyId: string
}

interface UpdateParticipantStatusResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const updateParticipantStatus = (
  { participantId, status, studyId }: UpdateParticipantStatusOptions,
  responseHandlers: UpdateParticipantStatusResponseHandlers
) => {
  const { req, cancel } = fetchApi.patch(`recruitment/studies/${studyId}/records/${participantId}`, {
    status: participantStatusForSaveMapping[status]
  })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<UpdateParticipantStatusResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface UpdateParticipantNoteOptions {
  participantId: string
  note: string
  studyId: string
}

interface UpdateParticipantNoteResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const updateParticipantNote = (
  { participantId, note, studyId }: UpdateParticipantNoteOptions,
  responseHandlers: UpdateParticipantNoteResponseHandlers
) => {
  const { req, cancel } = fetchApi.patch(`recruitment/studies/${studyId}/records/${participantId}`, { note })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<UpdateParticipantNoteResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface FetchSubjectsForRecruitmentOptions {
  studyId: string
  searchPhrase: string
  conditions?: Condition[]
  status?: {
    selections: SubjectStatus[]
    operator: ConditionalLogicOperator
  }
  poolId?: number
  limit?: number
  offset?: number
  sorter?: RecruitmentPoolSubjectSorter
}

interface RemoteRecruitmentPoolSubject extends RemoteSubjectInRepository {
  invited_on: string
}

export interface RecruitmentPoolSubject extends SubjectInRepository {
  invitedOn: Dayjs
}

const subjectSorterFields = {
  id: ['datacapt_id'],
  firstName: ['first_name', 'last_name'],
  status: ['status']
}

export interface RecruitmentPoolSubjectSorter {
  field: keyof RecruitmentPoolSubject
  order: SorterOrder
}

interface FetchSubjectsForRecruitmentResponse {
  results: RemoteRecruitmentPoolSubject[]
  count: number
  count_ommited: number
  count_new: number
  count_with_email: number
  count_with_phone: number
}

interface FetchSubjectsForRecruitmentResponseHandlers {
  onSuccess?: (
    subjects: RecruitmentPoolSubject[],
    count: number,
    countOmmited: number,
    countNew: number,
    countWithEmail: number,
    countWithPhone: number
  ) => void
  onRequestError?: (code: number) => void
}

export const fetchSubjectsForRecruitment = (
  {
    studyId,
    searchPhrase: search,
    conditions,
    status,
    poolId,
    limit,
    offset,
    sorter
  }: FetchSubjectsForRecruitmentOptions,
  responseHandlers: FetchSubjectsForRecruitmentResponseHandlers
) => {
  const url = `recruitment/studies/${studyId}/subjects/filters/?limit=${limit}&offset=${offset}&`
  const query = {
    search,
    conditions: conditions.map(prepareConditionForSave),
    status: status || undefined,
    pool_id: poolId,
    ordering: prepareSorter<typeof subjectSorterFields, RecruitmentPoolSubjectSorter>(subjectSorterFields, sorter).join(
      ','
    )
  }
  const { req, cancel } = fetchApi.post<FetchSubjectsForRecruitmentResponse>(url, query)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchSubjectsForRecruitmentResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess(
        body.results?.map(subject => ({
          ...parseRemoteSubject(subject as RemoteSubjectInRepository),
          invitedOn: subject.invited_on ? dayjs(subject.invited_on) : null
        })),
        body.count,
        body.count_ommited || 0,
        body.count_new || 0,
        body.count_with_email || 0,
        body.count_with_phone || 0
      )
    }
  })

  return cancel
}

interface RemoteRecruitmentPoolCondition {
  reference_question: string
  answer_value:
    | number
    | {
        selections: string[]
      }
  operator: ConditionalLogicOperator
}

interface RemoteRecruitmentPool {
  id: number
  name: string
  size: number
  quota: number
  action: PoolAction
  message_body: string
  message_subject: string
  last_editor: string
  last_updated: string
  creation_date: string
  conditions: RemoteRecruitmentPoolCondition[]
  status: {
    selections: SubjectStatus[]
    operator: ConditionalLogicOperator
  }
}

export interface RecruitmentPool {
  id: number
  name: string
  size: number
  quota: number
  action: PoolAction
  smsBody: string
  emailBody: string
  emailSubject: string
  lastEditor: string
  lastUpdated: Dayjs
  createdDate: Dayjs
  conditions: Condition[]
  status: {
    selections: SubjectStatus[]
    operator: ConditionalLogicOperator
  }
}

export const parseRemoteRecruitmentPoolCondition = (remoteCondition: RemoteRecruitmentPoolCondition): Condition => {
  if (!remoteCondition) return null
  return {
    referenceQuestionId: remoteCondition.reference_question,
    answerValue:
      typeof remoteCondition.answer_value === 'object'
        ? remoteCondition.answer_value.selections
        : remoteCondition.answer_value,
    operator: remoteCondition.operator
  }
}

const parseRemoteRecruitmentPool: (pool: RemoteRecruitmentPool) => RecruitmentPool = pool => ({
  id: pool.id,
  name: pool.name,
  size: pool.size,
  quota: pool.quota,
  action: pool.action,
  smsBody: pool.action === PoolAction.Sms ? pool.message_body : undefined,
  emailBody: pool.action === PoolAction.Email ? pool.message_body : undefined,
  emailSubject: pool.message_subject,
  lastEditor: pool.last_editor,
  lastUpdated: pool.last_updated && dayjs(pool.last_updated).locale(localeFromPath()),
  createdDate: pool.creation_date && dayjs(pool.creation_date).locale(localeFromPath()),
  conditions: pool.conditions.map(parseRemoteRecruitmentPoolCondition),
  status: pool.status
})

interface FetchRecruitmentPoolsOptions {
  studyId: string
}

interface FetchRecruitmentPoolsResponse {
  results: RemoteRecruitmentPool[]
}

interface FetchRecruitmentPoolsResponseHandlers {
  onSuccess?: (pools: RecruitmentPool[]) => void
  onRequestError?: (code: number) => void
}

export const fetchRecruitmentPools = (
  { studyId }: FetchRecruitmentPoolsOptions,
  responseHandlers: FetchRecruitmentPoolsResponseHandlers
) => {
  const url = `recruitment/studies/${studyId}/pools`
  const { req, cancel } = fetchApi.get<FetchRecruitmentPoolsResponse>(url)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchRecruitmentPoolsResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess(body.results?.map(pool => parseRemoteRecruitmentPool(pool)))
    }
  })

  return cancel
}

export interface SaveRecruitmentPoolOptions {
  poolId: number
  studyId: string
  options: {
    name: string
    subjectIds: string[]
    action: PoolAction
    emailSubject: string
    emailBody: string
    smsBody: string
    filters: {
      conditions?: Condition[]
      status?: {
        selections: SubjectStatus[]
        operator: ConditionalLogicOperator
      }
    }
    search: string
  }
}

interface SaveRecruitmentPoolResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const saveRecruitmentPool = (
  { poolId, studyId, options }: SaveRecruitmentPoolOptions,
  responseHandlers?: SaveRecruitmentPoolResponseHandlers
) => {
  const query = {
    name: options.name,
    datacapt_ids: options.subjectIds,
    action: options.action,
    message_body: options.emailBody ?? options.smsBody,
    message_subject: options.emailSubject,
    conditions: options.filters.conditions.map(prepareConditionForSave),
    search: options.search,
    status: options.filters.status || undefined
  }
  const { req, cancel } = poolId
    ? fetchApi.patch(`recruitment/studies/${studyId}/pools/${poolId}`, query)
    : fetchApi.post(`recruitment/studies/${studyId}/pools`, query)

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<SaveRecruitmentPoolResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface RemoveRecruitmentPoolOptions {
  studyId: string
  poolId: number
}

interface RemoveRecruitmentPoolResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
  onError?: () => void
}

export const removeRecruitmentPool = (
  { studyId, poolId }: RemoveRecruitmentPoolOptions,
  responseHandlers?: RemoveRecruitmentPoolResponseHandlers
) => {
  const { req, cancel } = fetchApi.delete(`recruitment/studies/${studyId}/pools/${poolId}`)

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<RemoveRecruitmentPoolResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

export interface ResendParticipantInvitesOptions {
  participantIds: SelectionKeys
  studyId: string
  messageType: MessageType
  messageSubject: string
  messageBody: string
  search: string
  status: ParticipantStatus[]
}

interface ResendParticipantInvitesResponse {
  count: number
}

interface ResendParticipantInvitesResponseHandlers {
  onSuccess?: (count: number) => void
  onRequestError?: (code: number) => void
}

export const resendParticipantInvites = (
  {
    participantIds,
    studyId,
    messageType,
    messageSubject,
    messageBody,
    search,
    status
  }: ResendParticipantInvitesOptions,
  responseHandlers?: ResendParticipantInvitesResponseHandlers
) => {
  const { req, cancel } = fetchApi.post<ResendParticipantInvitesResponse>(
    `recruitment/studies/${studyId}/records/resend`,
    {
      participant_ids: participantIds,
      message_type: messageType,
      message_subject: messageSubject,
      message_body: messageBody,
      search,
      status
    }
  )

  req.then(({ error, status, body }) => {
    if (error) {
      createErrorsHandlers<ResendParticipantInvitesResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.count)
    }
  })

  return cancel
}

export interface CountResendParticipantInvitesOptions {
  studyId: string
  search: string
  status: ParticipantStatus[]
}

interface CountResendParticipantInvitesResponse {
  count_email: number
  count_sms: number
}

interface CountResendParticipantInvitesResponseHandlers {
  onSuccess?: (count: { [MessageType.Email]: number; [MessageType.Sms]: number }) => void
  onRequestError?: (code: number) => void
}

export const countResendParticipantInvites = (
  { studyId, search, status }: CountResendParticipantInvitesOptions,
  responseHandlers?: CountResendParticipantInvitesResponseHandlers
) => {
  const { req, cancel } = fetchApi.post<CountResendParticipantInvitesResponse>(
    `recruitment/studies/${studyId}/records/resend_count`,
    {
      search,
      status
    }
  )

  req.then(({ error, status, body }) => {
    if (error) {
      createErrorsHandlers<CountResendParticipantInvitesResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        [MessageType.Email]: body.count_email,
        [MessageType.Sms]: body.count_sms
      })
    }
  })

  return cancel
}
