import { navigate } from 'gatsby-plugin-react-intl'
import React from 'react'

import { LandingPage } from '../../../../components/LP/LandingPage'
import { AccountType } from '../../../../requests'
import { routes } from '../../../../routes'

const allowedHosts = [
  'testsrooms.lorealppd.com',
  'testsrooms-demo.lorealppd.com',
  'internal.datacapt-dev.net',
  'localhost:3000'
]

const IndexPage = () => {
  if (!allowedHosts.includes(window.location.host)) {
    navigate(routes.notFound(AccountType.Subject))
    return null
  }
  return <LandingPage />
}

export default IndexPage
export const Head = () => <title>L'Oréal Tests Rooms</title>
