import './EventEditSubjects.less'

import { InputNumber } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React, { useContext, useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import { AclFeature } from '../../../../../requests'
import { validateRequired } from '../../../../../validation'
import { UserContext } from '../../../../auth'
import { DatacFormItem, DatacInformationMessage, DatacOption } from '../../../../common'
import { EventPeopleSelect, EventPeopleSelectList, PeopleSelectType } from '../EventPeopleSelect'

export const defaultCapacity = 1

interface EventEditSubjectsProps {
  isVisible: boolean
  formInstance: FormInstance
  currentSubjects: DatacOption[]
  setCurrentSubjects: (subjects: DatacOption[]) => void
  currentCapacity: number
  setCurrentCapacity: (capacity: number) => void
}

export const EventEditSubjects: React.VFC<EventEditSubjectsProps> = ({
  isVisible,
  formInstance,
  currentSubjects,
  setCurrentSubjects,
  currentCapacity,
  setCurrentCapacity
}) => {
  const intlEvent = useScopedIntl('calendar.event')
  const intl = useScopedIntl('')
  const [error, setError] = useState<string>()
  const { user } = useContext(UserContext)

  useEffect(() => {
    if (!isVisible) return
    setError(undefined)
    formInstance.validateFields(['capacity'])
  }, [isVisible])

  const getError = (capacity: number, subjectsCount: number) => {
    if (capacity < subjectsCount) return setError(intlEvent('subjects.error'))
    return setError(undefined)
  }

  useEffect(() => {
    if (currentSubjects.length) {
      getError(currentCapacity, currentSubjects.length)
    }
  }, [currentSubjects.length, currentCapacity])

  useEffect(() => {
    if (error) formInstance.validateFields(['subjects'])
  }, [error])

  const onChangeCapacity = (value: number) => {
    setCurrentCapacity(value)
    getError(value, currentSubjects.length)
  }

  return (
    <div className="calendar-event-edit-subjects">
      {currentSubjects.some(s => !s.sublabel) && (
        <DatacInformationMessage type="warning" message={intlEvent('no_email.warning')} />
      )}
      <div className="calendar-event-edit-subjects__form">
        <DatacFormItem
          name="capacity"
          icon="users"
          validate={validateRequired(intl('common.required'))}
          isCalendarStyle
          label={intlEvent('capacity.placeholder')}
          error={error}
          validateStatus={error ? 'error' : undefined}
        >
          <InputNumber
            size="large"
            placeholder={intlEvent('capacity.placeholder')}
            precision={0}
            min={1}
            max={99}
            onChange={e => onChangeCapacity(e as number)}
          />
        </DatacFormItem>
        {user.canAccess(AclFeature.SubjectRepository) && (
          <DatacFormItem
            className="calendar-event-edit-subjects__form__select"
            name="subjects"
            error={error}
            validateStatus={error ? 'error' : undefined}
            hideErrorDescription
          >
            <EventPeopleSelect
              currentSelection={currentSubjects}
              setCurrentSelection={setCurrentSubjects}
              isVisible={isVisible}
              type={PeopleSelectType.Participants}
              capacity={currentCapacity}
            />
          </DatacFormItem>
        )}
      </div>
      <EventPeopleSelectList currentSelection={currentSubjects} setCurrentSelection={setCurrentSubjects} />
    </div>
  )
}
