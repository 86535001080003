import './ImportModal.less'

import { Form } from 'antd'
import React, { useEffect } from 'react'

import { useScopedIntl, useScopedIntlWithTags } from '../../../../hooks'
import {
  SkippedSubjectImportRow,
  importSubjectRepositoryFile,
  supportedSubjectRepositoryFileExtensions
} from '../../../../requests'
import { DatacFileUpload, DatacModal, DatacTitle, OnDatacFileUpload } from '../../../common'
import { useSubjectRepositoryDashboardStore } from '../SubjectRepositoryDashboardStore'

export const ImportModal: React.VFC = () => {
  const intlModal = useScopedIntl('subject_repository.import')
  const intlModalWithTags = useScopedIntlWithTags('subject_repository.import')
  const intl = useScopedIntl('')
  const [formInstance] = Form.useForm()
  const [skippedRows, setSkippedRows] = React.useState<SkippedSubjectImportRow[]>([])
  // const [overwrite, setOverwrite] = React.useState(false)
  const { isImportModalOpened, setIsImportModalOpened } = useSubjectRepositoryDashboardStore()

  useEffect(() => {
    if (!isImportModalOpened) {
      setSkippedRows([])
    }
  }, [isImportModalOpened])

  const onFileUpload: OnDatacFileUpload = options => {
    importSubjectRepositoryFile(
      {
        file: options.file
        // overwrite
      },
      {
        onSuccess: file => {
          options.onSuccess({ name: file.name, size: file.size, id: file.name })
          setSkippedRows(file.skipped)
        },
        onRequestError: code => options.onError(String(code))
      }
    )
  }

  return (
    <DatacModal
      title={intl('common.import')}
      isOpened={isImportModalOpened}
      onClose={() => setIsImportModalOpened(false)}
      className="subject-repository-import-modal"
      cancelLabel={intl('common.close')}
      afterClose={() => {
        formInstance.resetFields()
      }}
      noSubmit
    >
      <div className="subject-repository-import-modal__content">
        <Form form={formInstance}>
          {/* Temporarily rolled back, will be back in 4.17 */}
          {/* <DatacFormItem label={intlModal('overwrite.label')} description={intlModal('overwrite.description')}>
            <Switch checked={overwrite} onChange={() => setOverwrite(!overwrite)} />
          </DatacFormItem> */}
          <Form.Item name="file">
            <DatacFileUpload
              onFileUpload={onFileUpload}
              showNotifications
              supportedExtensions={supportedSubjectRepositoryFileExtensions}
              maxFilesNumber={1}
            />
          </Form.Item>
        </Form>
        {skippedRows?.length > 0 && (
          <div className="subject-repository-import-modal__skipped-rows">
            <DatacTitle size="small">{intlModal('skipped.title')}</DatacTitle>
            <div className="subject-repository-import-modal__skipped-rows__list">
              {skippedRows.map((skippedRow, index) => (
                <div className="subject-repository-import-modal__skipped-rows__list__item" key={index}>
                  {!skippedRow.values
                    ? intlModalWithTags('skipped.subject', { subject: `<b>${skippedRow.subjectId}</b>` })
                    : intlModalWithTags('skipped.values', {
                        subject: `<b>${skippedRow.subjectId}</b>`,
                        values: `<b>${skippedRow.values}</b>`
                      })}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </DatacModal>
  )
}
