import './BookAppointmentsSchedule.less'

import { Popover } from 'antd'
import classNames from 'classnames'
import dayjs, { Dayjs } from 'dayjs'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { Schedule, ScheduleType, ScheduleVisit } from '../../../../requests'
import { DatacBox, DatacIcon, DatacLoading, DatacTimezonesSelect, DatacTitle } from '../../../common'

enum DayStatus {
  Available = 'available',
  PartiallyBooked = 'partially_booked',
  Unavailable = 'unavailable'
}

interface BookAppointmentsScheduleProps {
  schedule: Schedule
  isFetchingSchedule: boolean
  onConfirm: () => void
  selectedSlots: number[]
  setSelectedSlots: (slots: number[]) => void
  timezone: string
  setTimezone: (timezone: string) => void
  goToUnavailable: () => void
}

export const BookAppointmentsSchedule: React.VFC<BookAppointmentsScheduleProps> = ({
  schedule,
  isFetchingSchedule,
  onConfirm,
  selectedSlots,
  setSelectedSlots,
  timezone,
  setTimezone,
  goToUnavailable
}) => {
  const intlBook = useScopedIntl('subject_dashboard.appointment.book')
  const intl = useScopedIntl('')
  const [week, setWeek] = useState<Dayjs[]>([])
  const [currentDate, setCurrentDate] = useState<Dayjs>()
  const [visit, setVisit] = useState<ScheduleVisit>(null)
  const [areInformationsVisible, setAreInformationsVisible] = useState(false)
  const [isTimezonePopoverOpen, setIsTimezonePopoverOpen] = useState(false)
  const [visitNumber, setVisitNumber] = useState(0)
  const [subsequentVisitsCount, setSubsequentVisitsCount] = useState(0)

  const openVisit = (visitNumber: number) => {
    if (!schedule) return
    setVisit(schedule.visits?.[visitNumber])
    setCurrentDate(schedule.visits?.[visitNumber]?.events?.filter(s => s.isAvailable)[0]?.startDate)
  }

  useEffect(() => {
    openVisit(visitNumber)
    setSubsequentVisitsCount((schedule?.visitsCount || 1) - 1)
  }, [visitNumber, schedule])

  useEffect(() => {
    if (!currentDate) return

    const startOfTheWeek = currentDate.clone().startOf('week')
    setWeek([1, 2, 3, 4, 5, 6, 7].map(day => startOfTheWeek.clone().day(day)))
  }, [currentDate])

  const goToPrevious = () => {
    setCurrentDate(dayjs(currentDate).subtract(1, 'week'))
  }

  const goToNext = () => {
    setCurrentDate(dayjs(currentDate).add(1, 'week'))
  }

  const getDayStatus = (day: Dayjs): DayStatus => {
    if (!visit?.events) return DayStatus.Unavailable

    const slots = visit.events.filter(e => e.startDate.isSame(day, 'day'))
    const availableSlotsCount = slots.filter(s => s.isAvailable).length

    if (availableSlotsCount === 0) return DayStatus.Unavailable
    if (availableSlotsCount <= slots.length / 2) return DayStatus.PartiallyBooked
    return DayStatus.Available
  }

  const subsequentLabel =
    subsequentVisitsCount > 1
      ? intlBook('subsequent_visits', { count: subsequentVisitsCount })
      : subsequentVisitsCount === 1 && intlBook('subsequent_visit')

  const informations = (isDesktop: boolean) =>
    (isDesktop || areInformationsVisible) && (
      <div className={classNames('subject-dashboard-book-appointment-schedule__information', { desktop: isDesktop })}>
        <div className="subject-dashboard-book-appointment-schedule__information__row">
          <DatacIcon name="fileText" raw /> {visit?.title}
        </div>
        {visit?.centerName && (
          <div className="subject-dashboard-book-appointment-schedule__information__row">
            <DatacIcon name="disc" raw /> {visit?.centerName}
          </div>
        )}
      </div>
    )

  const onTimezoneChange = (timezone: string) => {
    setTimezone(timezone)
    setIsTimezonePopoverOpen(false)
  }

  const getTimeInTimezone = (date: Dayjs, time: string, originalTimezone: string) => {
    const timeDate = `${date.format('YYYY-MM-DD')}T${time}`
    const dateInOriginalTimezone = dayjs.tz(timeDate, originalTimezone)

    return dateInOriginalTimezone.tz(timezone).format('HH:mm')
  }

  const goToNextVisit = () => {
    if (selectedSlots.length === schedule.visitsCount) {
      onConfirm()
    } else {
      setVisitNumber(visitNumber + 1)
    }
  }

  const onSelectSlot = (slotId: number) => {
    if (schedule.type === ScheduleType.Sequential) {
      setSelectedSlots([slotId])
      return
    }

    if (selectedSlots[visitNumber]) return

    const currentSlots = selectedSlots
    currentSlots.push(slotId)
    setSelectedSlots([...currentSlots])
    setTimeout(goToNextVisit, 500)
  }

  return (
    <div className="subject-dashboard-book-appointment-schedule">
      <DatacTitle>{intlBook('title')}</DatacTitle>
      {schedule?.type === ScheduleType.Sequential
        ? subsequentLabel
        : intlBook('visit_number', { count: visitNumber + 1, all: schedule?.visitsCount })}
      <DatacBox>
        <DatacLoading isLoading={isFetchingSchedule}>
          {informations(true)}

          <div className="subject-dashboard-book-appointment-schedule__header">
            <DatacTitle size="small">{currentDate?.format('MMM YYYY')}</DatacTitle>
            <DatacIcon name="chevronLeft" onClick={() => goToPrevious()} />
            <DatacIcon name="chevronRight" onClick={() => goToNext()} />
          </div>
          <div className="subject-dashboard-book-appointment-schedule__week">
            {week.map((day, index) => {
              const status = getDayStatus(day)
              return (
                <div
                  key={index}
                  className={classNames('subject-dashboard-book-appointment-schedule__week__day', status)}
                  onClick={() => status !== DayStatus.Unavailable && setCurrentDate(day)}
                >
                  <div className="subject-dashboard-book-appointment-schedule__week__day__label">
                    {day.format('dd')}
                  </div>
                  <div
                    className={classNames(
                      'subject-dashboard-book-appointment-schedule__week__day__date',
                      { selected: day.isSame(currentDate, 'day') },
                      status
                    )}
                  >
                    {day.format('D')}
                    <div
                      className={classNames(
                        'subject-dashboard-book-appointment-schedule__week__day__date__indicator',
                        status
                      )}
                    />
                  </div>
                </div>
              )
            })}
          </div>

          {informations(false)}

          <div className="subject-dashboard-book-appointment-schedule__additional">
            <DatacTitle size="small">{intlBook('select_time')}</DatacTitle>
            <div className="subject-dashboard-book-appointment-schedule__additional__timezone">
              <Popover
                content={
                  <div className="subject-dashboard-book-appointment-schedule__additional__timezone__popover">
                    <DatacTimezonesSelect showLabel defaultTimezone={timezone} onChange={onTimezoneChange} />
                  </div>
                }
                placement="bottomRight"
                trigger="click"
                open={isTimezonePopoverOpen}
                onOpenChange={setIsTimezonePopoverOpen}
              >
                {timezone} {intl('common.timezone')} ({dayjs().tz(timezone).format('HH:mm')})
                <DatacIcon raw name={isTimezonePopoverOpen ? 'chevronUp' : 'chevronDown'} />
              </Popover>
            </div>
            <div className="subject-dashboard-book-appointment-schedule__additional__information">
              <DatacIcon name="info" onClick={() => setAreInformationsVisible(!areInformationsVisible)} />
            </div>
          </div>

          <div className="subject-dashboard-book-appointment-schedule__slots">
            {visit?.events.map((slot, slotIndex) => {
              if (!slot.startDate.isSame(currentDate, 'day') || !slot.isAvailable) return null

              const isSelected = selectedSlots[visitNumber] === slot.id
              const startTime = getTimeInTimezone(slot.startDate, slot.startTime, visit.timezone)
              const endTime = getTimeInTimezone(slot.endDate, slot.endTime, visit.timezone)
              return (
                <div
                  key={slotIndex}
                  className={classNames('subject-dashboard-book-appointment-schedule__slots__slot', {
                    selected: isSelected
                  })}
                  onClick={() => onSelectSlot(slot.id)}
                >
                  <div
                    className={classNames('subject-dashboard-book-appointment-schedule__slots__slot__header', {
                      open: schedule?.type === ScheduleType.Open
                    })}
                  >
                    <div className="subject-dashboard-book-appointment-schedule__slots__slot__header__left">
                      <DatacTitle size="small">
                        {startTime} - {endTime}
                      </DatacTitle>
                      {schedule?.type === ScheduleType.Sequential && subsequentLabel}
                    </div>
                    {schedule.type === ScheduleType.Sequential && schedule.visitsCount > 1 && (
                      <div className="subject-dashboard-book-appointment-schedule__slots__slot__header__right">
                        {isSelected ? (
                          <DatacIcon name="chevronUp" type="white" />
                        ) : (
                          <DatacIcon name="chevronDown" type="white" />
                        )}
                      </div>
                    )}
                  </div>
                  {isSelected && schedule.type === ScheduleType.Sequential && schedule?.visitsCount > 1 && (
                    <div className="subject-dashboard-book-appointment-schedule__slots__slot__details">
                      {schedule.visits.map((v, scheduleIndex) => {
                        const slot = v.events[slotIndex]
                        return (
                          <div
                            key={scheduleIndex}
                            className="subject-dashboard-book-appointment-schedule__slots__slot__details__visit"
                          >
                            <div className="subject-dashboard-book-appointment-schedule__slots__slot__details__visit__left">
                              <div className="subject-dashboard-book-appointment-schedule__slots__slot__details__visit__left__dot" />
                            </div>
                            <div className="subject-dashboard-book-appointment-schedule__slots__slot__details__visit__date">
                              <div className="subject-dashboard-book-appointment-schedule__slots__slot__details__visit__date__day">
                                {slot.startDate.format('DD')}
                              </div>
                              {slot.startDate.format('MMM')}
                            </div>
                            <div className="subject-dashboard-book-appointment-schedule__slots__slot__details__visit__info">
                              <DatacTitle size="small">{v.title}</DatacTitle>
                              {slot.startDate.format('dddd')}, {startTime} - {endTime}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </DatacLoading>
      </DatacBox>
      <div className="subject-dashboard-book-appointment-schedule__resign" onClick={goToUnavailable}>
        {intlBook('resign')}
      </div>

      {schedule?.type === ScheduleType.Sequential && selectedSlots?.[0] && (
        <div className="subject-dashboard-book-appointment-schedule__footer">
          <DatacIcon name="arrowRight" onClick={onConfirm} type="white" />
        </div>
      )}
    </div>
  )
}
