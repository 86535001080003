import './ScheduleListSlots.less'

import { Button, Popover } from 'antd'
import classNames from 'classnames'
import dayjs, { Dayjs } from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import React, { useContext, useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../../../hooks'
import {
  CalendarEvent,
  EventPerson,
  ScheduleVisit,
  UserConfigKey,
  addSubjectToEvent,
  fetchCalendarEvents,
  removeVisit
} from '../../../../../../../requests'
import { localeFromPath } from '../../../../../../../utils'
import { UserContext } from '../../../../../../auth'
import { DatacIcon, DatacMessage, DatacTitle } from '../../../../../../common'
import { useRecruitmentStudyDetailsStore } from '../../../../RecruitmentStudyDetailsStore'
import { ScheduleListSlotsBooking } from './ScheduleListSlotsBooking/ScheduleListSlotsBooking'
import { ScheduleListSlotsSettingsPopup } from './ScheduleListSlotsSettingsPopup'

dayjs.extend(isSameOrAfter)
dayjs.updateLocale(localeFromPath(), {})

interface DayOfWeek {
  day: Dayjs
  isToday: boolean
}

interface ScheduleListSlotsProps {
  scheduleId: number
  visit: ScheduleVisit
  selectionDisabled: boolean
  fetchSchedules: () => void
}

export const ScheduleListSlots: React.FC<ScheduleListSlotsProps> = ({
  fetchSchedules,
  scheduleId,
  visit,
  selectionDisabled
}) => {
  const [week, setWeek] = useState<DayOfWeek[]>([])
  const [slots, setSlots] = useState<CalendarEvent[]>([])
  const intlCalendar = useScopedIntl('calendar')
  const intlNext = useScopedIntl('recruitment.study.schedules.next_slot')
  const intlErrors = useScopedIntl('recruitment.study.schedules.slot.errors')
  const intl = useScopedIntl('')
  const [currentDate, setCurrentDate] = useState(dayjs())
  const [isFetchingSlots, setIsFetchingSlots] = useState(true)
  const { study } = useRecruitmentStudyDetailsStore()
  const [nextAvailableDate, setNextAvailableDate] = useState<Dayjs>()
  const [hasAvailableSlotThisWeek, setHasAvailableSlotThisWeek] = useState(false)
  const [isSettingsPopoverVisible, setIsSettingsPopoverVisible] = useState(false)
  const [showWeekends, setShowWeekends] = useState(false)
  const [startsOnSunday, setStartsOnSunday] = useState(false)
  const { user } = useContext(UserContext)

  useEffect(() => {
    if (!user) return
    dayjs.locale(localeFromPath())
    setShowWeekends(user.getConfigValue(UserConfigKey.CalendarShowWeekends, showWeekends))
    setStartsOnSunday(user.getConfigValue(UserConfigKey.CalendarStartsOnSunday, startsOnSunday))
  }, [user])

  useEffect(() => {
    dayjs.updateLocale(localeFromPath(), { weekStart: startsOnSunday ? 0 : 1 })
  }, [startsOnSunday])

  const getNextAvailableDate = () =>
    slots
      .filter(slot => slot.startDate?.isSameOrAfter(currentDate, 'day'))
      ?.sort((a, b) => a.startDate.diff(b.startDate))[0]?.startDate

  useEffect(() => {
    if (isFetchingSlots) return
    setCurrentDate(getNextAvailableDate() || dayjs())
    setHasAvailableSlotThisWeek(slots.some(slot => slot.startDate?.isSame(currentDate, 'week')))
  }, [isFetchingSlots, slots])

  useEffect(() => {
    if (isFetchingSlots) return
    setNextAvailableDate(getNextAvailableDate())
    setHasAvailableSlotThisWeek(slots.some(slot => slot.startDate?.isSame(currentDate, 'week')))
  }, [isFetchingSlots, slots, currentDate])

  const goToPrevious = () => {
    setCurrentDate(d => d.clone().subtract(1, 'week'))
  }

  const goToNext = () => {
    setCurrentDate(d => d.clone().add(1, 'week'))
  }

  const getDaysArray = () => {
    if (!showWeekends) return [1, 2, 3, 4, 5]
    if (startsOnSunday) return [0, 1, 2, 3, 4, 5, 6]
    return [1, 2, 3, 4, 5, 6, 7]
  }

  useEffect(() => {
    const startOfTheWeek = currentDate.clone().startOf('week')

    setWeek(
      getDaysArray().map(day => {
        const dayDate = startOfTheWeek.clone().day(day)
        return {
          day: dayDate,
          isToday: dayDate.isSame(dayjs(), 'day')
        }
      })
    )
  }, [currentDate, showWeekends, startsOnSunday])

  useEffect(() => {
    if (!study?.id || !scheduleId || !visit) {
      setCurrentDate(dayjs())
      return
    }

    setIsFetchingSlots(true)
    fetchCalendarEvents(
      { scheduleId: visit.id },
      {
        onSuccess: ({ events }) => {
          setCurrentDate(dayjs())
          setSlots(events)
          setIsFetchingSlots(false)
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsFetchingSlots(false)
        }
      }
    )
  }, [study, scheduleId, visit])

  const onAddSubject = (subject: EventPerson, slotId: number) => {
    addSubjectToEvent(
      { eventId: slotId, subjectId: subject.datacaptId },
      {
        onSuccess: () => {
          setSlots(
            slots.map(slot => {
              if (slot.id === slotId) {
                return {
                  ...slot,
                  subjects: [...slot.subjects, subject]
                }
              }
              return slot
            })
          )
          fetchSchedules()
        },

        onSubjectAlreadyBooked: () =>
          DatacMessage.error(intlErrors('title'), intlErrors('description.subject_already_booked')),
        onWrongSlotInSequentialBooking: () =>
          DatacMessage.error(intlErrors('title'), intlErrors('description.sequential_wrong_slot')),
        onMissingSlotForSequentialBooking: () =>
          DatacMessage.error(intlErrors('title'), intlErrors('description.sequential_missing_slot')),
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const onRemoveSubject = (visitId: number, slotId: number) => {
    removeVisit(
      { visitId },
      {
        onSuccess: () => {
          setSlots(
            slots.map(slot => {
              if (slot.id === slotId) {
                return {
                  ...slot,
                  subjects: slot.subjects.filter(s => s.visitId !== visitId)
                }
              }
              return slot
            })
          )
          fetchSchedules()
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  return (
    <div className="appointment-schedule-list-slots">
      <div className="appointment-schedule-list-slots__navigation">
        <div className="appointment-schedule-list-slots__navigation__spacer" />
        <button type="button" onClick={() => goToPrevious()}>
          <DatacIcon name="chevronLeft" />
        </button>
        <DatacTitle size="small">{currentDate.format('MMM YYYY')}</DatacTitle>
        <button type="button" onClick={() => goToNext()}>
          <DatacIcon name="chevronRight" />
        </button>
        <div className="appointment-schedule-list-slots__navigation__spacer" />
        <Button
          type="default"
          className="appointment-schedule-list-slots__navigation__button"
          onClick={() => setCurrentDate(dayjs())}
        >
          {intlCalendar('today')}
        </Button>
        <Popover
          trigger="click"
          placement="bottomRight"
          open={isSettingsPopoverVisible}
          onOpenChange={setIsSettingsPopoverVisible}
          content={
            <ScheduleListSlotsSettingsPopup
              startsOnSunday={startsOnSunday}
              setStartsOnSunday={setStartsOnSunday}
              showWeekends={showWeekends}
              setShowWeekends={setShowWeekends}
            />
          }
        >
          <Button type="default" className="appointment-schedule-list-slots__navigation__button">
            <DatacIcon raw name="settings" />
          </Button>
        </Popover>
      </div>
      <div className="appointment-schedule-list-slots__header">
        {week.map((d, index) => (
          <div key={index} className="appointment-schedule-list-slots__header__day">
            <div className="appointment-schedule-list-slots__header__day__label">{d.day.format('dddd')}</div>
            <div className={`appointment-schedule-list-slots__header__day__date ${d.isToday ? 'today' : ''}`}>
              {d.day.format('D')}
            </div>
          </div>
        ))}
      </div>
      <div className="appointment-schedule-list-slots__wrapper">
        {hasAvailableSlotThisWeek ? (
          <div className="appointment-schedule-list-slots__body">
            {week.map((day, dayIndex) => (
              <div key={dayIndex} className="appointment-schedule-list-slots__body__day">
                {slots
                  .filter(slot => slot.startDate?.isSame(day.day, 'day'))
                  ?.map((slot, slotIndex) => {
                    const subjectsCount = slot.subjects?.length || 0
                    return (
                      <ScheduleListSlotsBooking
                        slot={slot}
                        visit={visit}
                        key={slotIndex}
                        onAddSubject={subject => onAddSubject(subject, slot.id)}
                        onRemoveSubject={visitId => onRemoveSubject(visitId, slot.id)}
                        selectionDisabled={selectionDisabled}
                      >
                        <div
                          className={classNames(
                            'appointment-schedule-list-slots__body__day__slot',
                            selectionDisabled && 'disabled',
                            !subjectsCount && 'empty'
                          )}
                        >
                          <div className="appointment-schedule-list-slots__body__day__slot__booked">
                            {subjectsCount === slot.capacity && <DatacIcon name="checkCircle" raw />}
                            {subjectsCount} / {slot.capacity}
                          </div>
                          {slot.startTime}
                        </div>
                      </ScheduleListSlotsBooking>
                    )
                  })}
              </div>
            ))}
          </div>
        ) : (
          nextAvailableDate && (
            <div className="appointment-schedule-list-slots__next-slot">
              {intlNext('description')} {nextAvailableDate?.format('DD MMMM YYYY')}
              <Button type="default" onClick={() => setCurrentDate(nextAvailableDate)}>
                {intlNext('button')}
              </Button>
            </div>
          )
        )}
      </div>
    </div>
  )
}
