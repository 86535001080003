import { createErrorsHandlers, prepareSorter } from '../../utils'
import { BackendError } from '../RequestError'
import { fetchApi } from '../fetchApi'
import { RemoteUserData, UserData, UserType, UsersSorter, parseRemoteUser, usersSorterFields } from '../generalSettings'

interface FetchSideBySideProjectUsersOptions {
  projectId: string
  options?: {
    limit?: number
    offset?: number
    sorter?: UsersSorter
    search?: string
    filters?: Record<string, string[]>
    includePending?: boolean
  }
}

interface FetchSideBySideProjectUsersResponseHandlers {
  onSuccess?: ({
    projectUsers,
    allProjectUsersCount
  }: {
    projectUsers: UserData[]
    allProjectUsersCount: number
  }) => void
  onRequestError?: (code: number) => void
}

interface FetchSideBySideProjectUsersResponse {
  count: number
  results: RemoteUserData[]
}

export const fetchSideBySideProjectUsers = (
  { projectId, options }: FetchSideBySideProjectUsersOptions,
  responseHandlers?: FetchSideBySideProjectUsersResponseHandlers
) => {
  const sorter = prepareSorter<typeof usersSorterFields, UsersSorter>(usersSorterFields, options.sorter, 'name')
  const query = {
    limit: options.limit,
    offset: options.offset,
    ordering: sorter,
    search: options.search,
    role_ids: options.filters?.role,
    center_ids: options.filters?.center
  }

  const { req, cancel } = fetchApi.get<FetchSideBySideProjectUsersResponse>(
    `side_by_side/projects/${projectId}/users${options.includePending ? '/all' : ''}`,
    query
  )

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchSideBySideProjectUsersResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({ projectUsers: body.results.map(parseRemoteUser), allProjectUsersCount: body.count })
    }
  })

  return cancel
}

interface RemoveSideBySideProjectUserOptions {
  projectId: string
  userId: string
  type: UserType
}

interface RemoveSideBySideProjectUserResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
  onCannotRemoveAdmin?: () => void
  onError?: () => void
}

export const removeSideBySideProjectUser = (
  { projectId, userId, type }: RemoveSideBySideProjectUserOptions,
  responseHandlers?: RemoveSideBySideProjectUserResponseHandlers
) => {
  const { req, cancel } = fetchApi.delete(
    `side_by_side/projects/${projectId}/${type === UserType.User ? 'users' : 'invitations'}/${userId}`
  )

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<RemoveSideBySideProjectUserResponseHandlers>(
        {
          [BackendError.STUDY_CANNOT_UPDATE_ADMIN]: 'onCannotRemoveAdmin'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface FetchUsersNotInSideBySideProjectOptions {
  projectId: string
}

interface FetchUsersNotInSideBySideProjectResponseHandlers {
  onSuccess?: (users: UserData[]) => void
  onRequestError?: (code: number) => void
}

export const fetchUsersNotInSideBySideProject = (
  { projectId }: FetchUsersNotInSideBySideProjectOptions,
  responseHandlers?: FetchUsersNotInSideBySideProjectResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RemoteUserData[]>(`side_by_side/projects/${projectId}/users/invite`, {})

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchUsersNotInSideBySideProjectResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.map(parseRemoteUser))
    }
  })

  return cancel
}

interface InviteUsersToSideBySideProjectOptions {
  projectId: string
  userIds: string[]
  centerIds: string[]
  invitationIds: string[]
}

interface InviteUsersToSideBySideProjectResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const inviteUsersToSideBySideProject = (
  { projectId, userIds, centerIds, invitationIds }: InviteUsersToSideBySideProjectOptions,
  responseHandlers?: InviteUsersToSideBySideProjectResponseHandlers
) => {
  const query = {
    user_ids: userIds,
    center_ids: centerIds,
    invitation_ids: invitationIds
  }
  const { req, cancel } = fetchApi.post(`side_by_side/projects/${projectId}/users`, query)

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<InviteUsersToSideBySideProjectResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface EditSideBySideProjectUserOptions {
  projectId: string
  userId: string
  centerIds: string[]
  type: UserType
}

interface EditSideBySideProjectUserResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
  onError?: () => void
}

export const editSideBySideProjectUser = (
  { projectId, userId, centerIds, type }: EditSideBySideProjectUserOptions,
  responseHandlers?: EditSideBySideProjectUserResponseHandlers
) => {
  const query = {
    center_ids: centerIds
  }
  const { req, cancel } = fetchApi.patch(
    `side_by_side/projects/${projectId}/${type === UserType.User ? 'users' : 'invitations'}/${userId}`,
    query
  )

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<EditSideBySideProjectUserResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}
