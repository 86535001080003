/* eslint-disable camelcase */
import countries from 'i18n-iso-countries'

import { createErrorsHandlers, prepareSorter } from '../../utils'
import { localeFromPath } from '../../utils/misc'
import { BackendError } from '../RequestError'
import { SorterOrder } from '../SorterOrder'
import { fetchApi } from '../fetchApi'

// eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
// eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
countries.registerLocale(require('i18n-iso-countries/langs/fr.json'))
// eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
countries.registerLocale(require('i18n-iso-countries/langs/pl.json'))
// eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
countries.registerLocale(require('i18n-iso-countries/langs/pt.json'))
// eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
countries.registerLocale(require('i18n-iso-countries/langs/it.json'))
// eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
countries.registerLocale(require('i18n-iso-countries/langs/de.json'))
// eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
countries.registerLocale(require('i18n-iso-countries/langs/es.json'))
// eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
countries.registerLocale(require('i18n-iso-countries/langs/ja.json'))
// eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
countries.registerLocale(require('i18n-iso-countries/langs/zh.json'))

export const availableCountries = countries.getNames(localeFromPath().toUpperCase(), { select: 'official' })

export interface RemoteCenterData {
  id: number
  name: string
  country: string
  abbreviation: string
  status: CenterStatus
  token: string
  users_count?: number
  code?: string
  target?: number
  has_subjects?: boolean
}

export interface CenterData {
  id: string
  name: string
  country: string
  abbreviation: string
  status: CenterStatus
  token: string
  usersCount?: number
  code?: string
  target?: number
  hasSubjects?: boolean
}

export interface CentersSorter {
  field: keyof CenterData
  order: SorterOrder
}

export const prepareCentersSorter = (sorter?: CentersSorter) => {
  if (!sorter) return ['name']
  const field = sorter.field

  return [`${sorter.order === SorterOrder.Ascend ? '' : '-'}${field}`, field !== 'name' ? 'name' : null].filter(
    item => !!item
  )
}

export const parseRemoteCenterData = (center: RemoteCenterData) => {
  return {
    id: String(center.id),
    name: center.name,
    country: availableCountries[center.country],
    abbreviation: center.abbreviation,
    status: center.status,
    token: center.token,
    usersCount: center.users_count,
    code: center.code,
    target: center.target,
    hasSubjects: center.has_subjects
  }
}

export enum CenterStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED'
}

interface FetchCentersOptions {
  studyId?: string
  assignedToUser?: boolean
  options?: {
    limit?: number
    offset?: number
    sorter?: CentersSorter
    search?: string
    filters?: Record<string, string[]>
  }
}

interface FetchCentersSuccessOptions {
  centers: CenterData[]
  allCentersCount: number
  countries: string[]
}
interface FetchCentersResponseHandlers {
  onSuccess?: (options: FetchCentersSuccessOptions) => void
  onRequestError?: (code: number) => void
}

interface FetchCentersResponse {
  count: number
  countries: string[]
  results: RemoteCenterData[]
}

export const fetchCenters = (
  { studyId, assignedToUser, options }: FetchCentersOptions,
  responseHandlers?: FetchCentersResponseHandlers
) => {
  const sorter = prepareSorter<null, CentersSorter>(null, options.sorter, 'name')
  const query = {
    limit: options.limit,
    offset: options.offset,
    ordering: sorter,
    search: options.search,
    status: options.filters?.status || CenterStatus.Active,
    country: options.filters?.country,
    assigned_to_user: assignedToUser
  }

  const { req, cancel } = fetchApi.get<FetchCentersResponse>('study_centers', query, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchCentersResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        centers: body.results.map(parseRemoteCenterData),
        allCentersCount: body.count,
        countries: body.countries
      })
    }
  })

  return cancel
}

interface ArchiveCenterOptions {
  centerId: string
}

interface ArchiveCenterResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
  onError?: () => void
}

export const archiveCenter = ({ centerId }: ArchiveCenterOptions, responseHandlers?: ArchiveCenterResponseHandlers) => {
  const { req, cancel } = fetchApi.patch(`study_centers/${centerId}`, { status: CenterStatus.Archived })
  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<ArchiveCenterResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface AddCenterOptions {
  name: string
  country: string
  abbreviation: string
}

interface AddCenterResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
  onAbbreviationTaken?: () => void
  onError?: () => void
}

export const addCenter = (
  { name, country, abbreviation }: AddCenterOptions,
  responseHandlers?: AddCenterResponseHandlers
) => {
  const { req, cancel } = fetchApi.post<AddCenterOptions>('study_centers', { name, country, abbreviation })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<AddCenterResponseHandlers>(
        {
          [BackendError.STUDY_CENTER_ABBREVIATION_ALREADY_EXISTS]: 'onAbbreviationTaken'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}
