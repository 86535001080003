import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { Button } from 'antd'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import {
  AccountType,
  CenterData,
  SorterOrder,
  UserData,
  UserType,
  UsersSorter,
  fetchRoles,
  fetchSideBySideProjectUsers,
  fetchUsersNotInSideBySideProject,
  inviteUsersToSideBySideProject,
  removeSideBySideProjectUser,
  resendRegistrationConfirmMessage
} from '../../../../../requests'
import { DatacIcon, DatacMessage, DatacModal, DatacOption } from '../../../../common'
import { UsersTable, rolesDataToOptions } from '../../../../shared/UsersTable'
import { useSideBySideProjectDetailsStore } from '../../SideBySideProjectDetailsStore'
import { ProjectUsersEditModal } from './ProjectUsersEditModal'
import { InviteFormData, ProjectUsersInviteModal } from './ProjectUsersInviteModal'

interface Props extends RouteComponentProps {
  projectId?: string
}

const defaultSorter: UsersSorter = { field: 'name', order: SorterOrder.Ascend }

const pageSize = 25

export const SideBySideProjectSettingsUsers: React.FC<Props> = ({ projectId }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [projectUsers, setProjectUsers] = useState<UserData[]>(null)
  const [allUsers, setAllUsers] = useState<UserData[]>([])
  const [userToEdit, setUserToEdit] = useState<UserData>(null)
  const [allCount, setAllCount] = useState(0)
  const [isFetchingUsers, setIsFetchingUsers] = useState(false)
  const [sorter, setSorter] = useState<UsersSorter>(null)
  const [filters, setFilters] = useState<Record<string, string[]>>()
  const [search, setSearch] = useState<string>(null)
  const [isModalOpened, setIsModalOpened] = useState(false)
  const [userToDelete, setUserToDelete] = useState<UserData>(null)
  const [isRemovingUser, setIsRemovingUser] = useState(false)
  const intlUsers = useScopedIntl('studies.users')
  const intlSettingsUsers = useScopedIntl('settings.users')
  const intl = useScopedIntl('')
  const { project } = useSideBySideProjectDetailsStore()
  const [centersOptions, setCentersOptions] = useState<DatacOption[]>([])
  const [usedRoles, setUsedRoles] = useState<DatacOption[]>([])
  const [currentPageSize, setCurrentPageSize] = useState(pageSize)
  const [ongoingResendUserId, setOngoingResendUserId] = useState<string>(null)

  const fetchAvailableRoles = () => {
    return fetchRoles(
      {},
      {
        onSuccess: ({ roles }) => {
          setUsedRoles(rolesDataToOptions(roles, true))
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  useEffect(() => {
    if (!project) return

    fetchAllUsers()
    fetchAvailableRoles()
    setCentersOptions(
      project.centers.map((c: CenterData) => ({
        value: c.id,
        label: c.abbreviation
      }))
    )
  }, [project])

  useEffect(() => {
    fetchProjectUsersPage(0, sorter, search, filters)
  }, [search, filters, currentPageSize])

  const onPageChange = (page: number) => {
    fetchProjectUsersPage(page - 1, sorter, search, filters)
  }

  const fetchProjectUsersPage = (
    page: number,
    sorter: UsersSorter,
    search: string,
    filters: Record<string, string[]>
  ) => {
    setIsFetchingUsers(true)
    return fetchSideBySideProjectUsers(
      {
        projectId,
        options: {
          limit: currentPageSize,
          offset: page * currentPageSize,
          sorter: sorter || defaultSorter,
          search,
          filters,
          includePending: true
        }
      },
      {
        onSuccess: ({ projectUsers, allProjectUsersCount }) => {
          setProjectUsers(projectUsers)
          setAllCount(allProjectUsersCount)
          setIsFetchingUsers(false)
          setCurrentPage(page + 1)
        },
        onRequestError: code => {
          setIsFetchingUsers(false)
          DatacMessage.genericError(intl, code)
        }
      }
    )
  }

  const onSorterChange = (tableSorter: { field: keyof UserData; order: 'ascend' | 'descend' }) => {
    const sorterChanged =
      (!sorter && tableSorter.order) ||
      (sorter && (tableSorter.field !== sorter?.field || tableSorter.order !== sorter?.order))
    if (sorterChanged) {
      const newSorter = tableSorter.order
        ? {
            field: tableSorter.field,
            order: tableSorter.order === 'ascend' ? SorterOrder.Ascend : SorterOrder.Descend
          }
        : null
      fetchProjectUsersPage(currentPage - 1, newSorter, search, filters)
      setSorter(newSorter)
    }
  }

  const onStudyUserDelete = (studyUser: UserData) => {
    setIsRemovingUser(true)
    removeSideBySideProjectUser(
      { projectId, userId: studyUser.id, type: studyUser.type },
      {
        onSuccess: () => {
          setIsRemovingUser(false)
          setUserToDelete(null)
          refreshUsersList()
        },
        onError: () => setIsRemovingUser(false),
        onRequestError: code => DatacMessage.genericError(intl, code),
        onCannotRemoveAdmin: () =>
          DatacMessage.error(
            intl('studies.users.error.remove_admin.title'),
            intl('studies.users.error.remove_admin.description')
          )
      }
    )
  }

  const fetchAllUsers = () => {
    fetchUsersNotInSideBySideProject(
      { projectId },
      {
        onSuccess: setAllUsers,
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const sendInvitations = (data: InviteFormData) => {
    const parsedData = allUsers.reduce(
      (acc, curr) => {
        if (data.userIds.includes(`${curr.type}_${curr.id}`)) {
          if (curr.type === UserType.Invitation) {
            acc.invitationIds.push(curr.id)
          } else {
            acc.userIds.push(curr.id)
          }
        }
        return acc
      },
      {
        userIds: [] as string[],
        invitationIds: [] as string[],
        centerIds: data.centerIds
      }
    )
    inviteUsersToSideBySideProject(
      { projectId, ...parsedData },
      {
        onSuccess: () => {
          setIsModalOpened(false)
          refreshUsersList()
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const onFiltersChange = (filters: Record<string, string[]>) => {
    setFilters(filters)
  }

  const refreshUsersList = () => {
    fetchAllUsers()
    fetchProjectUsersPage(currentPage - 1, sorter, search, filters)
  }

  const onResend = (user: UserData) => {
    setOngoingResendUserId(user.id)
    resendRegistrationConfirmMessage(
      { email: user.email, accountType: AccountType.User },
      {
        onSuccess: () => {
          setOngoingResendUserId(null)
          DatacMessage.success(
            intlSettingsUsers('resend_success.title'),
            intlSettingsUsers('resend_success.description')
          )
        },
        onRequestError: code => {
          setOngoingResendUserId(null)
          DatacMessage.genericError(intl, code)
        }
      }
    )
  }

  const tableOptions = (
    <div>
      <Button type="default" onClick={() => setIsModalOpened(true)}>
        <DatacIcon type="grey" name="plus" raw /> {intlUsers('invite_button')}
      </Button>
    </div>
  )

  return (
    <div>
      {!!projectUsers && (
        <UsersTable
          onDelete={user => setUserToDelete(user)}
          users={projectUsers.map(user => ({
            ...user,
            centers: project.centers.filter((c: CenterData) => user.centers?.map(uc => uc.id).includes(c.id))
          }))}
          currentPage={currentPage}
          onPageChange={onPageChange}
          totalCount={allCount}
          pageSize={currentPageSize}
          setPageSize={setCurrentPageSize}
          disabled={isFetchingUsers}
          onSorterChange={onSorterChange}
          isUserDeletable={() => true}
          isUserEditable={() => true}
          onEdit={setUserToEdit}
          isModuleTable
          onFiltersChange={onFiltersChange}
          filters={filters}
          onSearchChange={setSearch}
          search={search}
          centers={project?.centers}
          options={tableOptions}
          usedRoles={usedRoles}
          scrollX={600}
          onResend={onResend}
          isResending={user => ongoingResendUserId === user.id}
        />
      )}
      <ProjectUsersInviteModal
        isOpened={isModalOpened}
        onClose={() => setIsModalOpened(false)}
        allUsers={allUsers}
        onSubmit={sendInvitations}
        centersOptions={centersOptions}
      />
      <DatacModal
        title={intlUsers('remove_modal.title')}
        onClose={() => setUserToDelete(null)}
        isOpened={!!userToDelete}
        onSubmit={() => onStudyUserDelete(userToDelete)}
        submitLabel={intlUsers('remove_modal.submit')}
        loading={isRemovingUser}
      >
        {intlUsers('remove_modal.message')}
      </DatacModal>
      <ProjectUsersEditModal
        isOpened={!!userToEdit}
        userToEdit={userToEdit}
        onClose={() => setUserToEdit(null)}
        onUserEdited={refreshUsersList}
        centersOptions={centersOptions}
      />
    </div>
  )
}
