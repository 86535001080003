/* eslint-disable camelcase */
import { createErrorsHandlers } from '../utils'
import { CalendarEvent, RemoteEvent, RemoteEventPerson, RemoteEventStudy, parseRemoteCalendarEvent } from './calendar'
import { fetchApi } from './fetchApi'
import { BookedVisitStatus } from './recruitment'

export interface RemoteParticipantVisit {
  id: number
  event: RemoteEvent & {
    center: { id: number; name: string; abbreviation: string }
    attendees?: RemoteEventPerson[]
    project?: RemoteEventStudy
    study?: RemoteEventStudy
    recruitment_study?: RemoteEventStudy
  }
  status: BookedVisitStatus
  cause: string
}

export interface ParticipantVisit {
  id: number
  event: CalendarEvent
  status: BookedVisitStatus
  cause: string
}

export const parseRemoteParticipantVisit = (remote: RemoteParticipantVisit): ParticipantVisit => ({
  id: remote.id,
  event: parseRemoteCalendarEvent(remote.event),
  status: remote.status,
  cause: remote.cause
})

interface ParticipantVisitsSearchOptions {
  search?: string
  filters?: {
    status?: string[]
    date_after?: string
    date_before?: string
  }
}

const parseParticipantVisitsSearchOptions = (options: ParticipantVisitsSearchOptions) => ({
  search: options?.search?.toLowerCase(),
  status: options?.filters?.status?.length ? options.filters.status.join(',') : undefined,
  end_after: options?.filters?.date_after,
  start_before: options?.filters?.date_before ? `${options?.filters?.date_before}T23:59:59` : undefined
})

interface FetchParticipantVisitsOptions {
  options: ParticipantVisitsSearchOptions & {
    limit: number
    offset: number
    subjectPk?: number
  }
}

export type ParticipantVisitStatusCount = Record<BookedVisitStatus, number>

interface FetchParticipantVisitsResponseHandlers {
  onSuccess?: ({ visits, countAll }: { visits: ParticipantVisit[]; countAll: number }) => void
  onRequestError?: (code: number) => void
}

interface FetchParticipantVisitsResponse {
  results: RemoteParticipantVisit[]
  count: number
}

export const fetchParticipantVisits = (
  { options }: FetchParticipantVisitsOptions,
  responseHandlers?: FetchParticipantVisitsResponseHandlers
) => {
  const query = {
    limit: options?.limit,
    offset: options?.offset,
    subject: options?.subjectPk,
    ...parseParticipantVisitsSearchOptions(options),
    expand: 'event,event.attendees,event.center,event.project,event.study,event.recruitment_study'
  }
  const { req, cancel } = fetchApi.get<FetchParticipantVisitsResponse>(`visits`, query)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchParticipantVisitsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        visits: body.results.map(visit => parseRemoteParticipantVisit(visit)),
        countAll: body.count
      })
    }
  })

  return cancel
}
