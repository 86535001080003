import './StudyUsersInviteModal.less'

import { Form } from 'antd'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../../hooks'
import { UserData } from '../../../../../../requests'
import { validateMinItems } from '../../../../../../validation'
import { DatacFormItem, DatacModal, DatacOption, DatacSelect, scopedIntlType } from '../../../../../common'

export interface InviteFormData {
  userIds: string[]
  centerIds: string[]
}

const getBadges = (user: UserData, intl: scopedIntlType) => (
  <>
    {user.pending && user.name === '-' ? (
      <div className="study-users-invite-modal__option-badge">{intl('status.pending')}</div>
    ) : null}
    {user.pending && user.name !== '-' ? (
      <div className="study-users-invite-modal__option-badge study-users-invite-modal__option-badge--unconfirmed">
        {intl('users_table.unconfirmed')}
      </div>
    ) : null}
    {user.locked ? (
      <div className="study-users-invite-modal__option-badge study-users-invite-modal__option-badge--locked">
        {intl('users_table.user_locked')}
      </div>
    ) : null}
  </>
)

interface Props {
  isOpened: boolean
  onClose: () => void
  allUsers: UserData[]
  onSubmit: (data: InviteFormData) => void
  centersOptions: DatacOption[]
}

export const StudyUsersInviteModal: React.FC<Props> = ({ isOpened, onClose, allUsers, onSubmit, centersOptions }) => {
  const [usersOptions, setUsersOptions] = useState([])
  const [formInstance] = Form.useForm()
  const [formKey, setFormKey] = useState(0)
  const intl = useScopedIntl('')
  const intlModal = useScopedIntl('studies.users.invite_modal')

  useEffect(() => {
    if (allUsers) {
      setUsersOptions(
        allUsers.map(user => ({
          value: `${user.type}_${user.id}`,
          label: user.name === '-' ? '' : user.name,
          sublabel: user.email,
          suffix: getBadges(user, intl)
        }))
      )
    }
  }, [allUsers])

  const remountForm = () => setFormKey(formKey === 1 ? 0 : 1)

  const onAfterClose = () => {
    remountForm()
    formInstance.resetFields()
  }

  return (
    <DatacModal
      isOpened={isOpened}
      onClose={onClose}
      title={intlModal('title')}
      onSubmit={() => formInstance.submit()}
      afterClose={onAfterClose}
    >
      <div className="study-users-invite-modal">
        <div className="study-users-invite-modal__instructions">{intlModal('instructions')}</div>
        <Form form={formInstance} onFinish={onSubmit} key={formKey}>
          <DatacFormItem
            label={intlModal('users.label')}
            name="userIds"
            validate={validateMinItems(intlModal('users.validation'), 1)}
          >
            <DatacSelect
              showSearch
              mode="multiple"
              placeholder={intlModal('users.placeholder')}
              options={usersOptions}
              renderOption={option => (
                <div className="datac-select__option datac-select__option study-users-invite-modal__option">
                  <span>
                    {option.label ? <span className="datac-select__option-label">{option.label}</span> : null}
                    <span className="datac-select__option-sublabel">{option.sublabel}</span>
                    <span className="datac-select__option-suffix">{option.suffix}</span>
                  </span>
                </div>
              )}
            />
          </DatacFormItem>
          <DatacFormItem
            label={intlModal('centers.label')}
            name="centerIds"
            validate={validateMinItems(intlModal('centers.validation'), 1)}
          >
            <DatacSelect
              showSearch
              mode="multiple"
              placeholder={intlModal('centers.placeholder')}
              options={centersOptions}
            />
          </DatacFormItem>
        </Form>
      </div>
    </DatacModal>
  )
}
