import './AddTestModal.less'

import { Button } from 'antd'
import { navigate } from 'gatsby-plugin-react-intl'
import { debounce } from 'lodash'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import {
  Project,
  ProjectSorter,
  ProjectStatus,
  SorterOrder,
  fetchProjects,
  linkTestToVisit
} from '../../../../requests'
import { routes } from '../../../../routes'
import { TableRecord, createTableRecords } from '../../../../utils'
import { DatacIcon, DatacMessage, DatacModal, DatacTable, DatacTableSearchAndFilters } from '../../../common'
import { getListColumns, searchAndFilterOptions } from './ProjectsTableConfig'

const projectListLimit = 50

const ActionCell: React.FC<{
  project: Project
  selectedProjectId: string
  setSelectedProjectId: (id: string) => void
}> = ({ project, selectedProjectId, setSelectedProjectId }) => {
  const intlAdd = useScopedIntl('calendar.bookings.add_test')
  const isSelected = selectedProjectId === project.id
  return (
    <div className="booking-add-test-modal__body__action">
      <Button type={isSelected ? 'text' : 'default'} onClick={() => setSelectedProjectId(project.id)}>
        {isSelected && <DatacIcon name="check" raw />}
        {intlAdd(isSelected ? 'selected' : 'select')}
      </Button>
    </div>
  )
}

interface AddTestModalProps {
  onClose: (redirect: boolean) => void
  isOpened: boolean
  subjectId: string
  visitId: string
}

export const AddTestModal: React.FC<AddTestModalProps> = ({ visitId, subjectId, isOpened, onClose }) => {
  const intl = useScopedIntl('')
  const intlAdd = useScopedIntl('calendar.bookings.add_test')
  const intlCard = useScopedIntl('side_by_side.card')
  const [projects, setProjects] = useState<TableRecord<Project>[]>()
  const [isFetchingProjects, setIsFetchingProjects] = useState(true)
  const [sorter, setSorter] = useState({
    field: 'name' as keyof Project,
    order: SorterOrder.Ascend
  })
  const [search, setSearch] = useState('')
  const [selectedProjectId, setSelectedProjectId] = useState<string>(null)

  useEffect(() => {
    if (!isOpened) return

    setSearch('')
    fetchProjectsList('', sorter)
    setSelectedProjectId(null)
  }, [isOpened])

  const onSubmit = () => {
    linkTestToVisit(
      { projectId: selectedProjectId, visitId },
      {
        onSuccess: testIds => {
          const lastTestId = testIds?.[testIds?.length - 1]
          navigate(routes.sideBySideProjectTest(selectedProjectId, lastTestId, null, null, true))
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const fetchProjectsList = (search: string, sorter: ProjectSorter) => {
    fetchProjects(
      {
        options: {
          limit: projectListLimit,
          offset: 0,
          search,
          status: [ProjectStatus.Live],
          subjectId,
          sorter
        }
      },
      {
        onSuccess: newProjects => {
          setProjects(createTableRecords<Project>(newProjects))
          setIsFetchingProjects(false)
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsFetchingProjects(false)
        }
      }
    )
  }

  const onSearchChange = debounce((newPhrase: string) => {
    setSearch(newPhrase)
    fetchProjectsList(newPhrase, sorter)
  }, 1000)

  const columns = getListColumns({
    columnNames: {
      name: intlCard('name'),
      brand: intlCard('brand'),
      category: intlCard('category')
    },
    actionCell: project => (
      <ActionCell project={project} selectedProjectId={selectedProjectId} setSelectedProjectId={setSelectedProjectId} />
    )
  })

  const onSorterChange = (tableSorter: ProjectSorter) => {
    const sorterChanged =
      Object.keys(tableSorter).length &&
      ((!sorter && tableSorter.order) || tableSorter.field !== sorter?.field || tableSorter.order !== sorter?.order)

    if (sorterChanged) {
      const newSorter = tableSorter.order
        ? {
            field: tableSorter.field,
            order: tableSorter.order === 'ascend' ? SorterOrder.Ascend : SorterOrder.Descend
          }
        : null
      fetchProjectsList(search, newSorter)
      setSorter(newSorter)
    }
  }

  return (
    <DatacModal
      title={intlAdd('title')}
      isOpened={isOpened}
      onClose={() => onClose(false)}
      onSubmit={onSubmit}
      noSubmit={!selectedProjectId}
      submitLabel={intl('common.next')}
      destroyOnClose
    >
      <div className="booking-add-test-modal__body">
        <div className="booking-add-test-modal__body__info">
          <DatacIcon name="linkChain" type="blue" />
          <div>
            <div className="booking-add-test-modal__body__info__title">{intlAdd('info.title')}</div>
            <div className="booking-add-test-modal__body__info__description">{intlAdd('info.description')}</div>
          </div>
        </div>
        <DatacTableSearchAndFilters
          onSearchChange={onSearchChange}
          isSearching={isFetchingProjects}
          searchAndFilterOptions={searchAndFilterOptions(intl)}
        />
        <DatacTable
          dataSource={projects}
          loading={isFetchingProjects}
          columns={columns}
          pagination={false}
          rowSelection={null}
          onChange={(_, __, sorter) => onSorterChange(sorter as ProjectSorter)}
        />
      </div>
    </DatacModal>
  )
}
