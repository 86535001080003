/* eslint-disable camelcase */
import { createErrorsHandlers } from '../../utils'
import { BackendError } from '../RequestError'
import { fetchApi } from '../fetchApi'
import { RemoteSchedule, Schedule, parseRemoteSchedule } from './schedules'

interface FetchScheduleForSubjectResponseHandlers {
  onSuccess?: (schedule: Schedule) => void
  onSubjectDidNotApplied?: () => void
  onSubjectRejected?: () => void
  onSubjectAlreadyBooked?: () => void
  onScheduleNotPublished?: () => void
  onRequestError?: (code: number) => void
}

export const fetchScheduleForSubject = (
  { recruitmentId, scheduleId }: { recruitmentId: string; scheduleId?: string },
  responseHandlers?: FetchScheduleForSubjectResponseHandlers
) => {
  const url = `subject_accounts/recruitment/${recruitmentId}/schedules/${scheduleId || 'primary'}`
  const { req, cancel } = fetchApi.get<RemoteSchedule>(url)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchScheduleForSubjectResponseHandlers>(
        {
          [BackendError.CALENDAR_APPLICANT_WRONG_STATUS]: 'onSubjectRejected',
          [BackendError.CALENDAR_SLOT_SUBJECT_ALREADY_BOOKED]: 'onSubjectAlreadyBooked',
          [BackendError.RECRUITMENT_RECORD_NOT_EXISTS]: 'onSubjectDidNotApplied',
          [BackendError.CALENDAR_SCHEDULE_NOT_PUBLISHED]: 'onScheduleNotPublished'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteSchedule(body))
    }
  })

  return cancel
}

interface BookVisitsResponseHandlers {
  onSuccess?: () => void
  onAlreadyTaken?: () => void
  onRequestError?: (code: number) => void
}

interface BookVisitsOptions {
  scheduleId: number
  eventIds: number[]
  isOnlyBlocking: boolean
}

export const bookVisits = (
  { scheduleId, eventIds, isOnlyBlocking }: BookVisitsOptions,
  responseHandlers?: BookVisitsResponseHandlers
) => {
  const query = {
    event_ids: eventIds,
    is_only_blocking: isOnlyBlocking
  }

  const { req, cancel } = fetchApi.post(`subject_accounts/schedules/${scheduleId}`, query)

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<BookVisitsResponseHandlers>(
        {
          [BackendError.CALENDAR_SLOT_CAPACITY_EXCEEDED]: 'onAlreadyTaken'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface RemoveBlockedBookingsOptions {
  scheduleId: number
}

export const removeBlockedBookings = (
  { scheduleId }: RemoveBlockedBookingsOptions,
  responseHandlers?: BookVisitsResponseHandlers
) => {
  const { req, cancel } = fetchApi.delete(`subject_accounts/schedules/${scheduleId}`)

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<BookVisitsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface UpdateParticipantNotAvailable {
  scheduleId: number
}

export const updateParticipantNonAvailable = (
  { scheduleId }: UpdateParticipantNotAvailable,
  responseHandlers?: BookVisitsResponseHandlers
) => {
  const { req, cancel } = fetchApi.patch(`subject_accounts/schedules/${scheduleId}/not_available`, {})

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<BookVisitsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}
