import classNames from 'classnames'
import dayjs from 'dayjs'
import { navigate } from 'gatsby-plugin-react-intl'
import React, { useContext, useState } from 'react'

import { useScopedIntl } from '../../../hooks'
import { AclFeature, CalendarVisit, EventStudyType } from '../../../requests'
import { routes } from '../../../routes'
import { UserContext } from '../../auth'
import { DatacAvatar, DatacIcon } from '../../common'
import { AddTestModal } from './AddTestModal'

interface Props {
  booking: CalendarVisit
}

export const BookingRecord: React.VFC<Props> = ({ booking }: Props) => {
  const [isAddTestModalOpened, setIsAddTestModalOpened] = useState(false)
  const { user } = useContext(UserContext)
  const intlEvent = useScopedIntl('calendar.event')

  const areTestsAvailable = user.isSideBySideEnabled && user.canAccess(AclFeature.SideBySide)

  const goToStudy = () => {
    const study = booking.event.study
    switch (study.type) {
      case EventStudyType.recruitment:
        navigate(routes.recruitmentStudy(study.uuid))
        break
      case EventStudyType.edc:
        navigate(routes.studyDashboard(study.uuid))
        break
      case EventStudyType.project:
        navigate(routes.sideBySideProject(study.uuid))
        break
    }
  }

  return (
    <div
      className={classNames({
        'bookings-record': true,
        'bookings-record--past': booking.event.endDate.isBefore(dayjs())
      })}
    >
      <div className="bookings-record__hours">
        {booking.event.isFullDay ? (
          intlEvent('all_day')
        ) : (
          <>
            {booking.event.startDate.format('HH:mm')} <small>{booking.event.endDate.format('HH:mm')}</small>
          </>
        )}
      </div>
      <div
        className={classNames('bookings-record__color-line', `calendar-content__background--${booking.event.color}`)}
      />
      <div className="bookings-record__appointment">
        <div className="bookings-record__appointment__details">
          <strong>{booking.event.title}</strong>
          {booking.event.study?.uuid && (
            <small className="bookings-record__appointment__link" onClick={goToStudy}>
              {booking.event.study.reference || booking.event.study.name}
            </small>
          )}
          {booking.tests?.slice(0, 2).map(test => (
            <small
              key={test.projectId}
              className="bookings-record__appointment__link"
              onClick={() => navigate(routes.sideBySideProjectTest(test.projectId, test.testId, null, null, true))}
            >
              <DatacIcon name="file" raw />
              {test.projectName}
            </small>
          ))}
        </div>
      </div>
      <div className="bookings-record__subject">
        <div className="bookings-record__subject__thumbnail">
          <DatacAvatar photoThumbnail={booking.subject.photoThumbnail} fullName={booking.subject.fullName} />
        </div>
        <div className="bookings-record__subject__details">
          <div
            className="bookings-record__subject__details__link"
            onClick={() => navigate(routes.subjectRepositoryProfile(booking.subject.datacaptId))}
          >
            <strong>{booking.subject.fullName}</strong>
            <small> {booking.subject.datacaptId}</small>
          </div>
        </div>
      </div>
      <div className="bookings-record__actions">
        {areTestsAvailable && <DatacIcon name="linkChain" onClick={() => setIsAddTestModalOpened(true)} />}
      </div>
      {areTestsAvailable && (
        <AddTestModal
          isOpened={isAddTestModalOpened}
          onClose={() => setIsAddTestModalOpened(false)}
          subjectId={booking.subject.datacaptId}
          visitId={booking.id}
        />
      )}
    </div>
  )
}
