import './SubjectRepositoryDashboard.less'

import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { Button } from 'antd'
import { navigate } from 'gatsby-plugin-react-intl'
import { debounce } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'

import { useScopedIntl } from '../../../hooks'
import {
  AccountStatus,
  AclAction,
  AclFeature,
  Feature,
  QuestionType,
  SorterOrder,
  SubjectInRepository,
  SubjectInRepositorySorter,
  SubjectStatus,
  TableColumnVariableSource,
  TableVariable,
  fetchCenters,
  fetchSubjectRepositoryQuestionsDependencies,
  fetchSubjectRepositoryStats,
  fetchSubjectRepositoryVariables,
  fetchSubjectsInRepository,
  updateSubjectRepositoryVariables
} from '../../../requests'
import { routes } from '../../../routes'
import { LocalStorageKey, TableRecord } from '../../../utils'
import { UserContext } from '../../auth'
import {
  AdvancedFiltersToConditions,
  DatacAdvancedFilters,
  DatacIcon,
  DatacMessage,
  DatacTable,
  DatacTableColumnPicker,
  DatacTableSearchAndFilters,
  DatacTitle
} from '../../common'
import { SubjectProfileQuickView } from '../SubjectRepositoryProfile'
import { AccountModal } from './AccountModal'
import { AddSubjectModal } from './AddSubjectModal'
import { AssignToRecruitmentModal } from './AssignToRecruitmentModal'
import { ExportModal } from './ExportModal'
import { ImportModal } from './ImportModal'
import { MessageModal } from './MessageModal'
import { RestartModal } from './RestartModal'
import { useSubjectRepositoryDashboardStore } from './SubjectRepositoryDashboardStore'
import { SubjectRepositoryOptions } from './SubjectRepositoryOptions'
import { SubjectRepositoryStats } from './SubjectRepositoryStats'
import { getColumns } from './SubjectRepositoryTableConfig'

const pageSize = 50
const defaultSorter: SubjectInRepositorySorter = { field: 'creationDate', order: SorterOrder.Descend }

export const SubjectRepositoryDashboard: React.FC<RouteComponentProps> = () => {
  const intl = useScopedIntl('')
  const intlSubjectRepository = useScopedIntl('subject_repository')
  const intlRepositoryTable = useScopedIntl('subject_repository.table')
  const intlStatus = useScopedIntl('status')
  const { user } = useContext(UserContext)
  const userCan = user.canDo(AclFeature.SubjectRepository)
  const {
    setIsNewSubjectModalOpened,
    setIsImportModalOpened,
    isFetchingSubjects,
    setIsFetchingSubjects,
    setIsFetchingVariables,
    isSearching,
    selectedSubjects,
    setSelectedSubjects,
    setStats,
    variables,
    allVariables,
    setAllVariables,
    subjects,
    onSubjectsPageSuccess,
    allSubjectsCount,
    subjectsWithEmailCount,
    subjectsWithPhoneCount,
    activeSubjectsWithEmailCount,
    activeSubjectsWithPhoneCount,
    subjectsWithoutAccountCount,
    setSubjectToView,
    setCentersOptions,
    sorter,
    setSorter,
    currentPage,
    search,
    setSearch,
    filters,
    setFilters,
    status,
    setStatus,
    setIsEverythingSelected,
    isEverythingSelected,
    getParsedStatusFilter
  } = useSubjectRepositoryDashboardStore()
  const [isOpened, setIsOpened] = useState(false)
  const [currentPageSize, setCurrentPageSize] = useState(pageSize)

  useEffect(() => {
    localStorage.setItem(LocalStorageKey.LastFeature, Feature.SubjectRepository)
    updateCentersList()
    updateAllVariablesList()
    setSubjectToView(null)
  }, [])

  useEffect(() => {
    // currentPage will be saved in store, and we should use it here only when we open the page
    const shouldUseCurrentPage = !isOpened && currentPage > 0
    fetchSubjectsPage(shouldUseCurrentPage ? currentPage - 1 : 0, sorter)
    setIsOpened(true)
    updateStats()
  }, [search, filters, status, currentPageSize])

  const updateAllVariablesList = () => {
    setIsFetchingVariables(true)
    fetchSubjectRepositoryVariables({
      onSuccess: variables => {
        setAllVariables(variables)
        fetchSubjectsPage(currentPage - 1, sorter)
      },
      onRequestError: code => {
        DatacMessage.genericError(intl, code)
        setIsFetchingVariables(false)
      }
    })
  }

  const updateCentersList = () => {
    fetchCenters(
      { assignedToUser: true, options: { sorter: { field: 'abbreviation', order: SorterOrder.Ascend } } },
      {
        onSuccess: ({ centers }) => {
          setCentersOptions(
            centers.map(center => ({
              value: center.id,
              label: center.abbreviation,
              sublabel: center.name
            }))
          )
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const updateStats = () => {
    fetchSubjectRepositoryStats(
      { search, conditions: AdvancedFiltersToConditions(filters), status: getParsedStatusFilter() },
      {
        onSuccess: setStats,
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const refreshSubjects = () => {
    fetchSubjectsPage(currentPage - 1, sorter)
    updateStats()
  }

  const onNewSubjectModalClose = () => {
    setIsNewSubjectModalOpened(false)
    refreshSubjects()
  }

  const onProfileQuickViewClose = () => {
    setSubjectToView(null)
    refreshSubjects()
  }

  const fetchSubjectsPage = (page: number, sorter: SubjectInRepositorySorter) => {
    setIsFetchingSubjects(true)
    return fetchSubjectsInRepository(
      {
        search,
        conditions: AdvancedFiltersToConditions(filters),
        status: getParsedStatusFilter(),
        limit: currentPageSize,
        offset: page * currentPageSize,
        sorter: sorter || defaultSorter
      },
      {
        onSuccess: ({
          subjects,
          allSubjectsCount,
          subjectsWithPhoneCount,
          subjectsWithEmailCount,
          activeSubjectsWithPhoneCount,
          activeSubjectsWithEmailCount,
          subjectsWithoutAccountCount,
          variables
        }) => {
          onSubjectsPageSuccess({
            subjects: subjects.map(createRecord),
            allSubjectsCount,
            subjectsWithPhoneCount,
            subjectsWithEmailCount,
            activeSubjectsWithPhoneCount,
            activeSubjectsWithEmailCount,
            subjectsWithoutAccountCount,
            variables: variables || {},
            currentPage: page + 1
          })
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsFetchingSubjects(false)
        }
      }
    )
  }

  const createRecord = (subject: SubjectInRepository) => ({ ...subject, ...subject.variableAnswers, key: subject.id })

  const onPageChange = (page: number) => {
    fetchSubjectsPage(page - 1, sorter)
  }

  const onSorterChange = (tableSorter: { field: keyof SubjectInRepository; order: SorterOrder }) => {
    const sorterChanged =
      (!sorter && tableSorter.order) || tableSorter.field !== sorter?.field || tableSorter.order !== sorter?.order
    if (sorterChanged) {
      const newSorter = tableSorter.order
        ? {
            field: tableSorter.field,
            order: tableSorter.order === 'ascend' ? SorterOrder.Ascend : SorterOrder.Descend
          }
        : null
      fetchSubjectsPage(currentPage - 1, newSorter)
      setSorter(newSorter)
    }
  }

  const columns = getColumns({
    columnNames: {
      id: intlRepositoryTable('field.id'),
      fullName: intlRepositoryTable('field.full_name'),
      phone: intlRepositoryTable('field.phone'),
      language: intlRepositoryTable('field.language'),
      status: intlRepositoryTable('field.status'),
      actions: ''
    },
    sortable: true,
    variables
  })

  const onRowConfig = (subject: SubjectInRepository) => ({
    onClick: (event: React.MouseEvent<HTMLElement>) => {
      const target = event.target as HTMLElement
      if (target.closest('div')?.classList.value.includes('datac-icon')) return

      setSubjectToView(subject)
    }
  })

  const onSearchChange = debounce((newPhrase: string) => {
    setSearch(newPhrase)
  }, 500)

  const getSubjectsCountForRestart = () => {
    if (!isEverythingSelected && selectedSubjects?.length) {
      return subjects.filter((s: TableRecord<SubjectInRepository>) => selectedSubjects.includes(s.id)).length
    }

    return allSubjectsCount
  }

  const getSubjectsCountForMessage = (isUsingSms: boolean, shouldBeActive: boolean) => {
    if (!isEverythingSelected && selectedSubjects?.length) {
      return subjects.filter(
        (s: TableRecord<SubjectInRepository>) =>
          selectedSubjects.includes(s.id) &&
          (s.status === SubjectStatus.Active || !shouldBeActive) &&
          ((s.email && !isUsingSms) || (s.internationalPhoneNumber && isUsingSms))
      ).length
    }

    if (shouldBeActive) return isUsingSms ? activeSubjectsWithPhoneCount : activeSubjectsWithEmailCount

    return isUsingSms ? subjectsWithPhoneCount : subjectsWithEmailCount
  }

  const getSubjectsWithoutAccountCount = () => {
    if (!isEverythingSelected && selectedSubjects?.length) {
      return subjects.filter(
        (s: TableRecord<SubjectInRepository>) =>
          selectedSubjects.includes(s.id) && s.accountStatus === AccountStatus.NoAccount
      ).length
    }

    return subjectsWithoutAccountCount
  }

  const statusOptions = [
    { value: SubjectStatus.New, label: intlStatus('new') },
    { value: SubjectStatus.Active, label: intlStatus('active') },
    { value: SubjectStatus.Inactive, label: intlStatus('inactive') },
    { value: SubjectStatus.Pending, label: intlStatus('pending') },
    { value: SubjectStatus.Paused, label: intlStatus('paused') },
    { value: SubjectStatus.DeletionRequested, label: intlStatus('deletion_requested') }
  ]

  return (
    <div className="subject-repository-dashboard">
      <div className="subject-repository-dashboard__header">
        <DatacTitle type="h1">{intlSubjectRepository('title')}</DatacTitle>

        <div className="subject-repository-dashboard__header__actions">
          {(user.canAccess(AclFeature.SubjectRepositoryBuilder) || userCan(AclAction.BasicSettings)) && (
            <Button
              className="subject-repository-dashboard__header__button"
              type="default"
              size="large"
              onClick={() => navigate(routes.subjectRepositorySettings)}
            >
              <DatacIcon
                className="subject-repository-dashboard__header__icon"
                type="blue"
                name="settings"
                size="big"
              />
              {intlSubjectRepository('settings.title')}
            </Button>
          )}
          {userCan(AclAction.Add) && (
            <Button
              className="subject-repository-dashboard__header__button"
              type="default"
              size="large"
              onClick={() => setIsNewSubjectModalOpened(true)}
            >
              <DatacIcon className="subject-repository-dashboard__header__icon" type="blue" name="plus" size="big" />
              {intlSubjectRepository('add_subject')}
            </Button>
          )}
        </div>
      </div>

      <div className="subject-repository-dashboard__stats">
        <SubjectRepositoryStats />
      </div>

      <div className="subject-repository-dashboard__table">
        <DatacTableSearchAndFilters
          onSearchChange={value => onSearchChange(value)}
          isSearching={isSearching}
          searchAndFilterOptions={{ searchInputPlaceholder: intlSubjectRepository('search.placeholder') }}
          initialSearchValue={search}
          options={
            <>
              {userCan(AclAction.Import) && (
                <Button type="default" onClick={() => setIsImportModalOpened(true)}>
                  <DatacIcon raw name="uploadCloud" type="grey" />
                  {intl('common.import')}
                </Button>
              )}
              <DatacTableColumnPicker
                allColumns={{ [TableColumnVariableSource.SubjectDatabase]: allVariables }}
                columns={Object.keys(variables).map(variable => {
                  const title = variables[variable]
                  return {
                    source: TableColumnVariableSource.SubjectDatabase,
                    label: variable,
                    value: variable,
                    sublabel: title
                  }
                })}
                updateColumns={columns => {
                  updateSubjectRepositoryVariables(
                    { variables: columns.map(column => column.value) },
                    {
                      onSuccess: () => {
                        fetchSubjectsPage(currentPage - 1, sorter)
                      },
                      onRequestError: code => DatacMessage.genericError(intl, code)
                    }
                  )
                }}
                isLoading={false}
                isPopover
              />
            </>
          }
        />
        <DatacAdvancedFilters
          fetchQuestionsDependencies={fetchSubjectRepositoryQuestionsDependencies}
          filters={filters}
          setFilters={setFilters}
          mainFilters={[
            {
              label: intlRepositoryTable('field.status'),
              type: QuestionType.Dropdown,
              name: 'status',
              icon: 'status',
              setValue: setStatus,
              selection: status,
              options: statusOptions
            }
          ]}
        />
        <DatacTable
          dataSource={subjects}
          selectedRows={selectedSubjects}
          setSelectedRows={setSelectedSubjects}
          setIsEverythingSelected={setIsEverythingSelected}
          columns={columns}
          scroll={{ x: 900, y: window.innerHeight - 200 }}
          onRow={onRowConfig}
          pagination={{
            current: currentPage,
            onChange: onPageChange,
            total: allSubjectsCount,
            disabled: isFetchingSubjects,
            pageSize: currentPageSize
          }}
          setPageSize={setCurrentPageSize}
          onChange={(_, __, sorter) =>
            onSorterChange(sorter as { field: keyof SubjectInRepository; order: SorterOrder })
          }
        />
      </div>
      {userCan(AclAction.Add) && <AddSubjectModal onClose={onNewSubjectModalClose} />}
      <SubjectProfileQuickView onClose={onProfileQuickViewClose} />
      {userCan(AclAction.Export) && <ExportModal />}
      {user.canDo(AclFeature.SubjectRepository)(AclAction.Add) && (
        <RestartModal subjectsToRestartCount={getSubjectsCountForRestart()} />
      )}
      {user.canDo(AclFeature.SubjectRepository)(AclAction.Add) && (
        <AccountModal subjectsWithoutAccountCount={getSubjectsWithoutAccountCount()} onSuccess={refreshSubjects} />
      )}
      {user.canDo(AclFeature.SubjectRepositoryCampaign)(AclAction.Add) && (
        <MessageModal
          subjectsWithPhoneCount={getSubjectsCountForMessage(true, false)}
          subjectsWithEmailCount={getSubjectsCountForMessage(false, false)}
          variables={allVariables
            .filter((v: TableVariable) => v.type !== QuestionType.File)
            .map((v: TableVariable) => v.variable)}
        />
      )}
      {userCan(AclAction.Import) && <ImportModal />}
      {user.canDo(AclFeature.RecruitmentRecords)(AclAction.Invite) && user.canAccess(AclFeature.Recruitment) && (
        <AssignToRecruitmentModal subjectsCount={allSubjectsCount} />
      )}

      <SubjectRepositoryOptions />
    </div>
  )
}
