import './CalendarReschedule.less'

import { DatePicker, Form, Switch, TimePicker } from 'antd'
import { Dayjs } from 'dayjs'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { CalendarEvent, EventPerson, rescheduleVisit } from '../../../../requests'
import { validateRequired } from '../../../../validation'
import { DatacBox, DatacFormItem, DatacIcon, DatacMessage, DatacModal, DatacTitle } from '../../../common'
import { useCalendarStore } from '../../CalendarStore'

interface CalendarRescheduleProps {
  onClose: (reload: boolean) => void
  participant: EventPerson
  event: CalendarEvent
  visitId: number
}

interface FormData {
  date: Dayjs
  time: Dayjs[]
  freeSlot: boolean
}

export const CalendarReschedule: React.VFC<CalendarRescheduleProps> = ({ onClose, participant, event, visitId }) => {
  const intlEvent = useScopedIntl('calendar.event')
  const intlReschedule = useScopedIntl('calendar.event.reschedule')
  const intl = useScopedIntl('')
  const [formInstance] = Form.useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [currentStart, setCurrentStart] = useState<Dayjs>(null)
  const [duration, setDuration] = useState<number>(null)
  const { userTimezone } = useCalendarStore()

  useEffect(() => {
    setIsModalVisible(!!participant)
    formInstance.setFieldsValue({ date: event?.startDate, time: [event?.startDate, event?.endDate] })
    setCurrentStart(event?.startDate)
    setDuration(event?.endDate.diff(event.startDate, 'minutes'))
  }, [participant, event])

  const onSubmit = ({ date, time, freeSlot }: FormData) => {
    rescheduleVisit(
      {
        date,
        startTime: time[0].format('HH:mm'),
        endTime: time[1].format('HH:mm'),
        visitId,
        freeSlot,
        userTimezone
      },
      {
        onSuccess: () => onClose(true),
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const onChange = (value: Dayjs[]) => {
    if (value[0].isSame(currentStart, 'minutes')) return

    const newEndValue = value[0].clone().add(duration, 'minutes')
    formInstance.setFieldsValue({ time: [value[0], value[0].isSame(newEndValue, 'day') ? newEndValue : null] })
    setCurrentStart(value[0])
  }

  return (
    <DatacModal
      className="calendar-reschedule__modal"
      isOpened={isModalVisible}
      onClose={() => onClose(false)}
      onSubmit={() => formInstance.submit()}
      submitLabel={intlReschedule('button')}
      title={intlReschedule('title')}
      destroyOnClose
    >
      {!!participant && (
        <Form className="calendar-reschedule" form={formInstance} name="reschedule" onFinish={onSubmit}>
          <DatacBox hasBorder={false}>
            <DatacIcon raw name="user" />
            <div>
              <div className="calendar-reschedule__participant">
                {participant.fullName && <DatacTitle size="small">{participant.fullName}</DatacTitle>}
                {participant.fullName && <span>|</span>}
                <div>{participant.datacaptId}</div>
              </div>
              {event.title}
            </div>
          </DatacBox>
          <div className="calendar-reschedule__info">{intlReschedule('info')}</div>
          <DatacFormItem
            name="date"
            validate={validateRequired(intl('common.required'))}
            isCalendarStyle
            label={intlEvent('date.placeholder')}
          >
            <DatePicker size="large" />
          </DatacFormItem>
          <DatacFormItem
            name="time"
            validate={validateRequired(intl('common.required'))}
            isCalendarStyle
            label={intlEvent('title.placeholder')}
          >
            <TimePicker.RangePicker onCalendarChange={onChange} size="large" format="HH:mm" />
          </DatacFormItem>
          <div className="calendar-reschedule__free-slot">
            <DatacFormItem name="freeSlot">
              <Switch />
            </DatacFormItem>
            <div className="calendar-reschedule__free-slot__label">{intlReschedule('free_slot')}</div>
          </div>
        </Form>
      )}
    </DatacModal>
  )
}
