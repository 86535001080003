import './BookingsContent.less'

import { Input } from 'antd'
import classNames from 'classnames'
import { debounce } from 'lodash'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../hooks'
import { BookedVisitStatus, CalendarVisit, Feature, SorterOrder, fetchVisits } from '../../../requests'
import { LocalStorageKey } from '../../../utils'
import { DatacIcon, DatacLoading, DatacMessage, DatacOption, DatacPagination } from '../../common'
import { CalendarLayout } from '../CalendarLayout'
import { CalendarFilterKey } from '../CalendarLayout/CalendarLayoutSidebar'
import { useCalendarStore } from '../CalendarStore'
import { BookingRecord } from './BookingRecord'

const pageSize = 50

export const BookingsContent: React.VFC = () => {
  const intl = useScopedIntl('')
  const intlBookings = useScopedIntl('calendar.bookings')
  const {
    setCurrentView,
    isSidebarVisible,
    currentView,
    currentDate,
    setCurrentDate,
    setIsSidebarVisible,
    filters,
    bookingsCount,
    setBookingsCount,
    userTimezone
  } = useCalendarStore()
  const [isRequestDatesValid, setIsRequestDatesValid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [bookings, setBookings] = useState<CalendarVisit[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState<string>()
  const [isShowingSearch, setIsShowingSearch] = useState(false)
  const [currentPageSize, setCurrentPageSize] = useState(pageSize)

  useEffect(() => {
    setCurrentView('bookings')
  }, [])

  useEffect(() => {
    fetchBookings(0)
  }, [currentDate, search, filters, currentPageSize, isRequestDatesValid])

  useEffect(() => {
    localStorage.setItem(LocalStorageKey.LastFeature, Feature.Bookings)
    setCurrentDate(currentDate, currentView === 'day' ? undefined : true)
    setIsRequestDatesValid(true)
  }, [])

  const fetchBookings = (page: number) => {
    if (!isRequestDatesValid) return
    setIsLoading(true)
    fetchVisits(
      {
        options: {
          startDate: currentDate.startOf('day'),
          endDate: currentDate.endOf('day'),
          limit: currentPageSize,
          offset: page * currentPageSize,
          search,
          location: filters?.[CalendarFilterKey.Centers]?.map((c: DatacOption) => c.label),
          studyId: filters?.[CalendarFilterKey.EcrfStudies]?.map((c: DatacOption) => c.value),
          recruitmentStudyId: filters?.[CalendarFilterKey.EdcStudies]?.map((c: DatacOption) => c.value),
          projectId: filters?.[CalendarFilterKey.Projects]?.map((c: DatacOption) => c.value),
          status: [BookedVisitStatus.NotDone, BookedVisitStatus.Completed, BookedVisitStatus.Scheduled],
          sorter: {
            field: 'date',
            order: SorterOrder.Ascend
          }
        }
      },
      {
        onSuccess: ({ bookedVisits, countAll }) => {
          setIsLoading(false)
          setBookings(
            bookedVisits?.map(b => ({
              ...b,
              event: {
                ...b.event,
                startDate: b.event.startDate.tz(userTimezone),
                endDate: b.event.endDate.tz(userTimezone)
              }
            }))
          )
          setBookingsCount(countAll)
          setCurrentPage(page + 1)
        },
        onRequestError: code => {
          setIsLoading(false)
          DatacMessage.genericError(intl, code)
        }
      }
    )
  }

  const onSearchChange = debounce((value: string) => {
    setSearch(value)
  }, 1000)

  const onSearchClose = () => {
    setSearch(null)
    setIsShowingSearch(false)
  }

  const isFilteringActive = !!Object.values(filters).filter(a => !!(a as string[]).length).length

  const onPageChange = (page: number) => {
    fetchBookings(page - 1)
  }

  const paginationConfig = {}

  return (
    <CalendarLayout path="bookings">
      <DatacLoading isLoading={isLoading}>
        <div className="bookings">
          {bookings.map(booking => (
            <BookingRecord booking={booking} key={booking.id} />
          ))}
          {paginationConfig && (
            <DatacPagination
              current={currentPage}
              onChange={onPageChange}
              total={bookingsCount}
              pageSize={currentPageSize}
              setPageSize={setCurrentPageSize}
              isDetached
            />
          )}
        </div>
      </DatacLoading>
      <div
        className={classNames('bookings__toolbox', {
          'sidebar-visible': isSidebarVisible
        })}
      >
        <div className={classNames('bookings__toolbox__inner', { 'search-visible': isShowingSearch })}>
          {isShowingSearch ? (
            <>
              <DatacIcon name="arrowLeft" raw onClick={onSearchClose} />
              <Input
                className="bookings__toolbox__inner__input"
                placeholder={intlBookings('search.placeholder')}
                onChange={e => onSearchChange(e.currentTarget.value)}
              />

              <DatacIcon name="search" className="bookings__toolbox__inner__search" />
            </>
          ) : (
            <>
              <DatacIcon name="search" raw onClick={() => setIsShowingSearch(true)} />
              <div className="bookings__toolbox__inner__filter">
                {!!isFilteringActive && <div className="bookings__toolbox__inner__filter__badge"> </div>}
                <DatacIcon name="filters" raw onClick={() => setIsSidebarVisible(!isSidebarVisible)} />
              </div>
            </>
          )}
        </div>
      </div>
    </CalendarLayout>
  )
}
