/* eslint-disable camelcase */
import { createErrorsHandlers } from '../../utils'
import { fetchApi } from '../fetchApi'
import {
  BaseRemoteQuestionAnswer,
  FulfillmentQuestionAnswer,
  QuestionType,
  deleteAnswer,
  parseRemoteQuestionAnswer,
  saveAnswer
} from '../forms'
import {
  RecordStatus,
  RemoteSectionAdvancement,
  SectionAdvancement,
  parseRemoteSectionAdvancement
} from '../sectionAdvancement'

interface SubjectRepositoryAnswerFields {
  subjectId: string
  reason?: string
  nullAnswerType?: QuestionType
}

export const subjectRepositoryAnswerMapping = {
  subjectId: 'datacapt_id',
  reason: 'reason'
}

export const getSubjectRepositoryAnswerPath = (data: SubjectRepositoryAnswerFields) =>
  `subject_repository/answer/${data.subjectId}`

export interface SubjectRepositoryRemoteQuestionAnswer extends BaseRemoteQuestionAnswer {
  datacapt_id: number
}

export interface SubjectRepositoryQuestionAnswer extends FulfillmentQuestionAnswer {
  subjectId: string
}

export const parseSubjectRepositoryRemoteQuestionAnswer = (answer: SubjectRepositoryRemoteQuestionAnswer) => ({
  ...parseRemoteQuestionAnswer(answer),
  subjectId: String(answer.datacapt_id),
  blockId: String(answer.question)
})

export const saveSubjectRepositoryAnswer = saveAnswer<
  SubjectRepositoryAnswerFields,
  SubjectRepositoryRemoteQuestionAnswer,
  SubjectRepositoryQuestionAnswer
>(getSubjectRepositoryAnswerPath, subjectRepositoryAnswerMapping, parseSubjectRepositoryRemoteQuestionAnswer)

export const deleteSubjectRepositoryAnswer = deleteAnswer<SubjectRepositoryAnswerFields>(
  getSubjectRepositoryAnswerPath,
  subjectRepositoryAnswerMapping
)

interface RemoteSubjectRepositoryRecord {
  id: number
  first_name: string
  last_name: string
  status: RecordStatus
  sections?: RemoteSectionAdvancement[]
}

export interface SubjectRepositoryRecord {
  id: string
  randomisationId?: string
  name: string
  progress: number
  status: RecordStatus
  sectionsAdvancement?: { [sectionId: string]: SectionAdvancement }
}

const parseRemoteSubjectRepositoryRecord = (remoteRecord: RemoteSubjectRepositoryRecord) => ({
  id: String(remoteRecord.id),
  name: `${remoteRecord.first_name} ${remoteRecord.last_name}`.trim(),
  status: remoteRecord.status,
  progress: 0,
  sectionsAdvancement: Object.entries(remoteRecord.sections || {}).reduce(
    (acc, [sectionId, remoteSection]) => ({
      ...acc,
      [sectionId]: parseRemoteSectionAdvancement(remoteSection)
    }),
    {}
  )
})

interface FetchSubjectRepositoryRecordOptions {
  subjectId: string
}

interface FetchSubjectRepositoryRecordResponseHandlers {
  onSuccess?: (record: SubjectRepositoryRecord) => void
  onNotFound?: () => void
  onRequestError?: (code: number) => void
}

export const fetchSubjectRepositoryRecord = (
  { subjectId }: FetchSubjectRepositoryRecordOptions,
  responseHandlers?: FetchSubjectRepositoryRecordResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RemoteSubjectRepositoryRecord>(`subject_repository/subjects/${subjectId}`)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchSubjectRepositoryRecordResponseHandlers>(
        {
          404: 'onNotFound'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteSubjectRepositoryRecord(body))
    }
  })

  return cancel
}
