import './CalendarViewDay.less'

import classNames from 'classnames'
import dayjs, { Dayjs } from 'dayjs'
import React, { useEffect, useRef, useState } from 'react'

import { CalendarEvent, CalendarEventColor } from '../../../../requests'
import { EventFullDayComponent, EventWeekComponent } from '../../CalendarContent/EventComponents'
import { useCalendarStore } from '../../CalendarStore'
import {
  CalendarHourOnGrid,
  CalendarViewDayCurrentTime,
  getDayColumnOffset,
  getDayColumnWidth,
  getPositionToTime,
  getTimeTopPosition
} from './CalendarViewDayCurrentTime'

interface EventBox {
  id: number
  top: number
  bottom: number
  column: number
  group?: number
}

interface SelectionTime {
  start: Dayjs
  end: Dayjs
}

interface Props {
  events: CalendarEvent[]
  onClosePopup: () => void
  dayToShow?: Dayjs
  showTimeColumn?: boolean
  shouldShowFullDay?: boolean
}

export const CalendarViewDay: React.VFC<Props> = ({
  events,
  onClosePopup,
  dayToShow,
  showTimeColumn,
  shouldShowFullDay = true
}) => {
  const { currentDate, currentView, setEventToEdit, userTimezone } = useCalendarStore()
  const [currentEvents, setCurrentEvents] = useState<CalendarEvent[]>([])
  const [currentAllDayEvents, setCurrentAllDayEvents] = useState<CalendarEvent[]>([])
  const eventsRefs = useRef<HTMLDivElement[]>([])
  const [selectionTime, setSelectionTime] = useState<SelectionTime>({ start: null, end: null })
  const selectionRef = useRef<HTMLDivElement>(null)
  const sevenRef = useRef<HTMLDivElement>(null)
  const dayRef = useRef<HTMLButtonElement>(null)
  const [isSelectingTimeBox, setIsSelectingTimeBox] = useState(false)

  useEffect(() => {
    if (!sevenRef.current) return

    sevenRef.current.scrollIntoView({ block: 'start' })
  }, [sevenRef.current])

  useEffect(() => {
    const dayString = (dayToShow || currentDate).format('YYYYMMDD')
    const currentEvents = events.filter(a => a.startFilterString <= dayString && a.endFilterString >= dayString)
    setCurrentEvents(currentEvents.filter(a => !a.isFullDay && dayString === a.startFilterString))
    setCurrentAllDayEvents(shouldShowFullDay ? currentEvents.filter(a => a.isFullDay) : [])
  }, [events])

  const checkIfColliding = (a: Partial<EventBox>, b: Partial<EventBox>) =>
    (a.top < b.bottom && a.top >= b.top) || (a.bottom <= b.bottom && a.bottom > b.top)

  const getMaxColumn = (boxes: EventBox[]) => boxes.reduce((max, obj) => Math.max(max, obj.column), 0)

  const calculateEventBoxes = () => {
    if (!currentEvents.length) return

    const boxes: EventBox[] = []

    currentEvents.forEach(event => {
      const top = getTimeTopPosition(event.startInUserTimezone)
      const bottom = getTimeTopPosition(event.endInUserTimezone)
      const maxColumn = getMaxColumn(boxes)

      let currentBoxColumn = maxColumn + 1

      for (let column = 1; column <= maxColumn; column += 1) {
        if (!boxes.find(b => b.column === column && checkIfColliding({ top, bottom }, b))) {
          currentBoxColumn = column
          break
        }
      }
      boxes.push({ id: event.id, top, bottom, column: currentBoxColumn })
    })

    const updatedBoxes = boxes.reduce((updatedBoxes, box, index) => {
      const groups: Set<number> = new Set()
      const collidingIds: number[] = []
      updatedBoxes
        .filter(updatedBox => checkIfColliding(box, updatedBox))
        .forEach(b => {
          if (b.group) groups.add(b.group)
          collidingIds.push(b.id)
        })
      return updatedBoxes.map(b => ({
        ...b,
        group: b.id === box.id || collidingIds.includes(b.id) || groups.has(b.group) ? index + 1 : b.group
      }))
    }, boxes)

    const dayColumnWidth = getDayColumnWidth()
    const dayColumnOffset = showTimeColumn ? getDayColumnOffset() : 0

    updatedBoxes.forEach(box => {
      const currentEvent = eventsRefs.current[box.id]
      const currentEventWrapper = currentEvent.querySelector('.calendar-components__wrapper') as HTMLDivElement
      const groupMaxColumn = getMaxColumn(updatedBoxes.filter(b => b.group === box.group))
      const weekBoxOffset = dayColumnWidth / groupMaxColumn / 1.5
      const width =
        currentView === 'week' ? dayColumnWidth - weekBoxOffset * (groupMaxColumn - 1) : dayColumnWidth / groupMaxColumn
      const boxOffset = currentView === 'week' ? weekBoxOffset : width
      currentEvent.style.top = `${box.top}px`
      currentEvent.style.height = `${box.bottom - box.top}px`
      currentEvent.style.width = `${width}px`
      currentEvent.style.left = `${boxOffset * (box.column - 1) + dayColumnOffset}px`
      currentEventWrapper.style.zIndex = String(box.column)
    })
  }

  useEffect(() => {
    if (!currentEvents || !dayRef.current) return null

    const resizeObserver = new ResizeObserver(() => calculateEventBoxes())
    resizeObserver.observe(dayRef.current)
    calculateEventBoxes()

    return () => resizeObserver.disconnect()
  }, [currentEvents, dayRef.current])

  const setDivRef = (element: HTMLDivElement, id: number) => {
    if (!element) return
    eventsRefs.current[id] = element
  }

  const placeSelectionDiv = ({ start, end }: SelectionTime) => {
    setSelectionTime({ start, end })
    const startPx = getTimeTopPosition(start)
    const endPx = getTimeTopPosition(end)
    const top = Math.min(startPx, endPx)
    const height = Math.abs(startPx - endPx)
    selectionRef.current.style.display = 'block'
    selectionRef.current.style.top = `${top}px`
    selectionRef.current.style.height = `${height}px`
  }

  const getClickTime = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.currentTarget as HTMLElement
    const divRect = target.getBoundingClientRect()

    const position = e.clientY - divRect.top
    return getPositionToTime(position, dayToShow || currentDate, true)
  }

  const onMouseClick = (e: React.MouseEvent<HTMLElement>, up: boolean) => {
    setIsSelectingTimeBox(!up)

    if (!up) {
      const clickTime = getClickTime(e)
      placeSelectionDiv({ start: clickTime, end: clickTime })
      return
    }

    selectionRef.current.style.display = 'none'

    const selectionDuration = selectionTime.end?.diff(selectionTime.start, 'minute')
    if (!selectionDuration || selectionDuration < 10) return

    const startDate = selectionTime.start.isBefore(selectionTime.end) ? selectionTime.start : selectionTime.end
    const endDate = selectionTime.start.isBefore(selectionTime.end) ? selectionTime.end : selectionTime.start

    setEventToEdit({
      startDate,
      endDate,
      startTime: dayjs(startDate).format('HH:mm'),
      endTime: dayjs(endDate).format('HH:mm'),
      timezone: userTimezone,
      publicTitle: null,
      title: null,
      capacity: null,
      subjects: [],
      color: CalendarEventColor.Blue2,
      study: null,
      center: null,
      organizer: null,
      isFullDay: false,
      attendees: [],
      schedule: null,
      isAvailable: true,
      startFilterString: '',
      endFilterString: ''
    })
  }

  const onMouseMove = (e: React.MouseEvent<HTMLElement>) => {
    if (!isSelectingTimeBox) return

    placeSelectionDiv({ start: selectionTime.start, end: getClickTime(e) })
  }

  return (
    <div className={classNames('calendar-view-day', { 'should-show-full-day': shouldShowFullDay })}>
      {!!currentAllDayEvents?.length && (
        <div className="calendar-view-day__full-day">
          {currentAllDayEvents.map(event => (
            <EventFullDayComponent key={event.id} event={event} onClosePopup={onClosePopup} />
          ))}
        </div>
      )}
      <div className={classNames('calendar-view-day__body', { 'should-show-full-day': shouldShowFullDay })}>
        <button
          type="button"
          className="raw calendar-view-day__hours"
          onMouseMove={onMouseMove}
          onMouseDown={e => onMouseClick(e, false)}
          onMouseUp={e => onMouseClick(e, true)}
          ref={dayRef}
        >
          <CalendarViewDayCurrentTime showTime={showTimeColumn} />
          {Array.from({ length: 24 }).map((_, i) => (
            <div key={i} className="calendar-view-day__row" ref={i === 7 ? sevenRef : undefined}>
              {showTimeColumn && (
                <div className="calendar-view-day__row__label">
                  <CalendarHourOnGrid hour={i} isToday={dayjs().isSame(currentDate, 'day')} />
                </div>
              )}
              <div className="calendar-view-day__row__cell" />
            </div>
          ))}
          {currentEvents.map(event => (
            <div key={event.id} className="calendar-view-day__event" ref={el => setDivRef(el, event.id)}>
              <EventWeekComponent event={event} onClosePopup={onClosePopup} />
            </div>
          ))}
          <div ref={selectionRef} className="calendar-view-day__selection" />
        </button>
      </div>
    </div>
  )
}
