import './VisitWeek.less'

import { Button } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import React, { useEffect, useState } from 'react'

import { getTimeAsNumber } from '..'
import { useScopedIntl } from '../../../../../../../hooks'
import { CalendarEvent, ScheduleVisitAvailability } from '../../../../../../../requests'
import { localeFromPath } from '../../../../../../../utils'

dayjs.updateLocale(localeFromPath(), { weekStart: 1 })

interface DayOfWeek {
  day: Dayjs
  isToday: boolean
}

interface AppointmentScheduleWeekProps {
  date: Dayjs
  setDate: (date: Date) => void
  participantsCount: number
  duration: number
  interval: number
  capacity: number
  availability: ScheduleVisitAvailability[]
  onUpdateSlotCounts: (created: number, needed: number) => void
}

export const AppointmentScheduleWeek: React.FC<AppointmentScheduleWeekProps> = ({
  date,
  setDate,
  participantsCount,
  duration,
  interval,
  capacity,
  availability,
  onUpdateSlotCounts
}) => {
  const intlNext = useScopedIntl('recruitment.study.schedules.next_slot')
  const [week, setWeek] = useState<DayOfWeek[]>([])
  const [slots, setSlots] = useState<Partial<CalendarEvent>[]>([])
  const [neededSlots, setNeededSlots] = useState<number>(0)
  const [nextAvailableDate, setNextAvailableDate] = useState<Dayjs>()
  const [hasAvailableSlotThisWeek, setHasAvailableSlotThisWeek] = useState(false)

  useEffect(() => {
    const nextAvailableDate = slots
      .filter(slot => slot.startDate?.isSameOrAfter(date, 'day'))
      ?.sort((a, b) => a.startDate.diff(b.startDate))[0]?.startDate
    setNextAvailableDate(nextAvailableDate)
    setHasAvailableSlotThisWeek(slots.some(slot => slot.startDate?.isSame(date, 'week')))
  }, [slots, date])

  useEffect(() => {
    setNeededSlots(Math.ceil(participantsCount / (capacity || 1)))
  }, [participantsCount, capacity])

  useEffect(() => {
    const startOfTheWeek = date.clone().startOf('week')
    setWeek(
      [1, 2, 3, 4, 5, 6, 7].map(day => {
        const dayDate = startOfTheWeek.clone().day(day)
        return {
          day: dayDate,
          isToday: dayDate.isSame(dayjs(), 'day')
        }
      })
    )
  }, [date])

  useEffect(() => {
    onUpdateSlotCounts(slots.length, neededSlots)
  }, [slots, neededSlots])

  useEffect(() => {
    if (!duration || !availability?.length) {
      setSlots([])
      return
    }

    const slots: Partial<CalendarEvent>[] = []
    availability.forEach(a => {
      let slotStart = getTimeAsNumber(a.startTime)
      while (slotStart + duration <= getTimeAsNumber(a.endTime)) {
        slots.push({
          startDate: a.date,
          startTime: dayjs().startOf('day').add(slotStart, 'minutes').format('HH:mm')
        })
        slotStart += interval
      }
    })

    setSlots(slots.slice(0, neededSlots))
  }, [availability, duration, interval, neededSlots])

  return (
    <div className="appointment-schedule-week">
      <div className="appointment-schedule-week__header">
        {week.map((d, index) => (
          <div key={index} className="appointment-schedule-week__header__day">
            <div className="appointment-schedule-week__header__day__label">{d.day.format('dddd')}</div>
            <div className={`appointment-schedule-week__header__day__date ${d.isToday ? 'today' : ''}`}>
              {d.day.format('D')}
            </div>
          </div>
        ))}
      </div>
      <div className="appointment-schedule-week__wrapper">
        {hasAvailableSlotThisWeek ? (
          <div className="appointment-schedule-week__body">
            {week.map((day, dayIndex) => (
              <div key={dayIndex} className="appointment-schedule-week__body__day">
                {slots
                  .filter(slot => slot.startDate?.isSame(day.day, 'day'))
                  ?.map((slot, slotIndex) => (
                    <div key={slotIndex} className="appointment-schedule-week__body__day__slot">
                      {slot.startTime}
                      {capacity > 1 && (
                        <div className="appointment-schedule-week__body__day__slot__capacity">{capacity}</div>
                      )}
                    </div>
                  ))}
              </div>
            ))}
          </div>
        ) : (
          nextAvailableDate && (
            <div className="appointment-schedule-week__wrapper__next-slot">
              {intlNext('description')} {nextAvailableDate?.format('DD MMMM YYYY')}
              <Button type="default" onClick={() => setDate(nextAvailableDate.toDate())}>
                {intlNext('button')}
              </Button>
            </div>
          )
        )}
      </div>
    </div>
  )
}
