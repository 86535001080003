import './EventEditStudy.less'

import { FormInstance } from 'antd/lib/form'
import { debounce } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import {
  AclAction,
  AclFeature,
  EventStudyType,
  fetchProject,
  fetchProjects,
  fetchRecruitmentStudies,
  fetchRecruitmentStudy,
  fetchStudies,
  fetchStudy
} from '../../../../../requests'
import { UserContext } from '../../../../auth'
import { DatacFormItem, DatacLoading, DatacMessage, DatacOption, DatacSelect } from '../../../../common'
import { useCalendarStore } from '../../../CalendarStore'

const studiesListLimit = 3

interface EventEditStudyProps {
  studyId: string
  setStudyId: (studyId: string) => void
  studyType: EventStudyType
  setStudyType: (studyType: EventStudyType) => void
  formInstance: FormInstance
}

export const EventEditStudy: React.VFC<EventEditStudyProps> = ({
  setStudyId,
  studyId,
  studyType,
  setStudyType,
  formInstance
}) => {
  const intlStudy = useScopedIntl('calendar.event.study')
  const intl = useScopedIntl('')
  const [recruitmentOptions, setRecruitmentOptions] = useState<DatacOption[]>([])
  const [edcOptions, setEdcOptions] = useState<DatacOption[]>([])
  const [projectOptions, setProjectOptions] = useState<DatacOption[]>([])
  const [options, setOptions] = useState<DatacOption[]>([])
  const { eventToEdit } = useCalendarStore()
  const [isSearchingRecruitment, setIsSearchingRecruitment] = useState(true)
  const [isSearchingEdc, setIsSearchingEdc] = useState(true)
  const [isSearchingProjects, setIsSearchingProjects] = useState(true)
  const { user } = useContext(UserContext)

  useEffect(() => {
    onSearchStudies()

    if (!eventToEdit?.study?.uuid) return

    switch (studyType) {
      case EventStudyType.edc:
        fetchStudy(
          { studyId: eventToEdit.study?.uuid },
          {
            onSuccess: study => {
              setEdcOptions(edcOptions => [
                ...edcOptions.filter(s => s.value !== study.id),
                {
                  label: study.reference,
                  sublabel: study.name,
                  value: `${EventStudyType.edc}.${study.id}`
                }
              ])
            }
          }
        )
        break
      case EventStudyType.recruitment:
        user.isRecruitmentEnabled &&
          fetchRecruitmentStudy(
            { studyId: eventToEdit.study?.uuid },
            {
              onSuccess: study => {
                setRecruitmentOptions(recruitmentOptions => [
                  ...recruitmentOptions.filter(s => s.value !== study.id),
                  {
                    label: study.reference,
                    sublabel: study.name,
                    value: `${EventStudyType.recruitment}.${study.id}`
                  }
                ])
              }
            }
          )
        break
      default:
        user.isSideBySideEnabled &&
          fetchProject(
            { projectId: eventToEdit.study?.uuid },
            {
              onSuccess: study => {
                setProjectOptions(projectOptions => [
                  ...projectOptions.filter(s => s.value !== study.id),
                  {
                    label: study.name,
                    sublabel: study.name,
                    value: `${EventStudyType.project}.${study.id}`
                  }
                ])
              }
            }
          )
        break
    }
  }, [eventToEdit])

  const onSearchStudies = debounce((searchPhrase?: string) => {
    const options = {
      limit: studiesListLimit,
      offset: 0,
      search: searchPhrase
    }
    setIsSearchingRecruitment(true)
    setIsSearchingEdc(true)
    setIsSearchingProjects(true)

    user.isRecruitmentEnabled && user.canAccess(AclFeature.Recruitment)
      ? fetchRecruitmentStudies(
          { options },
          {
            onSuccess: newStudies => {
              setRecruitmentOptions(recruitmentOptions => [
                ...recruitmentOptions.filter(s => s.value === `${studyType}.${studyId}`),
                ...newStudies
                  .filter(s => s.id !== studyId)
                  .map(s => ({ label: s.reference, sublabel: s.name, value: `${EventStudyType.recruitment}.${s.id}` }))
              ])
              setIsSearchingRecruitment(false)
            },
            onRequestError: code => DatacMessage.genericError(intl, code)
          }
        )
      : setIsSearchingRecruitment(false)

    user.canDo(AclFeature.StudyList)(AclAction.AccessAll)
      ? fetchStudies(
          { options },
          {
            onSuccess: newStudies => {
              setEdcOptions(edcOptions => [
                ...edcOptions.filter(s => s.value === `${studyType}.${studyId}`),
                ...newStudies
                  .filter(s => s.id !== studyId)
                  .map(s => ({ label: s.reference, sublabel: s.name, value: `${EventStudyType.edc}.${s.id}` }))
              ])
              setIsSearchingEdc(false)
            },
            onRequestError: code => DatacMessage.genericError(intl, code)
          }
        )
      : setIsSearchingEdc(false)

    user.isSideBySideEnabled && user.canAccess(AclFeature.SideBySide)
      ? fetchProjects(
          { options },
          {
            onSuccess: newStudies => {
              setProjectOptions(projectOptions => [
                ...projectOptions.filter(s => s.value === `${studyType}.${studyId}`),
                ...newStudies
                  .filter(s => s.id !== studyId)
                  .map(s => ({ label: s.name, value: `${EventStudyType.project}.${s.id}` }))
              ])
              setIsSearchingProjects(false)
            },
            onRequestError: code => DatacMessage.genericError(intl, code)
          }
        )
      : setIsSearchingProjects(false)
  }, 1000)

  const onStudySelect = (value: string) => {
    const selected = value?.split('.')
    setStudyType(selected?.[0] as EventStudyType)
    setStudyId(selected?.[1])
  }

  useEffect(() => {
    setOptions([
      ...(recruitmentOptions.length ? [{ label: intlStudy('recruitment'), disabled: true, value: 'recruitment' }] : []),
      ...recruitmentOptions,
      ...(edcOptions.length ? [{ label: intlStudy('edc'), disabled: true, value: 'edc' }] : []),
      ...edcOptions,
      ...(projectOptions.length ? [{ label: intlStudy('projects'), disabled: true, value: 'projects' }] : []),
      ...projectOptions
    ])
  }, [recruitmentOptions, edcOptions, projectOptions])

  const isSearching = isSearchingProjects || isSearchingEdc || isSearchingRecruitment

  useEffect(() => {
    formInstance.setFieldValue('studyId', isSearching || !studyId || !studyType ? null : `${studyType}.${studyId}`)
  }, [isSearching, studyId, studyType])

  return (
    <DatacFormItem name="studyId" icon="fileText" isCalendarStyle label={intlStudy('label')} validate={[]}>
      <DatacSelect
        size="large"
        options={isSearching ? [{ value: null, label: <DatacLoading isLoading size="small" /> }] : options}
        placeholder={intlStudy('placeholder')}
        onSearch={onSearchStudies}
        doNotFilterResults
        showSearch
        onChange={onStudySelect}
        allowBlank={!isSearching}
        dropDownClassName="calendar-event-edit-study__dropdown"
        disabled={!!eventToEdit?.schedule}
      />
    </DatacFormItem>
  )
}
