import { createErrorsHandlers, prepareSorter } from '../../utils'
import { BackendError } from '../RequestError'
import { fetchApi } from '../fetchApi'
import { RemoteUserData, UserData, UserType, UsersSorter, parseRemoteUser, usersSorterFields } from '../generalSettings'

interface FetchRecruitmentStudyUsersOptions {
  studyId: string
  isFetchingAllUsersInStudyCenters?: boolean
  options?: {
    limit?: number
    offset?: number
    sorter?: UsersSorter
    search?: string
    filters?: Record<string, string[]>
    includePending?: boolean
  }
}

interface FetchRecruitmentStudyUsersResponseHandlers {
  onSuccess?: ({ studyUsers, allStudyUsersCount }: { studyUsers: UserData[]; allStudyUsersCount: number }) => void
  onRequestError?: (code: number) => void
}

interface FetchRecruitmentStudyUsersResponse {
  count: number
  results: RemoteUserData[]
}

export const fetchRecruitmentStudyUsers = (
  { studyId, options, isFetchingAllUsersInStudyCenters }: FetchRecruitmentStudyUsersOptions,
  responseHandlers?: FetchRecruitmentStudyUsersResponseHandlers
) => {
  const sorter = prepareSorter<typeof usersSorterFields, UsersSorter>(usersSorterFields, options.sorter, 'name')
  const query = {
    limit: options.limit,
    offset: options.offset,
    ordering: sorter,
    search: options.search,
    role: options.filters?.role,
    study_center_id: options.filters?.center
  }

  const { req, cancel } = fetchApi.get<FetchRecruitmentStudyUsersResponse>(
    `recruitment/studies/${studyId}/${isFetchingAllUsersInStudyCenters ? 'centers-users' : 'users'}${
      options.includePending ? '/all' : ''
    }`,
    query
  )

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchRecruitmentStudyUsersResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({ studyUsers: body.results.map(parseRemoteUser), allStudyUsersCount: body.count })
    }
  })

  return cancel
}

interface RemoveRecruitmentStudyUserOptions {
  studyId: string
  userId: string
  type: UserType
}

interface RemoveRecruitmentStudyUserResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
  onCannotRemoveAdmin?: () => void
  onError?: () => void
}

export const removeRecruitmentStudyUser = (
  { studyId, userId, type }: RemoveRecruitmentStudyUserOptions,
  responseHandlers?: RemoveRecruitmentStudyUserResponseHandlers
) => {
  const { req, cancel } = fetchApi.delete(
    `recruitment/studies/${studyId}/${type === UserType.User ? 'users' : 'invitations'}/${userId}`
  )

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<RemoveRecruitmentStudyUserResponseHandlers>(
        {
          [BackendError.STUDY_CANNOT_UPDATE_ADMIN]: 'onCannotRemoveAdmin'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface FetchUsersNotInRecruitmentStudyOptions {
  studyId: string
}

interface FetchUsersNotInRecruitmentStudyResponseHandlers {
  onSuccess?: (users: UserData[]) => void
  onRequestError?: (code: number) => void
}

export const fetchUsersNotInRecruitmentStudy = (
  { studyId }: FetchUsersNotInRecruitmentStudyOptions,
  responseHandlers?: FetchUsersNotInRecruitmentStudyResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RemoteUserData[]>(`recruitment/studies/${studyId}/users/invite`, {})

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchUsersNotInRecruitmentStudyResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.map(parseRemoteUser))
    }
  })

  return cancel
}

interface InviteUsersToRecruitmentStudyOptions {
  studyId: string
  userIds: string[]
  centerIds: string[]
  invitationIds: string[]
}

interface InviteUsersToRecruitmentStudyResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const inviteUsersToRecruitmentStudy = (
  { studyId, userIds, centerIds, invitationIds }: InviteUsersToRecruitmentStudyOptions,
  responseHandlers?: InviteUsersToRecruitmentStudyResponseHandlers
) => {
  const query = {
    user_ids: userIds,
    center_ids: centerIds,
    invitation_ids: invitationIds
  }
  const { req, cancel } = fetchApi.post(`recruitment/studies/${studyId}/users`, query)

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<InviteUsersToRecruitmentStudyResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface EditRecruitmentStudyUserOptions {
  studyId: string
  userId: string
  centerIds: string[]
  type: UserType
}

interface EditRecruitmentStudyUserResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
  onError?: () => void
}

export const editRecruitmentStudyUser = (
  { studyId, userId, centerIds, type }: EditRecruitmentStudyUserOptions,
  responseHandlers?: EditRecruitmentStudyUserResponseHandlers
) => {
  const query = {
    center_ids: centerIds
  }
  const { req, cancel } = fetchApi.patch(
    `recruitment/studies/${studyId}/${type === UserType.User ? 'users' : 'invitations'}/${userId}`,
    query
  )

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<EditRecruitmentStudyUserResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}
