// We need to put this request in a separate file and request is separately because of some webpack bulshit

/* eslint-disable camelcase */
import { DisqualifiedCause, ParticipantStatus, participantStatusForSaveMapping } from '.'
import { createErrorsHandlers } from '../../utils'
import { BackendError } from '../RequestError'
import { CalendarVisit, RemoteVisit, parseRemoteVisit } from '../calendar/visits'
import { SelectionKeys } from '../exports'
import { fetchApi } from '../fetchApi'

interface BulkUpdateParticipantsStatusResponseHandlers {
  onSuccess?: (cancelledVisits: CalendarVisit[]) => void
  onWrongStatus?: () => void
  onNoCurrency?: () => void
  onRequestError?: (code: number) => void
}

interface BulkUpdateParticipantsStatusOptions {
  search: string
  records: SelectionKeys
  status: string[]
  studyId: string
  targetStatus: ParticipantStatus
  paymentAmount: number
  paymentComment: string
  disqualifiedCause: DisqualifiedCause
}

export const bulkUpdateParticipantsStatus = (
  {
    targetStatus,
    paymentAmount,
    paymentComment,
    disqualifiedCause,
    search,
    status,
    records,
    studyId
  }: BulkUpdateParticipantsStatusOptions,
  responseHandlers?: BulkUpdateParticipantsStatusResponseHandlers
) => {
  const path = `recruitment/studies/${studyId}/records/status`
  const query = {
    search: search || undefined,
    records,
    status: status?.length
      ? status.map(status => participantStatusForSaveMapping[status as ParticipantStatus]).join(',')
      : undefined,
    target_status: participantStatusForSaveMapping[targetStatus],
    payment_amount: paymentAmount,
    payment_comment: paymentComment,
    disqualified_cause: targetStatus === ParticipantStatus.Disqualified ? disqualifiedCause : undefined
  }
  const { req, cancel } = fetchApi.post<RemoteVisit[]>(path, query)

  req.then(({ error, status, body }) => {
    if (error) {
      createErrorsHandlers<BulkUpdateParticipantsStatusResponseHandlers>(
        {
          [BackendError.RECRUITMENT_RECORD_STATUS_ILLEGAL_TRANSITION]: 'onWrongStatus',
          [BackendError.PAYMENT_MISSING_CURRENCY]: 'onNoCurrency'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess((body.length && body.map(parseRemoteVisit)) || [])
    }
  })

  return cancel
}
