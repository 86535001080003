/* eslint-disable @typescript-eslint/no-explicit-any */
import './LandingPage.less'

import React, { useEffect, useRef, useState } from 'react'

import { localeFromPath } from '../../../utils'
import ArrowLeft from './assets/arrow-left.svg'
import LogoFr from './assets/logo_fr.svg'
import LogoUs from './assets/logo_us.svg'

type Selection = 'nyc' | 'continental'

const videoUrl = 'https://fb.watch/wiyYSoNNPI/'

const LandingPageUS: React.FC = () => {
  const [step, setStep] = useState<1 | 2>(1)
  const [selection, setSelection] = useState<Selection>(null)
  const playerRef = useRef(null)

  useEffect(() => {
    // we're using a lot of hacks here (no-explicit-any etc)
    // because I didn't want to install a facebook module for this one player here
    ;(window as any).fbAsyncInit = () => {
      ;(window as any).FB.init({
        xfbml: true,
        version: 'v3.2'
      })

      // Get Embedded Video Player API Instance
      ;(window as any).FB.Event.subscribe('xfbml.ready', (msg: any) => {
        if (msg.type === 'video') {
          playerRef.current = msg.instance
          loadVideo()
        }
      })
    }
    if (!('FB' in window)) {
      const tag = document.createElement('script')
      tag.defer = true
      tag.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2'

      const firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
    } else {
      loadVideo()
    }
  }, [])

  const loadVideo = () => {
    playerRef.current.subscribe('startedPlaying', () => {
      document.querySelector('.videoWrapper__overlay')?.remove()
    })
  }

  const onSelectionChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const target = event.currentTarget as HTMLInputElement
    setSelection(target.value as Selection)
  }

  const onStepChange = (val: 1 | 2) => {
    setStep(val)
    setSelection(null)
  }

  return (
    <>
      {step === 1 && (
        <article data-step="1">
          <header>
            <LogoUs />
          </header>
          <hr />
          <section>
            <h1>Thank you for your interest in becoming a L'Oréal hair model</h1>
            <h2>
              Becoming a model is easy!
              <br />
              Check the video to understand our 2 programs.
            </h2>
          </section>
          <div className="videoWrapper">
            <div className="videoWrapper__overlay" />
            <div data-href={videoUrl} className="fb-video" />
          </div>
          <section className="gray">
            Just follow these 3 steps.
            <ol>
              <li>Complete the form below and click Apply Now.</li>
              <li>Check your email. You will receive an email with a link to click and confirm your application.</li>
              <li>Please await approval. You will be contacted with the next steps regarding our becoming a model.</li>
            </ol>
          </section>
          <button type="button" className="button" onClick={() => onStepChange(2)}>
            Register now!
          </button>
        </article>
      )}
      {step === 2 && (
        <article data-step="2">
          <header>
            <nav>
              <button type="button" onClick={() => onStepChange(1)} className="button--ghost">
                <ArrowLeft />
              </button>
            </nav>
            <h1>Select a programme</h1>
          </header>
          <form id="form">
            <fieldset>
              <div>
                <input type="radio" name="selection" id="opt-a" value="nyc" onChange={onSelectionChange} />
                <>{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}</>
                <label htmlFor="opt-a">
                  <strong>NYC In-Salon Program</strong>
                  We offer free coloring, hair treatments along with hair care services and blowouts in exchange for
                  your time. Color is given once every 6 weeks with 1 mandatory hair care appointment in between each
                  color service.
                </label>
              </div>
              <div>
                <input type="radio" name="selection" id="opt-b" value="continental" onChange={onSelectionChange} />
                <>{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}</>
                <label htmlFor="opt-b">
                  <strong>Continental USA Consumer Program</strong>
                  Picking up a study product, using it for the time frame stated to the study. Answering surveys on time
                  in exchange for a full size goodie bag along with a pass that grants you exclusive access to the
                  L’Oreal discounted employee store.
                </label>
              </div>
            </fieldset>
            {selection === 'nyc' && (
              <a
                href="https://testsrooms.lorealppd.com/en/public/signup-qr/#fb5a1865-1cdb-4af3-99f6-b50e7193e517"
                className="button"
              >
                Register now!
              </a>
            )}
            {selection === 'continental' && (
              <a
                href="https://testsrooms.lorealppd.com/en/public/signup-qr/#c76557b6-0ae7-4fcf-86a7-4c56c6c2ac9c"
                className="button"
              >
                Register now!
              </a>
            )}
            {!selection && <input type="submit" className="button" value="Select your program" disabled />}
          </form>
        </article>
      )}
    </>
  )
}

const LandingPageFR: React.FC = () => {
  return (
    <article>
      <header>
        <LogoFr />
      </header>
      <hr />
      <section>
        <h1>Thank you for your interest in becoming a L'Oréal hair model</h1>
        <h2>Becoming a model is easy! Just follow these 3 steps</h2>
      </section>
      <section className="gray">
        Just follow these 3 steps.
        <ol>
          <li>Complete the form below and click Apply Now.</li>
          <li>Check your email. You will receive an email with a link to click and confirm your application.</li>
          <li>Please await approval. You will be contacted with the next steps regarding our becoming a model.</li>
        </ol>
      </section>
      <a
        href="https://testsrooms.lorealppd.com/en/public/signup-qr/#54d0531d-306d-4ae2-86ab-d30f017369da"
        type="button"
        className="button"
      >
        Sign me up!
      </a>
    </article>
  )
}

export const LandingPage: React.FC = () => {
  const locale = localeFromPath()
  return (
    <main className="lp-landing-page">
      <div className="lp-landing-page__content">{locale === 'fr' ? <LandingPageFR /> : <LandingPageUS />}</div>
    </main>
  )
}
