import './SettingsPersonalDetails.less'

import { Button, Form, Input } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import Flag from 'react-world-flags'

import { useScopedIntl } from '../../../hooks'
import {
  AvailableLocales,
  LoginType,
  PersonalDetails,
  fetchPersonalDetails,
  updatePersonalDetails
} from '../../../requests'
import { changeLocale, enumToOptions } from '../../../utils'
import { validateRequired } from '../../../validation'
import { UserContext } from '../../auth'
import { DatacFormItem, DatacMessage, DatacSelect, DatacTimezonesSelect, DatacTitle } from '../../common'
import { SettingsLayout } from '../SettingsLayout'

enum langToFlag {
  en = 'gb',
  fr = 'fr',
  it = 'it',
  de = 'de',
  es = 'es',
  pl = 'pl',
  pt = 'pt',
  ja = 'jp',
  zh = 'cn'
}

export const SettingsPersonalDetails: React.FC = () => {
  const intlPersonalDetails = useScopedIntl('settings.personal_details')
  const intlLanguages = useScopedIntl('languages')
  const [isEditingOn, setIsEditingOn] = useState(false)
  const [form] = Form.useForm()
  const [formKey, setFormKey] = useState(0)
  const [isSaving, setIsSaving] = useState(false)
  const [currentUser, setCurrentUser] = useState<PersonalDetails>()
  const { user, setUserLanguage } = useContext(UserContext)

  const languageOptions = enumToOptions(AvailableLocales, intlLanguages)

  useEffect(() => {
    fetchPersonalDetails({
      onSuccess: data => {
        setCurrentUser(data)
        setFormKey(formKey === 0 ? 1 : 0)
      },
      onRequestError: () => {
        form.setFieldsValue(null)
        DatacMessage.error(intlPersonalDetails('error.title'), intlPersonalDetails('error.subtitle'))
      }
    })
  }, [])

  const onCancel = () => {
    form.resetFields()
    form.validateFields()
    setIsEditingOn(false)
  }

  const onSave = () => {
    form.submit()
  }

  const onEdit = () => {
    setIsEditingOn(true)
  }

  const onSubmit = (data: PersonalDetails) => {
    setIsSaving(true)
    updatePersonalDetails(data, {
      onSuccess: () => {
        setCurrentUser(data)
        setIsEditingOn(false)
        setIsSaving(false)
        setUserLanguage(data.language)
        changeLocale(data.language)
      },
      onRequestError: () => {
        DatacMessage.error(intlPersonalDetails('error.title'), intlPersonalDetails('error.subtitle'))
        setIsSaving(false)
      }
    })
  }

  return (
    <SettingsLayout>
      <div className="personal-details-content">
        <DatacTitle type="h2" className="personal-details-content__header">
          {intlPersonalDetails('title')}
        </DatacTitle>
        <div className="personal-details-content__body">
          <div className="personal-details-content__form">
            <Form
              form={form}
              onFinish={data => onSubmit(data as PersonalDetails)}
              initialValues={currentUser}
              key={formKey}
            >
              <div className="personal-details-content__row">
                <div className="personal-details-content__label">{intlPersonalDetails('first_name.label')}</div>
                <div className="personal-details-content__control">
                  <DatacFormItem
                    name="firstName"
                    label=" "
                    validate={validateRequired(intlPersonalDetails('first_name.validation'))}
                  >
                    <Input
                      disabled={!isEditingOn}
                      size="large"
                      placeholder={intlPersonalDetails('first_name.placeholder')}
                    />
                  </DatacFormItem>
                </div>
              </div>

              <div className="personal-details-content__row">
                <div className="personal-details-content__label">{intlPersonalDetails('last_name.label')}</div>
                <div className="personal-details-content__control">
                  <DatacFormItem
                    name="lastName"
                    label=" "
                    validate={validateRequired(intlPersonalDetails('last_name.validation'))}
                  >
                    <Input
                      disabled={!isEditingOn}
                      size="large"
                      placeholder={intlPersonalDetails('last_name.placeholder')}
                    />
                  </DatacFormItem>
                </div>
              </div>

              <div className="personal-details-content__row">
                <div className="personal-details-content__label">{intlPersonalDetails('company.label')}</div>
                <div className="personal-details-content__control">
                  <DatacFormItem
                    name="company"
                    label=" "
                    validate={validateRequired(intlPersonalDetails('company.validation'))}
                  >
                    <Input
                      disabled={!isEditingOn}
                      size="large"
                      placeholder={intlPersonalDetails('company.placeholder')}
                    />
                  </DatacFormItem>
                </div>
              </div>

              {user.loginType === LoginType.Password && (
                <div className="personal-details-content__row">
                  <div className="personal-details-content__label">{intlPersonalDetails('email.label')}</div>
                  <div className="personal-details-content__control">
                    <DatacFormItem name="email" label=" ">
                      <Input disabled size="large" placeholder={intlPersonalDetails('email.placeholder')} />
                    </DatacFormItem>
                  </div>
                </div>
              )}

              <div className="personal-details-content__row">
                <div className="personal-details-content__label">{intlPersonalDetails('language.label')}</div>
                <div className="personal-details-content__control">
                  <DatacFormItem name="language" label=" ">
                    <DatacSelect
                      disabled={!isEditingOn}
                      options={languageOptions}
                      renderOption={option => (
                        <div className="personal-details-content__language-option">
                          <Flag
                            code={langToFlag[option.value as keyof typeof langToFlag]}
                            data-code={option.value}
                            className="personal-details-content__control__flag"
                          />
                          {option.label}
                        </div>
                      )}
                    />
                  </DatacFormItem>
                </div>
              </div>
              <div className="personal-details-content__row">
                <div className="personal-details-content__label">{intlPersonalDetails('timezone.label')}</div>
                <div className="personal-details-content__control">
                  <DatacTimezonesSelect disabled={!isEditingOn} />
                </div>
              </div>
            </Form>
          </div>
          <div className="personal-details-content__form-controls">
            {isEditingOn || isSaving ? (
              <>
                <Button size="large" type="default" onClick={onCancel}>
                  {intlPersonalDetails('cancel')}
                </Button>
                <Button loading={isSaving} size="large" type="primary" onClick={onSave}>
                  {intlPersonalDetails('save')}
                </Button>
              </>
            ) : (
              <Button size="large" type="primary" onClick={onEdit}>
                {intlPersonalDetails('edit')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </SettingsLayout>
  )
}
