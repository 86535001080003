/* eslint-disable camelcase */
import dayjs, { Dayjs } from 'dayjs'

import { createErrorsHandlers } from '../../utils'
import { BackendError } from '../RequestError'
import { CalendarVisit, RemoteVisit, parseRemoteVisit } from '../calendar'
import { fetchApi } from '../fetchApi'
import { DisqualifiedCause, ParticipantStatus, participantStatusForSaveMapping } from './participants'

export enum BookedVisitStatus {
  Scheduled = 'SCHEDULED',
  Completed = 'COMPLETED',
  NotDone = 'NOT_DONE',
  Cancelled = 'CANCELLED'
}

interface FetchBookedVisitsCausesResponseHandlers {
  onSuccess?: (causes: string[]) => void
  onRequestError?: (code: number) => void
}

export const fetchBookedVisitsCauses = (responseHandlers?: FetchBookedVisitsCausesResponseHandlers) => {
  const { req, cancel } = fetchApi.get<string[]>('calendar/visits/causes')

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchBookedVisitsCausesResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body)
    }
  })

  return cancel
}

export interface VisitConfirmationOptions {
  visitStatus: BookedVisitStatus
  visitStatusCause: string
  applicationStatus: ParticipantStatus
  paymentAmount: number
  paymentComment: string
  disqualifiedCause: DisqualifiedCause
}

interface ConfirmVisitsRequestOptions {
  visits: number[]
  confirmationOptions: VisitConfirmationOptions
}

interface ConfirmVisitsResponseHandlers {
  onSuccess?: (bookedVisits: CalendarVisit[]) => void
  onNoCurrency?: () => void
  onRequestError?: (code: number) => void
}
export const confirmVisits = (
  { visits, confirmationOptions }: ConfirmVisitsRequestOptions,
  responseHandlers?: ConfirmVisitsResponseHandlers
) => {
  const { req, cancel } = fetchApi.post<RemoteVisit[]>('visits/confirm', {
    status: confirmationOptions.visitStatus,
    cause: confirmationOptions.visitStatusCause ? confirmationOptions.visitStatusCause : undefined,
    disqualified_cause:
      confirmationOptions.applicationStatus === ParticipantStatus.Disqualified
        ? confirmationOptions.disqualifiedCause
        : undefined,
    application_status: participantStatusForSaveMapping[confirmationOptions.applicationStatus],
    payment_amount: confirmationOptions.paymentAmount || undefined,
    payment_comment: confirmationOptions.paymentComment,
    visits
  })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<ConfirmVisitsResponseHandlers>(
        {
          [BackendError.PAYMENT_MISSING_CURRENCY]: 'onNoCurrency'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.map(parseRemoteVisit))
    }
  })

  return cancel
}

export type VisitStatusCount = Record<BookedVisitStatus, number>

interface FetchBookedVisitsSummaryResponse {
  dates: string[]
  count_scheduled: number
  count_completed: number
  count_not_done: number
  count_cancelled: number
}
interface FetchBookedVisitsSummaryResponseHandlers {
  onSuccess?: ({
    bookedVisitDates,
    bookedVisitStatusCount
  }: {
    bookedVisitDates: Dayjs[]
    bookedVisitStatusCount: VisitStatusCount
  }) => void
  onRequestError?: (code: number) => void
}

export const fetchBookedVisitsSummary = (
  { studyId }: { studyId: string },
  responseHandlers?: FetchBookedVisitsSummaryResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<FetchBookedVisitsSummaryResponse>('visits/summary', {
    recruitment_study: studyId
  })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchBookedVisitsSummaryResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        bookedVisitDates: body.dates?.map(date => date && dayjs(date)) || [],
        bookedVisitStatusCount: {
          [BookedVisitStatus.Scheduled]: body.count_scheduled,
          [BookedVisitStatus.Completed]: body.count_completed,
          [BookedVisitStatus.NotDone]: body.count_not_done,
          [BookedVisitStatus.Cancelled]: body.count_cancelled
        }
      })
    }
  })

  return cancel
}
