import './CalendarEventSearch.less'

import { RefSelectProps, Select } from 'antd'
import classNames from 'classnames'
import { navigate } from 'gatsby-plugin-react-intl'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { CalendarEvent, fetchCalendarEvents } from '../../../../requests'
import { routes } from '../../../../routes'
import { DatacIcon, DatacLoading, DatacMessage, DatacSelect } from '../../../common'
import { useCalendarStore } from '../../CalendarStore'
import { EventAgendaComponent } from '../EventComponents'

interface CalendarEventSearchProps {
  performedSearch: string
}

export const CalendarEventSearch: React.FC<CalendarEventSearchProps> = ({ performedSearch }) => {
  const intl = useScopedIntl('')
  const intlSearch = useScopedIntl('calendar.search')
  const [currentEvents, setCurrentEvents] = useState<CalendarEvent[]>(null)
  const { forceEventDetails, currentView, currentDate, isSidebarVisible, setIsShowingSearchEvents } = useCalendarStore()
  const [isFetchingEvents, setIsFetchingEvents] = useState(false)
  const [currentSearch, setCurrentSearch] = useState('')
  const [shouldOpenAgendaAfterSearch, setShouldOpenAgendaAfterSearch] = useState(false)
  const searchRef = useRef<RefSelectProps>()

  useEffect(() => {
    clearPreviousSearch()
  }, [currentDate])

  useEffect(() => {
    if (currentView !== 'agenda') clearPreviousSearch()
  }, [currentView])

  useEffect(() => {
    if (shouldOpenAgendaAfterSearch && currentEvents?.length && currentSearch) {
      openEventsInAgenda()
    }
  }, [shouldOpenAgendaAfterSearch, currentEvents, currentSearch])

  const openEventsInAgenda = () => {
    searchRef.current.blur()
    setIsShowingSearchEvents(true)
    navigate(routes.calendarSearch(encodeURIComponent(currentSearch)))
  }

  const fetchEvents = useCallback(
    debounce((searchPhrase?: string) => {
      fetchCalendarEvents(
        { search: searchPhrase },
        {
          onSuccess: ({ events }) => {
            setIsFetchingEvents(false)
            setCurrentEvents(events)
          },
          onRequestError: code => DatacMessage.genericError(intl, code)
        }
      )
    }, 1000),
    []
  )

  const onSearchEvents = (searchPhrase?: string) => {
    setCurrentEvents(null)

    if (!searchPhrase) {
      setCurrentSearch('')
      return
    }

    setIsFetchingEvents(true)
    setCurrentSearch(searchPhrase)
    fetchEvents(searchPhrase)
  }

  const onSelectEvent = (id: number) => {
    const event = currentEvents.find(e => e.id === id)
    if (event) {
      openEventsInAgenda()
      forceEventDetails(event)
    }
  }

  const ondKeyPressed = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && currentSearch.trim()) {
      openEventsInAgenda()
    }
  }

  const onClosePopup = (reload: boolean) => {
    if (reload) {
      setCurrentEvents(null)
      setShouldOpenAgendaAfterSearch(true)
      onSearchEvents(currentSearch)
    }
  }

  const clearPreviousSearch = () => {
    setCurrentEvents(null)
    setCurrentSearch('')
  }

  const onClearSearchResults = () => navigate(routes.calendarFullPath('agenda', currentDate))

  return (
    <DatacSelect
      selectRef={searchRef}
      suffixIcon={performedSearch ? <DatacIcon raw name="x" onClick={onClearSearchResults} /> : <span />}
      className="calendar-event-search"
      placeholder={intlSearch('placeholder')}
      showSearch
      onSearch={onSearchEvents}
      onSelect={onSelectEvent}
      onInputKeyDown={ondKeyPressed}
      value={currentSearch || performedSearch}
      dropDownClassName={classNames('calendar-event-search__dropdown', {
        'calendar-event-search__dropdown--disabled': currentEvents === null && !isFetchingEvents,
        'calendar-event-search__dropdown--with-sidebar': isSidebarVisible
      })}
      defaultActiveFirstOption={false}
    >
      {isFetchingEvents ? (
        <Select.Option>
          <DatacLoading isLoading size="small" />
        </Select.Option>
      ) : (
        currentEvents &&
        currentEvents.map(e => (
          <Select.Option value={e.id} key={e.id}>
            <EventAgendaComponent event={e} onClosePopup={onClosePopup} isInSearch />
          </Select.Option>
        ))
      )}
    </DatacSelect>
  )
}
